import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../base';
import { RegistrationAcceptContextProvider } from '../context';
import { RegistrationAcceptProfileController } from './acceptProfileInterface';
import { AcceptProfileViewProps } from './acceptProfileProps';

export function createRegistrationAcceptProfileContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptProfileController,
    View: React.FC<AcceptProfileViewProps>
): React.FC {
    const {
        accept: { useContext },
    } = config;
    return (_containerProps) => {
        const context = useContext();
        const props = controller.useProps({
            stepper: context.stepper,
            form: {
                action: {
                    label: 'Continue',
                },
                id: 'invite_accept_profile_step',
                form: context.form.profile,
                async onSubmit(values) {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    context.stepper.onChange(context.stepper.selected + 1);
                },
            },
            layout: {
                stepper: context.stepper,
                navigate: useNavigate(),
            },
        });
        return <View {...props} />;
    };
}
