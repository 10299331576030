import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChakraStepperControllerProps } from '../../../vendor/chakra-stepper';
import { RegistrationContainerConfig } from '../base';
import { RegistrationAcceptContextProvider } from './context';
import {
    AcceptOrganizationFormValues,
    AcceptOrganizationFormValuesSchema,
} from './organization';
import { AcceptProfileFormValues, AcceptProfileFormValuesSchema } from './profile';
import {
    buildInitialOrganizationFormValues,
    buildInitialProfileFormValues,
} from './registrationAcceptCreateFactory';
import {
    RegistrationAcceptCreateController,
    RegistrationAcceptCreateLoader,
} from './registrationAcceptCreateInterface';
import {
    RegistrationAcceptCreateContainerProps,
    RegistrationAcceptCreateViewProps,
} from './registrationAcceptCreateProps';

export function createRegistrationAcceptCreateContainer(
    config: RegistrationContainerConfig & {
        accept: {
            context: RegistrationAcceptContextProvider;
        };
    },
    loader: RegistrationAcceptCreateLoader,
    controller: RegistrationAcceptCreateController,
    View: React.FC<RegistrationAcceptCreateViewProps>
): React.FC<RegistrationAcceptCreateContainerProps> {
    const {
        accept: {
            context: { Provider: ContextProvider },
        },
        context: { useContext },
    } = config;

    return (containerProps) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const context = useContext();

        const forms = {
            profile: useForm<AcceptProfileFormValues>({
                defaultValues: {
                    email: '',
                    firstname: '',
                    lastname: '',
                },
                resolver: zodResolver(AcceptProfileFormValuesSchema),
            }),
            organization: useForm<AcceptOrganizationFormValues>({
                defaultValues: {
                    name: '',
                    website: '',
                },
                resolver: zodResolver(AcceptOrganizationFormValuesSchema),
            }),
        };

        const stepper: ChakraStepperControllerProps = {
            selected: containerProps.step ?? 0,
            onChange(selected) {
                searchParams.set('step', selected.toString());
                setSearchParams(searchParams);
            },
        };

        const data = loader.useLoad(context, containerProps);

        useEffect(() => {
            const profile = buildInitialProfileFormValues(data);
            const organization = buildInitialOrganizationFormValues(data);

            const { success: profileValid } =
                AcceptProfileFormValuesSchema.safeParse(profile);
            const { success: organizationValid } =
                AcceptOrganizationFormValuesSchema.safeParse(organization);

            forms.profile.reset(profile);
            forms.organization.reset(organization);

            if (stepper.selected === 0) {
                return;
            } else if (!profileValid) {
                console.info('navigating to incomplete profile step');
                stepper.onChange(1);
            } else if (!organizationValid) {
                console.info('navigating to incomplete organization step');
                stepper.onChange(2);
            }
        }, []);

        const props = controller.useProps(data, {
            navigate: useNavigate(),
            stepper,
        });
        return (
            <ContextProvider value={{ ...containerProps, stepper, form: forms }}>
                <View {...props} />
            </ContextProvider>
        );
    };
}
