import React from 'react';
import varosLogoSVG from '../../../../../svg/varos-icon.svg';
import { RegistrationViewConfig } from '../../base';
import {
    Box,
    Heading,
    HStack,
    Icon,
    Image,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AcceptWelcomeViewProps } from './acceptWelcomeProps';

export function createRegistrationAcceptWelcomeView(
    config: RegistrationViewConfig
): React.FC<AcceptWelcomeViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <VStack
                align="center"
                borderRadius="lg"
                py={6}
                px={12}
                spacing={12}
                minW="42vw"
                divider={<StackDivider borderColor="gray.200" borderWidth={1} />}
            >
                <VStack align="center" spacing={6}>
                    <Image w={10} h={10} alt="brand" src={varosLogoSVG} />
                    <VStack w="full" spacing={3}>
                        <Heading color="black">{props.page.header.title}</Heading>
                        {props.page.header.description && (
                            <Text
                                fontSize="lg"
                                fontWeight="medium"
                                color="blackAlpha.700"
                            >
                                {props.page.header.description}
                            </Text>
                        )}
                    </VStack>
                    <HStack w="full" justify="center" maxW="72%">
                        <Button
                            {...props.button}
                            size="lg"
                            w="full"
                            colorScheme="green"
                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                        >
                            Get started
                        </Button>
                    </HStack>
                </VStack>
                <Box w="full" maxW="72%">
                    <Box
                        bg="whiteAlpha.300"
                        borderRadius="lg"
                        overflow="hidden"
                        w="full"
                        style={{
                            width: '100%',
                            position: 'relative',
                            paddingBottom: '64.86486486486486%',
                            height: 0,
                        }}
                    >
                        <iframe
                            src={props.video.src}
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    };
}
