import { CalendarIcon, CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Link as ChakraLink,
    Button,
    ButtonGroup,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    VStack,
    Grid,
    GridItem,
    Tag,
    Spinner,
    Box,
    Image,
    Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
    AiOutlineArrowRight,
    AiOutlineEye,
    AiOutlineEyeInvisible,
    AiOutlineLock,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { RemoteIframe } from '../../../ui';
import { createInsightView } from '../../view';
import {
    createHomeExpertCallSecondaryView,
    createHomeExpertCallView,
} from './expert-call';
import { createHomeGuideView, createHomeSecondaryView } from './guide';
import { HomeRouteConfig } from './homeRouteConfig';
import { HomeViewProvider, HomeViewItem } from './homeRouteModel';
import { HomeRouteItemProps, HomeRouteProps } from './homeRouteProps';

export function createHomeRouteView(config: HomeRouteConfig): React.FC<HomeRouteProps> {
    const {
        deps: {
            Layout: {
                UI,
                Container,
                Header,
                View: {
                    List: ViewList,
                    Item: ViewItem,
                    Content: ViewContent,
                    Action: ViewAction,
                },
                Alert: { List: AlertList, Item: AlertItem },
                Control: { List: ControlList, Item: ControlItem },
            },
        },
    } = config;

    const { Link } = UI;

    function createHomeRouteItemContainer<TData>(provider: HomeViewProvider<TData>) {
        const { controller, Component, ...instance } = provider.create({ UI });
        return {
            ...instance,
            id: provider.id,
            actions: instance.actions?.map((action) => {
                const { Component, ...rest } = action;
                return {
                    ...action,
                    Component(props: Omit<HomeRouteItemProps<TData>, 'data'>) {
                        const viewProps = controller.useProps(props.context, props);
                        if (!viewProps.props || !viewProps.enabled) {
                            return null;
                        }
                        return <Component {...props} data={viewProps.props} />;
                    },
                };
            }),
            Component(props: Omit<HomeRouteItemProps<TData>, 'data'>) {
                const viewProps = controller.useProps(props.context, props);
                if (!viewProps.props) {
                    return null;
                }
                return <Component {...props} data={viewProps.props} />;
            },
        };
    }

    const views = config.config.views.map(createHomeRouteItemContainer);

    const controls = config.config.controls.map((item) => ({
        ...item,
        ...item.create({ UI }),
    }));

    const OpportunityView = config.providers.opportunity.createView({ UI });
    const AlertView = config.providers.alert.createView(
        { UI },
        createInsightView({ UI })
    );

    const GuideView = {
        Secondary: createHomeSecondaryView({ UI }),
        Main: createHomeGuideView({ UI }),
    };

    const ExpertCallView = {
        Secondary: createHomeExpertCallSecondaryView({ UI }),
        Main: createHomeExpertCallView({ UI }),
    };

    return (props) => {
        const opportunityProps = props.getOpportunityProps();
        const alertProps = props.getAlertProps();
        const integrationProps = props.getIntegrationProps();
        const destinationProps = props.getDestinationProps();
        const guideProps = props.getGuideProps();
        const expertCallProps = props.getExpertCallProps();
        const dateProps = props.getDateProps();

        return (
            <Container>
                <AlertList containerRef={props.page.alert.containerRef}>
                    {props.page.alert.items.map((item) => (
                        <AlertItem
                            key={item.id}
                            {...props.page.alert.getItemProps(item)}
                        />
                    ))}
                </AlertList>
                <Header {...props.headerProps} />
                <ViewList>
                    {guideProps.isHidden ? null : (
                        <ViewItem
                            id="guide"
                            title="Best Practices"
                            isVisible={true}
                            layout={{ fullWidth: true, showDivider: true }}
                            rightContent={<GuideView.Secondary {...guideProps} />}
                        >
                            <ViewContent>
                                <GuideView.Main {...guideProps} />
                            </ViewContent>
                        </ViewItem>
                    )}
                    {expertCallProps.isHidden ? null : (
                        <ViewItem
                            id="tactical_calls"
                            title="High Performer Calls"
                            isVisible={true}
                            layout={{ fullWidth: true, showDivider: true }}
                            rightContent={
                                <ExpertCallView.Secondary {...expertCallProps} />
                            }
                        >
                            <ViewContent>
                                <ExpertCallView.Main {...expertCallProps} />
                            </ViewContent>
                        </ViewItem>
                    )}
                    {props.isVisible({ id: 'summary' }) ? (
                        <ViewItem
                            id="summary"
                            title="Summary"
                            isVisible={true}
                            layout={{ fullWidth: true }}
                        >
                            <ViewContent>
                                <VStack
                                    align="start"
                                    w="full"
                                    // maxW="60rem"
                                    p={6}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                    borderRadius="lg"
                                >
                                    <Text>
                                        The company's key challenge is an IT Run vs.
                                        Change disparity, with 70% of IT expenses focused
                                        on Run and 30% on Change, compared to
                                        best-performing peers aiming for 40% Run and 60%
                                        Change.
                                    </Text>
                                    <Text>
                                        This is mainly driven by an excessively large
                                        applications portfolio supported by a larger and
                                        more expensive and requiring more maintenance
                                        support.
                                    </Text>
                                    <Text>
                                        Application maintenance spend ~25% of expense
                                        which is above the benchmark of top performing
                                        peers at ~15% to 20%; overall staff is ~15% at low
                                        cost locations while best performing peers are at{' '}
                                        {`>20`}% and as high as ~40%; overall IT headcount
                                        at 10% of company headcount is well above best
                                        performing peers at 8%. These applications are
                                        driving the need for a larger than predicted
                                        infrastructure which is currently operating at a
                                        cost which is ~33% higher than predicted by the
                                        benchmark model.
                                    </Text>
                                </VStack>
                            </ViewContent>
                        </ViewItem>
                    ) : null}
                    {/* Integrations */}
                    {integrationProps.items.length > 0 ? (
                        <ViewItem
                            id="integration"
                            title="Integrations"
                            description={
                                <Text>
                                    Integration data must finish syncing before their
                                    metrics and dashboards become available
                                </Text>
                            }
                            isVisible={true}
                            layout={{ fullWidth: true }}
                        >
                            <ViewContent>
                                <Grid
                                    gap={4}
                                    templateColumns={{
                                        base: '1fr 1fr',
                                        sm: '1fr 1fr 1fr',
                                    }}
                                    w="full"
                                >
                                    {integrationProps.items.map((item) => {
                                        const itemProps =
                                            integrationProps.getItemProps(item);
                                        return (
                                            <GridItem key={item.plugin.id}>
                                                <VStack
                                                    w="full"
                                                    borderWidth={2}
                                                    borderColor="whiteAlpha.300"
                                                    borderStyle="solid"
                                                    p={4}
                                                    align="start"
                                                    borderRadius="lg"
                                                >
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                        spacing={4}
                                                    >
                                                        <HStack spacing={4} flex={1}>
                                                            <Box
                                                                p={2}
                                                                bg="whiteAlpha.300"
                                                                borderRadius="md"
                                                            >
                                                                <Image
                                                                    w={5}
                                                                    h={5}
                                                                    src={
                                                                        item.plugin
                                                                            .iconUrl ?? ''
                                                                    }
                                                                />
                                                            </Box>
                                                            <Text fontWeight="medium">
                                                                {item.plugin.name}
                                                            </Text>
                                                        </HStack>
                                                        <Tooltip
                                                            placement="top"
                                                            hasArrow={true}
                                                            label={
                                                                <Box p={2}>
                                                                    {
                                                                        itemProps.status
                                                                            .description
                                                                    }
                                                                </Box>
                                                            }
                                                        >
                                                            <Tag
                                                                flexShrink={0}
                                                                colorScheme={
                                                                    itemProps.status
                                                                        .colorScheme
                                                                }
                                                                size="lg"
                                                            >
                                                                <HStack
                                                                    w="full"
                                                                    spacing={3}
                                                                >
                                                                    <Text>
                                                                        {
                                                                            itemProps
                                                                                .status
                                                                                .title
                                                                        }
                                                                    </Text>
                                                                    <Box>
                                                                        {itemProps.status
                                                                            .isLoading && (
                                                                            <Spinner
                                                                                size="sm"
                                                                                speed="5s"
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </HStack>
                                                            </Tag>
                                                        </Tooltip>
                                                    </HStack>
                                                </VStack>
                                            </GridItem>
                                        );
                                    })}
                                </Grid>
                            </ViewContent>
                        </ViewItem>
                    ) : null}
                    {/* {!destinationProps.isHidden ? (
                        <ViewItem
                            id={destinationProps.id}
                            title="Destinations"
                            description={`Data destinations enable you to synchronize Varos data with external systems like databases and spreadsheets`}
                            rightContent={
                                <>
                                    <Button
                                        variant="link"
                                        size="sm"
                                        rightIcon={<Icon as={AiOutlineEyeInvisible} />}
                                        onClick={destinationProps.onHide}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Hide
                                    </Button>
                                </>
                            }
                            layout={{ fullWidth: true, showDivider: true }}
                            isVisible={true}
                        >
                            <ViewContent>
                                {destinationProps.children}
                                <Grid
                                    gap={6}
                                    templateColumns={{
                                        base: '1fr',
                                        lg: '1fr 1fr',
                                        xl: '1fr 1fr 1fr',
                                    }}
                                    w="full"
                                >
                                    {destinationProps.items.map((item) => {
                                        const itemProps =
                                            destinationProps.getItemProps(item);
                                        return (
                                            <GridItem key={itemProps.id}>
                                                <VStack
                                                    w="full"
                                                    borderWidth={2}
                                                    borderColor="whiteAlpha.300"
                                                    borderStyle="solid"
                                                    p={6}
                                                    align="start"
                                                    borderRadius="lg"
                                                >
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                        spacing={4}
                                                        fontWeight="medium"
                                                        align="start"
                                                    >
                                                        <VStack align="start" flex={1}>
                                                            <HStack spacing={4} flex={1}>
                                                                <Box
                                                                    flexShrink={0}
                                                                    p={2}
                                                                    bg="whiteAlpha.300"
                                                                    borderRadius="md"
                                                                >
                                                                    <Image
                                                                        w={5}
                                                                        h={5}
                                                                        src={
                                                                            itemProps.iconUrl
                                                                        }
                                                                    />
                                                                </Box>
                                                                <VStack
                                                                    align="start"
                                                                    spacing={0}
                                                                >
                                                                    <Text fontSize="lg">
                                                                        {itemProps.name}
                                                                    </Text>
                                                                </VStack>
                                                            </HStack>
                                                        </VStack>
                                                        <Tooltip
                                                            placement="top"
                                                            hasArrow={true}
                                                            isDisabled={
                                                                !itemProps.connect.tooltip
                                                            }
                                                            label={
                                                                <Box p={2}>
                                                                    {
                                                                        itemProps.connect
                                                                            .tooltip
                                                                    }
                                                                </Box>
                                                            }
                                                        >
                                                            <Button
                                                                colorScheme="green"
                                                                variant="outline"
                                                                flexShrink={0}
                                                                size="sm"
                                                                px={3}
                                                                py={2}
                                                                onClick={
                                                                    itemProps.connect
                                                                        .onClick
                                                                }
                                                                rightIcon={
                                                                    itemProps.connect
                                                                        .Icon ? (
                                                                        <Icon
                                                                            as={
                                                                                itemProps
                                                                                    .connect
                                                                                    .Icon
                                                                            }
                                                                        />
                                                                    ) : undefined
                                                                }
                                                            >
                                                                Connect
                                                            </Button>
                                                        </Tooltip>
                                                    </HStack>
                                                </VStack>
                                            </GridItem>
                                        );
                                    })}
                                </Grid>
                            </ViewContent>
                        </ViewItem>
                    ) : null} */}
                    {opportunityProps.getCollectionProps().collection.status ===
                    'loaded' ? (
                        <ViewItem
                            id="opportunity"
                            title="Opportunities"
                            description={`Opportunities highlight potential areas for improving performance
                            based on what you're doing differently compared to the high
                            performing companies in your peer group
                        `}
                            isVisible={true}
                            rightContent={
                                <Link
                                    id="home_opportunity_view_all"
                                    to="../opportunities"
                                >
                                    <Button
                                        variant="link"
                                        size="sm"
                                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        View all
                                    </Button>
                                </Link>
                            }
                            style={{ minW: '24rem' }}
                            layout={{ fullWidth: false, showDivider: true }}
                        >
                            <ViewContent>
                                <OpportunityView {...opportunityProps} />
                            </ViewContent>
                        </ViewItem>
                    ) : null}
                    {views
                        .filter(props.isEnabled)
                        .map(({ Component, ...item }, index) => {
                            return (
                                <ViewItem
                                    key={item.id}
                                    {...item}
                                    isVisible={props.isVisible(item)}
                                >
                                    {item.actions?.map(({ Component, ...action }) => (
                                        <ViewAction key={action.id}>
                                            <Component
                                                {...props.getActionProps(action)}
                                            />
                                        </ViewAction>
                                    )) ?? []}
                                    <ViewContent>
                                        <Component {...props.getRouteProps(item)} />
                                    </ViewContent>
                                </ViewItem>
                            );
                        })}
                    {/* Alerts */}
                    {alertProps.items.length > 0 ? (
                        <ViewItem
                            id="alert"
                            title="Alerts"
                            description={`Opportunities highlight potential areas for improving performance
                            based on what you're doing differently compared to the high
                            performing companies in your peer group
                        `}
                            isVisible={true}
                            status={
                                <Tag
                                    fontWeight="medium"
                                    color="blackAlpha.900"
                                    bg="#c6e74f"
                                    letterSpacing="wide"
                                >
                                    Beta
                                </Tag>
                            }
                            style={{ minW: '24rem' }}
                            layout={{ fullWidth: false }}
                        >
                            <ViewContent>
                                <AlertView {...alertProps} />
                            </ViewContent>
                        </ViewItem>
                    ) : null}
                </ViewList>
                <ControlList>
                    <ControlItem>
                        <HStack align="center" spacing={4}>
                            <ButtonGroup isAttached={true}>
                                <Menu>
                                    <MenuButton
                                        flexShrink={0}
                                        borderRight="1px solid"
                                        borderColor="blackAlpha.500"
                                        borderRightRadius="none"
                                        as={Button}
                                        _selected={{
                                            bg: 'green.500',
                                            white: 'white',
                                        }}
                                        // minW={32}
                                        size="sm"
                                        bg="#292929"
                                        _focus={{ outline: 'none' }}
                                        rightIcon={
                                            <Icon
                                                fontSize="lg"
                                                w="1rem"
                                                as={ChevronDownIcon}
                                            />
                                        }
                                    >
                                        {dateProps.getPeriodLabel()}
                                    </MenuButton>
                                    <MenuList bg="#292929">
                                        {props.control.period.options.map((item) => {
                                            const itemProps =
                                                dateProps.getPeriodOptionProps(item);
                                            return (
                                                <MenuItem
                                                    key={itemProps.label}
                                                    aria-selected={itemProps.isSelected}
                                                    _selected={{ color: 'green.500' }}
                                                    icon={
                                                        <Icon
                                                            as={CheckIcon}
                                                            visibility={
                                                                itemProps.isSelected
                                                                    ? 'inherit'
                                                                    : 'hidden'
                                                            }
                                                        />
                                                    }
                                                    onClick={itemProps.onClick}
                                                    fontWeight="medium"
                                                >
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                        aria-disabled={
                                                            itemProps.isDisabled
                                                        }
                                                        _disabled={{ opacity: 0.33 }}
                                                    >
                                                        <Text>{itemProps.label}</Text>
                                                        {itemProps.Icon ? (
                                                            <Icon as={itemProps.Icon} />
                                                        ) : null}
                                                    </HStack>
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuList>
                                </Menu>
                                <ReactDatePicker
                                    calendarClassName="dark"
                                    customInput={
                                        <Button
                                            borderLeftRadius="none"
                                            w="full"
                                            leftIcon={<Icon as={CalendarIcon} />}
                                            // minW={42}
                                            size="sm"
                                            bg="#292929"
                                            _focus={{ outline: 'none' }}
                                        >
                                            {dateProps.getAbsoluteLabel(
                                                props.control.period.value,
                                                true
                                            )}
                                        </Button>
                                    }
                                    {...dateProps.getRangeProps()}
                                    selectsRange={true}
                                />
                            </ButtonGroup>
                            <Text
                                fontWeight="medium"
                                color="whiteAlpha.500"
                                whiteSpace="nowrap"
                            >
                                compared to
                            </Text>
                            <Menu size="sm" placement="bottom-end">
                                <MenuButton
                                    as={Button}
                                    borderRight="1px solid"
                                    borderColor="blackAlpha.500"
                                    _selected={{
                                        bg: 'green.500',
                                        white: 'white',
                                    }}
                                    // minW={32}
                                    size="sm"
                                    bg="#292929"
                                    _focus={{ outline: 'none' }}
                                    rightIcon={
                                        <Icon
                                            fontSize="lg"
                                            w="1rem"
                                            as={ChevronDownIcon}
                                        />
                                    }
                                >
                                    {props.getComparisonLabel()}
                                </MenuButton>
                                <MenuList bg="#292929">
                                    {props.control.comparison.options.map((item) => {
                                        const itemProps =
                                            props.getComparisonOptionProps(item);
                                        return (
                                            <MenuItem
                                                key={itemProps.label}
                                                aria-selected={itemProps.isSelected}
                                                _selected={{ color: 'green.500' }}
                                                icon={
                                                    <Icon
                                                        as={CheckIcon}
                                                        visibility={
                                                            itemProps.isSelected
                                                                ? 'inherit'
                                                                : 'hidden'
                                                        }
                                                    />
                                                }
                                                onClick={itemProps.onClick}
                                            >
                                                <HStack
                                                    justify="space-between"
                                                    spacing={8}
                                                >
                                                    <Text fontWeight="medium">
                                                        {itemProps.label}
                                                    </Text>
                                                    <Text color="whiteAlpha.500">
                                                        {itemProps.caption}
                                                    </Text>
                                                </HStack>
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </Menu>
                        </HStack>
                    </ControlItem>
                    {controls.map(({ Component, ...item }) => (
                        <ControlItem>
                            <Component {...props.getRouteProps(item)} data={null} />
                        </ControlItem>
                    ))}
                </ControlList>
            </Container>
        );
    };
}
