import { useMemo } from 'react';
import { buildStudyItemViewProps } from '../../../../view/studies';
import { PageIds } from '../../../../config';
import { StudiesDetailRouteConfig } from './studiesReportDetailConfig';
import { StudiesDetailController } from './studiesReportDetailInterface';
import { StudiesDetailViewProps } from './studiesReportDetailProps';

export function createStudiesDetailController(
    config: Pick<StudiesDetailRouteConfig, 'controller' | 'provider'>
): StudiesDetailController {
    const {
        controller: { help: helpController, image: imageConroller },
        provider: { createPageController },
    } = config;

    const pageController = createPageController({
        id: PageIds.REPORTS_DETAIL,
        title: 'Report',
        description: null,
        provider: config.provider,
    });

    return {
        useProps(context, item, props): StudiesDetailViewProps {
            const pageProps = pageController.useProps(
                {
                    entity: item.study.report,
                },
                {}
            );

            const imageProps = imageConroller.useProps(item.image, {});

            const helpProps = helpController.useProps({
                id: 'request_a_report',
            });

            const studyProps = useMemo(
                () => buildStudyItemViewProps(context, item.study),
                [context, item.study]
            );

            return {
                page: pageProps,
                image: imageProps,
                study: studyProps,
                help: helpProps,
                highlight:
                    item.study.report.kind === 'external'
                        ? {
                              label: 'Beta',
                              description:
                                  'This report is in beta and is actively being tested using internal tools',
                          }
                        : null,
                actions: {
                    open:
                        item.study.report.kind === 'dataset'
                            ? {
                                  caption: null,
                                  links: {
                                      response: {
                                          to: `/u/assets/${context.workspace.id}/reporting/reports/${item.study.report.id}/data`,
                                      },
                                  },
                                  anchors: {
                                      response: null,
                                  },
                              }
                            : {
                                  caption: {
                                      label: 'External report',
                                      description: `This report is in beta will opened in a separate tab using the Hex data platform`,
                                  },
                                  links: {
                                      response: null,
                                  },
                                  anchors: {
                                      response: {
                                          id: 'open_report',
                                          href: item.study.report.url,
                                          target: '_blank',
                                      },
                                  },
                              },
                },
            };
        },
    };
}
