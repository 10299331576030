import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FormIds, PageIds } from '../../../../config';
import { PeerRouteConfig } from '../../base';
import {
    PeerGroupCreateController,
    PeerGroupCreateFormProvider,
    PeerGroupCreateLoader,
} from './peerGroupCreateInterface';
import { PeerGroupCreateFormValues } from './peerGroupCreateModel';
import { PeerGroupCreateViewProps } from './peerGroupCreateProps';

export function createPeerGroupCreateRoute(
    config: PeerRouteConfig,
    loader: PeerGroupCreateLoader,
    forms: PeerGroupCreateFormProvider,
    controller: PeerGroupCreateController,
    View: React.FC<PeerGroupCreateViewProps>
): React.FC {
    const {
        repository: { invitation: invitationRepository, peerset: peerSetRepository },
        infra: { toaster },
        context,
        service: { peerset: peersetService },
    } = config;
    return (props) => {
        const toast = toaster.useToast();
        const navigate = useNavigate();

        const viewContext = context.useContext();

        const viewForm = forms.useForm(viewContext, {
            initialValues: {},
        });
        const viewFormValues = viewForm.watch();

        const viewData = loader.useLoad(viewContext, {
            form: viewForm,
        });

        const peersetMutation = peerSetRepository.useCreate(viewContext);
        const invitationMutation = invitationRepository.useBulk(viewContext);

        const mutationCreate = useMutation({
            mutationFn(values: PeerGroupCreateFormValues) {
                return peersetService.create(viewContext, {
                    companies: values.companies,
                    createPeerset(props) {
                        return peersetMutation.mutateAsync(props);
                    },
                    createInvitations(props) {
                        return invitationMutation.mutateAsync(props);
                    },
                });
            },
        });

        const selected = useMemo(
            () => new Set(viewFormValues.companies),
            [viewFormValues.companies]
        );

        const viewProps = controller.useProps(viewContext, viewData, {
            page: {
                item: {
                    id: PageIds.PEER_COMPETITIVE_SET_NEW,
                    title: 'New competitive set',
                    description: `Competitive sets enable filtering data by a known set of companies that you define`,
                    breadcrumbs: [],
                },
            },
            company: {
                match: {
                    company: {
                        metadata: {},
                    },
                    selected,
                    onAdd(item) {
                        viewForm.setValue('companies', [
                            // add company to start of list for proper UX
                            item.company.id,
                            ...viewFormValues.companies,
                        ]);
                    },
                },
                select: {
                    selected: viewFormValues.companies,
                    onRemove(item) {
                        viewForm.setValue(
                            'companies',
                            viewFormValues.companies.filter(
                                (candidate) => candidate !== item.company.id
                            )
                        );
                    },
                    onClear() {
                        viewForm.setValue('companies', []);
                    },
                },
                form: {
                    id: FormIds.PEER_GROUP_CREATE,
                    action: {
                        label: 'Create',
                    },
                    form: viewForm,
                    async onSubmit(values) {
                        const response = await mutationCreate.mutateAsync(values);
                        toast({
                            kind: 'success',
                            description: `Successfully created competitive set`,
                        });
                        navigate(`../competitive_sets/${response.id}`);
                    },
                    onSubmitError(error) {
                        console.log('error', error);
                        toast({
                            kind: 'error',
                            description: `Form submission failed`,
                        });
                    },
                },
            },
        });
        return <View {...viewProps} />;
    };
}
