import React from 'react';
import { Box, Button, Center, Icon, Text, useToken, VStack } from '@chakra-ui/react';
import { GoOrganization } from 'react-icons/go';
import { CompanyListboxSelectViewProps } from '../../../../macro';
import { PeerViewConfig } from '../../base';
import { createPeerCompanyListView } from '../list';
import { createPeerCompanyItemView } from '../item';

export function createPeerCompanySelectView(
    config: PeerViewConfig
): React.FC<CompanyListboxSelectViewProps> {
    const CompanyList = createPeerCompanyListView();
    const CompanyItem = createPeerCompanyItemView(config);
    return (props) => {
        if (props.items.length === 0) {
            return (
                <Center
                    w="full"
                    h="full"
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderStyle="dashed"
                    borderRadius="lg"
                >
                    <VStack
                        align="start"
                        h="full"
                        justify="center"
                        maxW="16rem"
                        spacing={3}
                    >
                        <Box p={3} bg="whiteAlpha.300">
                            <Icon w={6} h={6} as={GoOrganization} />
                        </Box>
                        <VStack align="start" spacing={1}>
                            <Text
                                cursor="default"
                                fontWeight="semibold"
                                color="whiteAlpha.800"
                            >
                                No company selected
                            </Text>
                            <Text
                                cursor="default"
                                fontWeight="medium"
                                color="whiteAlpha.600"
                            >
                                Add competitive set members by selecting companies on the
                                left
                            </Text>
                        </VStack>
                    </VStack>
                </Center>
            );
        }
        return (
            <CompanyList
                label={props.collection.label}
                rightContent={
                    props.action.clear ? (
                        <Button
                            size="sm"
                            variant="link"
                            {...props.action.clear}
                            _focus={{ outline: 'none' }}
                        >
                            Clear
                        </Button>
                    ) : undefined
                }
            >
                {props.items.map((item) => {
                    const itemProps = props.getItemProps(item);
                    return <CompanyItem key={itemProps.id} {...itemProps} />;
                })}
            </CompanyList>
        );
    };
}
