import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    StackDivider,
    Tag,
    Text,
    VStack,
    Wrap,
    Alert,
    AlertTitle,
    AlertIcon,
    useToken,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { VAROS_YELLOW } from '../../../../config';
import { Icons } from '../../../../../config';
import { assertNever } from '../../../../util';
import { StudyDependencyItemProps } from '../../../../view/studies';
import { StudiesDetailRouteConfig } from './studiesReportDetailConfig';
import { StudiesDetailViewProps } from './studiesReportDetailProps';

export function createStudiesDetailView(
    config: Pick<StudiesDetailRouteConfig, 'Layout' | 'UI'>
): React.FC<StudiesDetailViewProps> {
    const {
        UI: {
            Application: { Link, Tooltip, Anchor },
        },
    } = config;

    const DependencyItem: React.FC<StudyDependencyItemProps> = (props) => {
        if (props.kind === 'invite') {
            return (
                <VStack
                    align="start"
                    w="full"
                    p={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderStyle="solid"
                    borderRadius="lg"
                    spacing={6}
                    divider={
                        <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                    }
                >
                    <VStack w="full" align="start">
                        <HStack w="full" justify="space-between">
                            <HStack
                                w="full"
                                spacing={4}
                                fontWeight="medium"
                                userSelect="none"
                            >
                                <Box p={2.5} bg="whiteAlpha.300" borderRadius="md">
                                    <Icon w={5} h={5} as={Icons.Reports.Invitation} />
                                </Box>
                                <Tooltip
                                    p={4}
                                    placement="top"
                                    hasArrow={true}
                                    bg="#1D1C21"
                                    label={props.description}
                                    isDisabled={props.description === null}
                                >
                                    <HStack spacing={2}>
                                        <Text>{props.label}</Text>
                                        <Icon as={AiOutlineInfoCircle} />
                                    </HStack>
                                </Tooltip>
                            </HStack>
                            <HStack spacing={4}>
                                <Tooltip
                                    p={4}
                                    placement="top"
                                    hasArrow={true}
                                    bg="#1D1C21"
                                    label={props.status.description}
                                    isDisabled={props.status.description === null}
                                >
                                    <Box flexShrink={0}>
                                        <Tag
                                            cursor="default"
                                            size="lg"
                                            colorScheme={props.status.color}
                                        >
                                            {props.status.label}
                                        </Tag>
                                    </Box>
                                </Tooltip>
                            </HStack>
                        </HStack>
                    </VStack>
                </VStack>
            );
        }
        if (props.kind === 'survey') {
            return (
                <VStack
                    align="start"
                    w="full"
                    p={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderStyle="solid"
                    borderRadius="lg"
                    spacing={6}
                    divider={
                        <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                    }
                >
                    <VStack w="full" align="start">
                        <HStack w="full" justify="space-between">
                            <HStack
                                w="full"
                                spacing={4}
                                fontWeight="medium"
                                userSelect="none"
                            >
                                <Box p={2.5} bg="whiteAlpha.300" borderRadius="md">
                                    <Icon w={5} h={5} as={Icons.Reports.Study} />
                                </Box>
                                <Tooltip
                                    p={4}
                                    placement="top"
                                    hasArrow={true}
                                    bg="#1D1C21"
                                    label={props.description}
                                    isDisabled={props.description === null}
                                >
                                    <HStack spacing={2}>
                                        <Text>{props.label}</Text>
                                        <Icon as={AiOutlineInfoCircle} />
                                    </HStack>
                                </Tooltip>
                            </HStack>
                            <HStack spacing={4}>
                                {!props.isFulfilled && (
                                    <Link {...props.link.form}>
                                        <Button
                                            bg="whiteAlpha.300"
                                            h={8}
                                            py={0}
                                            _hover={{ bg: 'whiteAlpha.400' }}
                                        >
                                            View survey
                                        </Button>
                                    </Link>
                                )}
                                {props.link.detail && (
                                    <>
                                        <Link {...props.link.detail}>
                                            <Text
                                                whiteSpace="nowrap"
                                                cursor="pointer"
                                                color="whiteAlpha.600"
                                                _hover={{ textDecor: 'underline' }}
                                            >
                                                View response
                                            </Text>
                                        </Link>
                                        <Link {...props.link.detail}>
                                            <Tag
                                                size="lg"
                                                colorScheme={props.status.color}
                                                cursor="pointer"
                                            >
                                                {props.status.label}
                                            </Tag>
                                        </Link>
                                    </>
                                )}
                            </HStack>
                        </HStack>
                    </VStack>
                </VStack>
            );
        }
        if (props.kind === 'integration') {
            return (
                <VStack
                    align="start"
                    w="full"
                    p={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderStyle="solid"
                    borderRadius="lg"
                    spacing={6}
                    divider={
                        <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                    }
                >
                    <VStack w="full" align="start">
                        <HStack w="full" justify="space-between">
                            <HStack w="full" spacing={4} fontWeight="medium">
                                <Box p={2.5} bg="whiteAlpha.300" borderRadius="md">
                                    <Image
                                        w={5}
                                        h={5}
                                        src="https://cdn.varos.io/assets/common/facebook_logo.png"
                                    />
                                </Box>
                                <Text>Facebook Ads</Text>
                            </HStack>
                            <HStack spacing={4}>
                                <Tag h={8} py={0} size="lg" colorScheme="green">
                                    Connected
                                </Tag>
                            </HStack>
                        </HStack>
                    </VStack>
                </VStack>
            );
        }
        assertNever(props);
    };

    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Grid
                templateAreas={`
                    "header sidebar"
                    "image sidebar"
                    "requirements sidebar"
                `}
                templateColumns={{
                    base: 'minmax(0, 5fr) 2fr',
                    lg: 'minmax(0, 5fr) 2fr',
                }}
                columnGap={12}
                rowGap={12}
                w="full"
            >
                <GridItem gridArea="header">
                    <VStack w="full" align="start" spacing={12}>
                        <VStack
                            w="full"
                            // justify="space-between"
                            spacing={6}
                            align="start"
                        >
                            <VStack w="full" align="start" spacing={3}>
                                <HStack
                                    fontWeight="semibold"
                                    spacing={2}
                                    divider={
                                        <Icon
                                            boxSize="1.20rem"
                                            color="whiteAlpha.600"
                                            as={ChevronRightIcon}
                                            border="none"
                                        />
                                    }
                                >
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Reports
                                        </Text>
                                    </Link>
                                </HStack>
                                <HStack w="full" justify="space-between" align="center">
                                    <HStack spacing={4} w="full">
                                        <Box p={3} bg="whiteAlpha.300" borderRadius="sm">
                                            <Icon w={6} h={6} as={Icons.Reports.Report} />
                                        </Box>
                                        <VStack align="start" spacing={2}>
                                            <HStack spacing={3}>
                                                <Heading fontSize="xl">
                                                    {props.study.label}
                                                </Heading>
                                                {/* {props.group.status && (
                                                    <Tooltip
                                                        p={3}
                                                        placement="top"
                                                        hasArrow={true}
                                                        bg="#1D1C21"
                                                        {...props.group.status.getTooltipProps()}
                                                    >
                                                        <Tag
                                                            cursor="default"
                                                            {...props.group.status.getTagProps()}
                                                        />
                                                    </Tooltip>
                                                )} */}
                                            </HStack>
                                            {/* <Wrap shouldWrapChildren={true}>
                                                {props.study.tags.map((tag, index) => (
                                                    <Tag
                                                        key={index}
                                                        colorScheme="gray"
                                                        size="md"
                                                        cursor="default"
                                                    >
                                                        {tag.label}
                                                    </Tag>
                                                ))}
                                            </Wrap> */}
                                            {/* <Text fontSize="md" color="whiteAlpha.700">
                                                {props.study.description}
                                            </Text> */}
                                            {/* <HStack
                                                cursor="default"
                                                fontSize="md"
                                                color="whiteAlpha.700"
                                                fontWeight="medium"
                                                divider={
                                                    <Box border="none">&#x2022;</Box>
                                                }
                                            >
                                                <Text>
                                                    some stuff
                                                </Text>
                                            </HStack> */}
                                        </VStack>
                                    </HStack>
                                    {props.highlight && (
                                        <Tooltip
                                            p={4}
                                            placement="top"
                                            hasArrow={true}
                                            bg="#1D1C21"
                                            label={props.highlight.description}
                                        >
                                            <Box>
                                                <Tag
                                                    cursor="default"
                                                    size="lg"
                                                    bg={VAROS_YELLOW['200']}
                                                    fontWeight="bold"
                                                    color="black"
                                                >
                                                    {props.highlight.label}
                                                </Tag>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </HStack>
                            </VStack>
                            <VStack align="start" w="full" spacing={2}>
                                <Text
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    color="whiteAlpha.800"
                                >
                                    Description
                                </Text>
                                <Text fontSize="lg" color="whiteAlpha.700">
                                    {props.study.description}
                                </Text>
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
                <GridItem gridArea="image">
                    <VStack align="start" w="full" spacing={6}>
                        <Text fontSize="lg" fontWeight="semibold" color="whiteAlpha.800">
                            Preview
                        </Text>
                        <HStack
                            // minH="12rem"
                            // maxH="20vh"
                            w="full"
                            overflowX="scroll"
                            // overflowY="hidden"
                            pb={1.5}
                            spacing={3}
                            css={{
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    height: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    background: scrollbarHoverBg,
                                },
                            }}
                        >
                            {props.image.items.map((itemProps) => (
                                <Box
                                    key={itemProps.id}
                                    flex="0 0 auto"
                                    h="100%"
                                    maxH="12rem"
                                    borderRadius="md"
                                    p={3}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.300"
                                    borderStyle="solid"
                                >
                                    <Image
                                        {...itemProps.image}
                                        borderRadius="md"
                                        // maxH="100%"
                                        maxW="20rem"
                                        // minH="12rem"
                                        // maxH="20vh"
                                        // maxH="16rem"
                                        h="100%"
                                        w="auto"
                                        objectFit="cover"
                                        objectPosition="center"
                                    />
                                </Box>
                            ))}
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem
                    gridArea="sidebar"
                    fontWeight="medium"
                    borderLeftWidth={3}
                    borderColor="whiteAlpha.300"
                    borderStyle="dashed"
                    pl={12}
                    pt={12}
                    position="relative"
                >
                    <VStack w="full" align="start" spacing={6} position="sticky" top={12}>
                        {props.study.status.access.kind !== 'not_enough_data' && (
                            <Tooltip
                                p={4}
                                placement="top"
                                hasArrow={true}
                                bg="#1D1C21"
                                label={props.study.status.access.description}
                                isDisabled={
                                    props.study.status.access.description === null
                                }
                            >
                                <Box w="full" as="span">
                                    {props.actions.open.links.response && (
                                        <Link
                                            style={{ width: '100%' }}
                                            {...props.actions.open.links.response}
                                        >
                                            <Button
                                                w="full"
                                                colorScheme="blue"
                                                isDisabled={
                                                    props.study.status.access.kind !==
                                                    'available'
                                                }
                                                _focus={{ outline: 'none' }}
                                            >
                                                Open report
                                            </Button>
                                        </Link>
                                    )}
                                    {props.actions.open.anchors.response && (
                                        <VStack align="start" w="full">
                                            <Anchor
                                                style={{ width: '100%' }}
                                                {...props.actions.open.anchors.response}
                                            >
                                                <Button
                                                    w="full"
                                                    colorScheme="blue"
                                                    isDisabled={
                                                        props.study.status.access.kind !==
                                                        'available'
                                                    }
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    Open report
                                                </Button>
                                            </Anchor>
                                            {/* {props.actions.open.caption && (
                                                <Tooltip
                                                    p={4}
                                                    placement="top"
                                                    hasArrow={true}
                                                    bg="#1D1C21"
                                                    label={
                                                        props.actions.open.caption
                                                            .description
                                                    }
                                                    isDisabled={
                                                        props.actions.open.caption
                                                            .description === null
                                                    }
                                                >
                                                    <HStack
                                                        userSelect="none"
                                                        color="whiteAlpha.600"
                                                        fontSize="sm"
                                                    >
                                                        <Text>
                                                            {
                                                                props.actions.open.caption
                                                                    .label
                                                            }
                                                        </Text>
                                                        <Icon as={AiOutlineInfoCircle} />
                                                    </HStack>
                                                </Tooltip>
                                            )} */}
                                        </VStack>
                                    )}
                                </Box>
                            </Tooltip>
                        )}
                        {props.study.status.access.kind === 'not_enough_data' && (
                            <Tooltip
                                p={4}
                                placement="top"
                                hasArrow={true}
                                bg="#1D1C21"
                                label={props.study.status.access.description}
                                isDisabled={
                                    props.study.status.access.description === null
                                }
                            >
                                <Tag
                                    cursor="default"
                                    size="lg"
                                    colorScheme={props.study.status.access.color}
                                >
                                    {props.study.status.access.label}
                                </Tag>
                            </Tooltip>
                        )}
                        <VStack w="full" align="start" spacing={3}>
                            <Text
                                color="whiteAlpha.600"
                                fontSize="xs"
                                fontWeight="bold"
                                textTransform="uppercase"
                                letterSpacing="wide"
                            >
                                Category
                            </Text>
                            <VStack w="full" align="start" color="whiteAlpha.800">
                                <Text>{props.study.category.label}</Text>
                            </VStack>
                        </VStack>
                        <VStack w="full" align="start" spacing={3}>
                            <Text
                                color="whiteAlpha.600"
                                fontSize="xs"
                                fontWeight="bold"
                                textTransform="uppercase"
                                letterSpacing="wide"
                            >
                                Data sources
                            </Text>
                            <VStack
                                w="full"
                                align="start"
                                color="whiteAlpha.800"
                                spacing={1}
                            >
                                {props.study.dataSource.items.map((item) => (
                                    <HStack
                                        key={item.label}
                                        aria-disabled={!item.isEnabled}
                                        spacing={3}
                                        cursor="default"
                                        _disabled={{ color: 'whiteAlpha.400' }}
                                    >
                                        <Box
                                            aria-disabled={!item.isEnabled}
                                            w={1.5}
                                            h={1.5}
                                            bg="whiteAlpha.800"
                                            borderRadius="full"
                                            _disabled={{ bg: 'whiteAlpha.400' }}
                                        />
                                        <Tooltip
                                            p={4}
                                            placement="top"
                                            hasArrow={true}
                                            bg="#1D1C21"
                                            label={item.description}
                                        >
                                            <HStack spacing={2}>
                                                <Text>{item.label}</Text>
                                                <Icon as={AiOutlineInfoCircle} />
                                            </HStack>
                                        </Tooltip>
                                    </HStack>
                                ))}
                            </VStack>
                        </VStack>
                        <VStack w="full" align="start" spacing={3}>
                            <Text
                                color="whiteAlpha.600"
                                fontSize="xs"
                                fontWeight="bold"
                                textTransform="uppercase"
                                letterSpacing="wide"
                            >
                                Tags
                            </Text>
                            <VStack w="full" align="start" color="whiteAlpha.800">
                                <Wrap shouldWrapChildren={true}>
                                    {props.study.tags.map((tag, index) => (
                                        <Tag
                                            key={index}
                                            colorScheme="gray"
                                            size="md"
                                            cursor="default"
                                        >
                                            {tag.label}
                                        </Tag>
                                    ))}
                                </Wrap>
                            </VStack>
                        </VStack>
                    </VStack>
                </GridItem>
                {/* <GridItem gridArea="content">
                    <VStack w="full" align="start" fontWeight="medium" spacing={6}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={3}>
                                <Text fontSize="lg" fontWeight="semibold" color='whiteAlpha.800'>
                                    Highlights
                                </Text>
                            </HStack>
                        </HStack>
                        <SimpleGrid
                            columns={{ base: 1, lg: 2 }}
                            spacing={12}
                            // p={6}
                            // borderWidth={2}
                            // borderColor="whiteAlpha.300"
                            // borderStyle="solid"
                            // borderRadius="lg"
                        >
                            {highlights.map((item, index) => (
                                <VStack w="full" key={index} align="start" spacing={2}>
                                    <HStack w="full" spacing={4}>
                                        <Box p={2} bg="whiteAlpha.300" borderRadius="sm">
                                            <Box w={5} h={5}></Box>
                                        </Box>
                                        <Text
                                            fontWeight="semibold"
                                            fontSize="md"
                                            color="whiteAlpha.800"
                                        >
                                            {item.title}
                                        </Text>
                                    </HStack>
                                    <Text color="whiteAlpha.600">{item.description}</Text>
                                </VStack>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </GridItem> */}
                <GridItem gridArea="requirements">
                    {props.study.dependencies.items.length > 0 && (
                        <VStack
                            w="full"
                            align="start"
                            spacing={6}
                            // divider={<StackDivider borderWidth={1} />}
                        >
                            <HStack w="full" justify="space-between">
                                <HStack spacing={3} color="whiteAlpha.800">
                                    <Text fontSize="lg" fontWeight="semibold">
                                        Dependencies
                                    </Text>
                                    <Tooltip
                                        p={4}
                                        placement="top"
                                        hasArrow={true}
                                        bg="#1D1C21"
                                        label={`Dependencies are the necessary information for accessing the report. You and all participants must integrate this data and complete the survey to gain access`}
                                    >
                                        <Box as="span">
                                            <Icon
                                                fontSize="xl"
                                                as={AiOutlineInfoCircle}
                                            />
                                        </Box>
                                    </Tooltip>
                                </HStack>
                                <HStack>
                                    <Text
                                        cursor="default"
                                        fontSize="sm"
                                        textTransform="uppercase"
                                        letterSpacing="wider"
                                        fontWeight="bold"
                                        color="whiteAlpha.600"
                                    >
                                        {props.study.dependencies.status.label}
                                    </Text>
                                </HStack>
                            </HStack>
                            {!props.study.dependencies.status.isFulfilled && (
                                <Alert status="warning">
                                    <AlertIcon />
                                    <AlertTitle cursor="default">
                                        Fulfill dependencies to access report
                                    </AlertTitle>
                                </Alert>
                            )}
                            {props.study.dependencies.status.isFulfilled && (
                                <Alert status="success">
                                    <AlertIcon />
                                    <AlertTitle cursor="default">
                                        Dependencies are fulfilled for report
                                    </AlertTitle>
                                </Alert>
                            )}
                            {props.study.dependencies.items.map((itemProps, index) => (
                                <DependencyItem key={index} {...itemProps} />
                            ))}
                            <HStack w="full" justify="end">
                                <Text
                                    fontWeight="medium"
                                    // color="blue.300"
                                    color="whiteAlpha.600"
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                >
                                    Learn more about report dependencies
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </GridItem>
            </Grid>
        );
    };
}
