import { InvitationAcceptPayload } from '../../../../domain/accounts';
import { AcceptProfileFormValues } from '../profile';
import { RegistrationAcceptCreateContainerProps } from '../registrationAcceptCreateProps';
import { AcceptOrganizationFormValues } from './acceptOrganizationModel';

export function buildServicePayload(
    props: RegistrationAcceptCreateContainerProps & {
        profile: AcceptProfileFormValues;
        organization: AcceptOrganizationFormValues;
    }
): InvitationAcceptPayload {
    return {
        token: props.token,
        invitation_id: props.invitation.id,
        email: props.profile.email,
        first_name: props.profile.firstname,
        last_name: props.profile.lastname,
        // title: props.profile.role ?? undefined,
        organization: {
            name: props.organization.name,
            url: props.organization.website,
        },
    };
}
