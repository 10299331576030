export const PageIds = {
    HOME: 'home',
    REPORTS: 'reports',
    SETTINGS_ACCOUNT_SUBSCRIPTION: 'settings_billing_subscription',
    SETTINGS_ACCOUNT_PRIVACY: 'settings_billing_privacy',
    SETTINGS_ASSET_GENERAL_SETTINGS: 'settings_asset_general_settings',
    SETTINGS_ASSET_MAPPING: 'settings_asset_mapping',
    SETTINGS_DATASET_CONNECTION: 'settings_asset_dataset_connections',
    SETTINGS_ASSET_IMPORTS_LIST: 'settings_asset_imports_list',
    SETTINGS_ASSET_IMPORTS_NEW: 'settings_asset_imports_new',
    SETTINGS_DATA_SOURCE: 'settings_asset_data_sources',
    SETTINGS_ASSET_PLUGIN: 'settings_asset_plugin',
    REPORTS_LIST: 'reports_list',
    REPORTS_DETAIL: 'reports_detail',
    STUDIES_OVERVIEW: 'studies_overview',
    STUDIES_SUBMISSION_LIST: 'studies_submission_list',
    STUDIES_SUBMISSION_DETAIL: 'studies_submission_detail',
    STUDIES_SUBMISSION_NEW: 'studies_submission_new',
    STUDIES_RESPONSE_DETAIL: 'studies_response_detail',
    PEER_OVERVIEW: 'peer_overview',
    PEER_COMPETITIVE_SET_LIST: 'peer_competitive_set_list',
    PEER_COMPETITIVE_DETAIL: 'peer_competitive_set_detail',
    PEER_COMPETITIVE_SET_NEW: 'peer_competitive_set_new',
    PEER_COMPETITIVE_SET_EDIT: 'peer_competitive_set_edit',
    PEER_COMPETITIVE_SET_MEMBER_EDIT: 'peer_competitive_set_member_edit',
    NETWORKS_EXPERT_CALL_REQUEST_NEW: 'expert_call_request_new',
    REGISTRATION_ACCEPT_WELCOME: 'registration_accept_welcome',
    REGISTRATION_ACCEPT_USER: 'registration_accept_user',
    REGISTRATION_ACCEPT_ORGANIZATION: 'registration_accept_organization',
    REGISTRATION_ACCEPT_COMPLETE: 'registration_accept_complete',
} as const;

export const FormIds = {
    STUDY_SUBMISSION: 'studies_submission_new',
    NETWORK_EXPERT_CALL_REQUEST_NEW: 'expert_call_request_new',
    PEER_GROUP_CREATE: 'peers_group_create',
    PEER_GROUP_EDIT: 'peers_group_edit',
    PEER_GROUP_MEMBER_EDIT: 'peers_group_member_edit',
    ONBOARDING_COMPETTIVE_SET: 'onboarding_competitive_set',
};
