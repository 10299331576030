import React from 'react';
import {
    Box,
    Center,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react';
import { CompanyListboxMatchViewProps } from '../../../../macro';
import { PeerViewConfig } from '../../base';
import { AiOutlineArrowRight, AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { createPeerCompanyListView } from '../list';
import { createPeerCompanyItemView } from '../item';

export function createPeerCompanySearchView(
    config: PeerViewConfig
): React.FC<CompanyListboxMatchViewProps> {
    const CompanyList = createPeerCompanyListView();
    const CompanyItem = createPeerCompanyItemView(config);
    return (props) => {
        return (
            <VStack align="start" w="full" h="full" spacing={4}>
                <InputGroup>
                    <InputLeftElement mx={1}>
                        <Icon color="whiteAlpha.600" as={AiOutlineSearch} />
                    </InputLeftElement>
                    <Input
                        {...props.search.input}
                        autoFocus={true}
                        borderWidth={2}
                        borderRadius="full"
                        placeholder="Search companies"
                        _placeholder={{ color: 'whiteAlpha.600' }}
                        _focus={{ outline: 'none', borderColor: 'whiteAlpha.400' }}
                    />
                    {props.search.isLoading && (
                        <InputRightElement mx={1}>
                            <HStack justify="end">
                                <Spinner color="whiteAlpha.600" size="sm" speed="0.5s" />
                            </HStack>
                        </InputRightElement>
                    )}
                    {!props.search.isLoading && props.search.clear && (
                        <InputRightElement mx={1}>
                            <IconButton
                                {...props.search.clear}
                                variant="link"
                                aria-label="clear"
                                icon={<Icon as={AiOutlineClose} />}
                                color="whiteAlpha.600"
                                _focus={{ outline: 'none' }}
                                _hover={{ color: 'whiteAlpha.700' }}
                            />
                        </InputRightElement>
                    )}
                </InputGroup>
                {props.items.length === 0 && (
                    <Center
                        w="full"
                        h="full"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderStyle="dashed"
                        borderRadius="lg"
                    >
                        <VStack
                            align="start"
                            h="full"
                            justify="center"
                            maxW="16rem"
                            spacing={3}
                        >
                            <Box p={3} bg="whiteAlpha.300">
                                <Icon w={6} h={6} as={AiOutlineSearch} />
                            </Box>
                            <VStack align="start" spacing={1}>
                                <Text
                                    cursor="default"
                                    fontWeight="semibold"
                                    color="whiteAlpha.800"
                                >
                                    No company found
                                </Text>
                                <Text
                                    cursor="default"
                                    fontWeight="medium"
                                    color="whiteAlpha.600"
                                >
                                    Expand your filters to match more companies
                                </Text>
                                <HStack color="blue.300" spacing={2}>
                                    <Text
                                        cursor="pointer"
                                        fontWeight="medium"
                                        userSelect="none"
                                        _hover={{ textDecor: 'underline' }}
                                    >
                                        Add company manually
                                    </Text>
                                    <Icon as={AiOutlineArrowRight}></Icon>
                                </HStack>
                            </VStack>
                        </VStack>
                    </Center>
                )}
                {props.items.length > 0 && (
                    <CompanyList label={props.collection.label}>
                        {props.items.map((item) => {
                            const itemProps = props.getItemProps(item);
                            return <CompanyItem key={itemProps.id} {...itemProps} />;
                        })}
                    </CompanyList>
                )}
            </VStack>
        );
    };
}
