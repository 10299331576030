import { capitalize } from 'lodash';
import { Transparancy } from '../../../../../models/Metadata';
import { buildStatusProps, StatusProps } from '../../../../view/common';
import { PeerDatasetEligibilityItemAggregate } from '../item';

export function buildDatasetEligibilityStatusProps(
    item: PeerDatasetEligibilityItemAggregate
): StatusProps<Transparancy['quality']> {
    const {
        healthcheck: {
            transparency: { quality },
        },
    } = item;
    if (quality === 'insufficient_sample') {
        return buildStatusProps({
            kind: 'error',
            label: 'Insufficient',
            description: `Group does not have enough companies connected to this dataset`,
            reason: null,
            value: quality,
            ordinal: 1,
        });
    }
    return buildStatusProps({
        kind: 'success',
        label: 'Eligible',
        value: quality,
        description: `Group meets the minimum required number of companies connected to this dataset`,
        ordinal: 0,
    });
}
