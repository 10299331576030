import { useContext, useMemo } from 'react';
import { OnboardingAssetStateService } from '../../../service/onboarding';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { OrganizationRouteController } from '../../../route';
import { LoadedLookup } from '../../../base';
import { AssetOnboardingState } from '../../../domain/onboarding';
import { FeatureFlagContext } from '../featureFlagConstant';

export function createFeatureFlagPeersMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceController(
            controller: OrganizationRouteController
        ): OrganizationRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    return {
                        ...viewProps,
                        peersUrl: context.flags.peers?.enabled
                            ? viewProps.peersUrl
                            : null,
                    };
                },
            };
        }

        function enhanceOnboardingService(
            service: OnboardingAssetStateService
        ): OnboardingAssetStateService {
            return {
                ...service,
                useLookup(context, ...args) {
                    const result = service.useLookup(context, ...args);
                    const features = useContext(FeatureFlagContext);
                    if (!features) {
                        throw new Error(`feature flag context not found`);
                    }
                    return useMemo((): LoadedLookup<AssetOnboardingState> => {
                        if (features.flags.peers?.enabled) {
                            return result;
                        }
                        // filter out the competitive set onboarding step when feature flag
                        // is not enabled
                        return {
                            ...result,
                            data: {
                                ...result.data,
                                steps: result.data.steps.filter(
                                    (candidate) => candidate.id !== 'competitive_set'
                                ),
                            },
                        };
                    }, [result.data, features.flags.peers?.enabled]);
                },
            };
        }

        return create({
            ...config,
            service: {
                ...config.service,
                onboarding: {
                    ...config.service.onboarding,
                    createAssetState(...args) {
                        const service = config.service.onboarding.createAssetState(
                            ...args
                        );
                        return enhanceOnboardingService(service);
                    },
                },
            },
            route: {
                ...config.route,
                createOrganizationRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
                createOrganizationWorkspaceRoute(routeConfig) {
                    return config.route.createOrganizationRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            createController(...args) {
                                const controller = routeConfig.providers.createController(
                                    ...args
                                );
                                return enhanceController(controller);
                            },
                        },
                    });
                },
            },
        });
    };
}
