import { capitalize } from '../../../../../utils/strings';
import {
    getReportAccessStatus,
    getReportDependencyStatus,
    WorkspaceContextV2,
} from '../../../../app';
import { buildDataSourceListProps } from '../data-source';
import { buildDependencyListProps } from '../dependency';
import { buildDependencyItemProps } from '../dependency/item';
import { buildAccessStatusProps, buildDependencyStatusProps } from '../status';
import { ReportItemAggregate } from './studyItemModel';
import { StudyItemViewProps } from './studyItemProps';

export function buildStudyItemViewProps(
    context: WorkspaceContextV2,
    item: ReportItemAggregate
): StudyItemViewProps {
    const accessStatus = getReportAccessStatus(item);
    const dependencyStatus = getReportDependencyStatus(item);

    return {
        iconUrl: '',
        label: item.report.name,
        description: item.report.description,
        category: {
            label: capitalize(item.report.category),
        },
        status: {
            access: buildAccessStatusProps(accessStatus),
            dependency: buildDependencyStatusProps(dependencyStatus),
        },
        thumbnail: {
            image: {
                src: item.report.thumbnail.url,
                alt: item.report.thumbnail.description ?? 'thumbnail',
            },
        },
        dependencies: buildDependencyListProps(item),
        dataSource: buildDataSourceListProps(item),
        highlight: null,
        tags: [
            ...item.report.tags.map((tag) => ({
                label: capitalize(tag),
            })),
        ],
        links: {
            join: item.dataset
                ? {
                      to: `/u/assets/${context.workspace.id}/reporting/submissions/new?survey=${item.dataset.id}`,
                  }
                : null,
            detail: {
                to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.id}`,
            },
            submission: item.submission
                ? {
                      detail: {
                          to: `/u/assets/${context.workspace.id}/reporting/submissions/${item.submission.id}`,
                      },
                  }
                : null,
            responses: {
                to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.id}`,
            },
            // accessStatus === 'available'
            //     ? {
            //           to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.id}/data`,
            //       }
            //     : {
            //           to: `/u/assets/${context.workspace.id}/reporting/reports/${item.report.id}`,
            //       },
        },
    };
}
