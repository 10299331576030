import React from 'react';
import { observer } from 'mobx-react';
import {
    useControlApi,
    useDashboardApi,
    useDashboardStateApi,
    useQueryApi,
} from '../../../app';
import { createDashboardGroupController } from '../../../view';
import { DashboardGroupContainerProps } from './dashboardGroupProps';
import { createDashboardGroupLoader } from './dashboardGroupLoader';
import { DashboardGroupConfig } from './dashboardGroupConfig';

export function createDashboardGroupContainer(
    config: DashboardGroupConfig
): React.FC<DashboardGroupContainerProps> {
    const { hook } = config;
    const controller = createDashboardGroupController();
    const loader = createDashboardGroupLoader(config);
    return observer(({ as: Component, ...props }) => {
        const api = {
            dashboard: useDashboardApi(),
            query: useQueryApi(),
            state: useDashboardStateApi(),
            control: useControlApi(),
        };
        const context = {
            auth: hook.useAuth(),
            organization: hook.useOrganization(),
            workspace: hook.useWorkspace(),
        };
        const viewData = loader.useLoad(context, {
            plugin: {
                id: api.dashboard.getDashboard().pluginId,
            },
        });

        const viewProps = controller.useProps(viewData, {
            selected: {
                value: api.control
                    .getSegmentGroupValue()
                    .map((item) => item.replace('grp_', 'cs_')),
                onChange(value) {
                    api.control.setSegmentGroupValue(
                        // HACK to make group endpoints work with new competitive set model.
                        // current only the prefix differs in the ID for these models
                        value.map((item) => item.replace('cs_', 'grp_'))
                    );
                },
            },
        });
        return <Component {...viewProps} />;
    });
}
