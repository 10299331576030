import { chain } from 'lodash';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildStatusProps } from '../../../../view/common';
import { PeerDatasetEligibilityItemAggregate } from '../item';
import { PeerDatasetEligibilityItemViewProps } from '../item/peerDatasetEligibilityItemProps';
import { buildDatasetEligibilityStatusProps } from '../status';
import { PeerDatasetEligibilityListConfig } from './peerDatasetEligibilityListConfig';
import { PeerDatasetEligibilityController } from './peerDatasetEligibilityListInterface';

export function createPeerDatasetEligibilityController(
    config: PeerDatasetEligibilityListConfig
): PeerDatasetEligibilityController {
    function buildItemProps(
        item: PeerDatasetEligibilityItemAggregate
    ): PeerDatasetEligibilityItemViewProps {
        return {
            plugin: {
                label: item.plugin.name,
                image: { src: item.plugin.iconUrl },
                link: config.getLinkProps(item),
            },
            status: buildDatasetEligibilityStatusProps(item),
        };
    }
    return {
        useProps(item, props) {
            const itemPropsById = useMemo(
                () =>
                    item.items.reduce(
                        (acc, item) => {
                            return { ...acc, [item.id]: buildItemProps(item) };
                        },
                        {} as Record<
                            string,
                            PeerDatasetEligibilityItemViewProps | undefined
                        >
                    ),
                [item.items]
            );
            const items = useMemo(
                () =>
                    chain(item.items)
                        .orderBy((item) => {
                            const itemProps = itemPropsById[item.id];
                            assert(itemProps, 'no item props');
                            return itemProps.status.ordinal;
                        }, 'asc')
                        .value(),
                [item.items]
            );
            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.id];
                    assert(itemProps, 'no item props');
                    return itemProps;
                },
            };
        },
    };
}
