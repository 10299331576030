import { assertNever } from '../../../../util';
import { getPeerSetStatus, PeerSetStatus } from '../../../../app';
import { buildStatusProps, StatusProps } from '../../../../view/common';
import { PeerSetStatusAggregate } from './peerSetStatusModel';

export function buildPeerSetStatusProps(
    item: Pick<PeerSetStatusAggregate, 'peerset' | 'invitation'>
): StatusProps<PeerSetStatus['kind']> {
    const status = getPeerSetStatus(item);
    if (status.kind === 'eligible') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'success',
            label: 'Active',
            description: null,
            ordinal: 0,
        });
    }
    if (status.kind === 'creating') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'warning',
            label: 'Pending',
            description: `There are not enough companies in the competitive set currently within Varos. We have invited the remaining companies to join Varos and we are still awaiting responses from invited companies to meet the minimum member requirement`,
            ordinal: 1,
        });
    }
    if (status.kind === 'updating') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'info',
            label: 'Active',
            description: `While you can use this competitive set to benchmark, some of the companies in the competitive are not within Varos. We have invited the remaining companies to join Varos and we are still awaiting responses`,
            ordinal: 2,
        });
    }
    if (status.kind === 'ineligible') {
        return buildStatusProps({
            id: 'comeptitive_set',
            value: status.kind,
            kind: 'warning',
            label: 'Ineligible',
            description: `The group does not have enough members yet and needs more companies to be added to become functional`,
            ordinal: 3,
        });
    }
    assertNever(status);
}
