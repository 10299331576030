import React from 'react';
import { WithAs } from '../../../../base';
import { PeerRouteConfig } from '../../base';
import {
    PeerCompanySelectController,
    PeerCompanySelectLoader,
} from './peerCompanySelectInterface';
import {
    CompanyListboxSelectContainerProps,
    CompanyListboxSelectViewProps,
} from './peerCompanySelectProps';

export function createCompanyListBoxSelectContainer(
    config: PeerRouteConfig,
    loader: PeerCompanySelectLoader,
    controller: PeerCompanySelectController
): React.FC<WithAs<CompanyListboxSelectContainerProps, CompanyListboxSelectViewProps>> {
    const {
        context: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const viewProps = controller.useProps(context, data, containerProps);
        return <View {...viewProps} />;
    };
}
