import { useMemo } from 'react';
import { isReferenceType } from '../../../../domain/attributes';
import { assert } from '../../../../util/assert';
import { StudiesBaseRouteConfig } from '../../base';
import { StudiesChartRouteLoader } from './studiesChartItemInterface';
import { StudiesChartRouteAggregate } from './studiesChartItemModel';

export function createStudiesChartItemLoader(
    config: Pick<StudiesBaseRouteConfig, 'repository' | 'strategy'>
): StudiesChartRouteLoader {
    const {
        repository: { response: responseRepository },
        strategy: {
            result: { create: getResult },
            queryable: { toQuery: getQuery },
        },
    } = config;
    return {
        useLoad(context, props): StudiesChartRouteAggregate {
            const {
                data: {
                    report: { dataset },
                },
            } = context;
            const visualization = props.visualization;

            assert(dataset, 'expected study report to have dataset loaded');

            // console.log('DEBUG context.state.filters', context.state.filters);

            const request = useMemo(
                () => ({
                    all: getQuery(
                        {
                            segment: context.state.filters,
                        },
                        {
                            id: dataset.id,
                            source: dataset.id,
                            columns: [
                                ...dataset.configuration.schema,
                                {
                                    // TODO refactor the get query to work with datasets and not just views
                                    // to avoid this hack
                                    key: dataset.configuration.partitionKey,
                                    name: 'Timestamp',
                                    type: 'date',
                                    description: null,
                                    isDisabled: false,
                                    disabledReason: null,
                                    constraints: [],
                                },
                            ],
                        },
                        visualization
                    ),
                    // peers: getQuery(
                    //     {
                    //         segment: context.state.filters,
                    //     },
                    //     {
                    //         id: dataset.id,
                    //         source: dataset.id,
                    //         columns: dataset.configuration.schema,
                    //     },
                    //     visualization
                    // ),
                }),
                [
                    context.state.filters,
                    dataset,
                    visualization,
                    context.state.segment,
                    context.state.filters,
                ]
            );

            const responseQuery = {
                all: responseRepository.useFind(
                    context,
                    {
                        request: request.all,
                        asset: { id: context.workspace.id as number },
                    },
                    { suspense: true, staleTime: Infinity, retry: false }
                ),
                // peers: responseRepository.useFind(
                //     context,
                //     {
                //         request: request.peers,
                //         asset: { id: context.workspace.id as number },
                //     },
                //     { suspense: true, staleTime: Infinity, retry: false }
                // ),
            };

            assert(responseQuery.all.status === 'success', 'expected suspense');
            // assert(responseQuery.peers.status === 'success', 'expected suspense');

            // NOTE we prefetch both queries to enable seamless switching between them
            const appliedQuery = responseQuery.all;
            // const appliedQuery =
            //     context.state.segment === 'all' ? responseQuery.all : responseQuery.peers;

            const resultProps = useMemo(() => {
                return getResult(
                    {
                        id: dataset.id,
                        columns: dataset.configuration.schema.map((item) => {
                            assert(
                                !isReferenceType(item.type),
                                'expected inline types for report dataset configuration'
                            );
                            return { ...item, type: item.type };
                        }),
                    },
                    visualization,
                    appliedQuery.data
                );
            }, [dataset, visualization, appliedQuery.data]);

            return {
                result: resultProps,
                visualization,
            };
        },
    };
}
