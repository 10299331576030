import { ChevronRightIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import {
    CompanyListboxMatchViewProps,
    CompanyListboxSelectViewProps,
} from '../../../macro';
import { PeerViewConfig } from '../base';
import { PeerCompanyEditViewProps } from './peerCompanyEditProps';

export function createCompanyEditView(
    config: PeerViewConfig,
    MatchList: React.FC<CompanyListboxMatchViewProps>,
    SelectList: React.FC<CompanyListboxSelectViewProps>
): React.FC<PeerCompanyEditViewProps> {
    const {
        macro: {
            company: {
                select: {
                    Component: { Select: SelectContainer, Match: MatchContainer },
                },
            },
        },
        UI: {
            Application: { Link, Anchor },
            Input: InputUI,
        },
    } = config;

    return (props) => {
        const headerProps = props.page.getHeaderProps();
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Grid
                    w="full"
                    h="90vh"
                    templateAreas={`
                    "header header header"
                    "left center right"
                    "footer footer footer"
                `}
                    templateRows="min-content minmax(0, 1fr) min-content"
                    templateColumns="minmax(0, 1fr) min-content minmax(0, 1fr)"
                >
                    <GridItem area="header">
                        <VStack w="full" align="start" spacing={4} py={6}>
                            <HStack w="full" align="start">
                                <HStack w="full" spacing={3}>
                                    <Heading fontSize="2xl">{headerProps.title}</Heading>
                                    {headerProps.description && (
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            p={4}
                                            bg="#1D1C21"
                                            label={headerProps.description}
                                        >
                                            <Box as="span">
                                                <Icon
                                                    fontSize="2xl"
                                                    as={AiOutlineInfoCircle}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )}
                                </HStack>
                                <HStack spacing={6}>
                                    {/* @ts-expect-error this actually works */}
                                    <Link to={-1}>
                                        <Button
                                            size="sm"
                                            variant="link"
                                            _focus={{ outline: 'none' }}
                                        >
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button
                                        {...props.form.getSubmitButtonProps()}
                                        size="sm"
                                        colorScheme="green"
                                        _focus={{ outline: 'none' }}
                                    />
                                </HStack>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem area="left">
                        <MatchContainer {...props.listbox.match} as={MatchList} />
                    </GridItem>
                    <GridItem area="center">
                        <VStack h="full" w="full" align="center" spacing={6} px={12}>
                            <Box flex={1} w={0.5} bg="whiteAlpha.200"></Box>
                            <Icon
                                fontSize="3xl"
                                color="whiteAlpha.400"
                                as={HiOutlineSwitchHorizontal}
                            />
                            <Box flex={1} w={0.5} bg="whiteAlpha.200"></Box>
                        </VStack>
                    </GridItem>
                    <GridItem area="right">
                        <VStack w="full" h="full" align="start" spacing={3}>
                            {props.form.error && (
                                <Alert status="error" w="full">
                                    <AlertIcon />
                                    <AlertTitle>{props.form.error.label}</AlertTitle>
                                </Alert>
                            )}
                            {props.form.fieldErrors.companies && (
                                <Alert status="error" w="full">
                                    <AlertIcon />
                                    <AlertTitle>
                                        {props.form.fieldErrors.companies.message}
                                    </AlertTitle>
                                </Alert>
                            )}
                            <SelectContainer {...props.listbox.select} as={SelectList} />
                        </VStack>
                    </GridItem>
                    <GridItem area="footer">
                        <HStack w="full" py={6} justify="end">
                            <Anchor
                                {...props.anchor.documentation}
                                cursor="pointer"
                                color="blue.300"
                                fontWeight="medium"
                                _hover={{ textDecor: 'underline' }}
                            >
                                Learn more about competitive sets
                            </Anchor>
                        </HStack>
                    </GridItem>
                </Grid>
            </form>
        );
    };
}
