import React from 'react';
import { RegistrationContainerConfig } from '../base';
import { RegistrationAcceptCreateContainerProps } from './registrationAcceptCreateProps';
import { RegistrationAcceptCreateOptionsSchema } from './registrationAcceptCreateSchema';

export function createRegistrationAcceptCreateRoute(
    config: RegistrationContainerConfig,
    Container: React.FC<RegistrationAcceptCreateContainerProps>
): React.FC {
    const {
        infra: {
            options: { useOptions },
        },
    } = config;
    return () => {
        const options = useOptions(RegistrationAcceptCreateOptionsSchema);
        return (
            <Container
                token={options.token}
                invitation={{ id: options.invitation }}
                step={options.step ?? null}
            />
        );
    };
}
