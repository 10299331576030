import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useSize, useWindowSize } from 'react-use';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Center,
    Container,
    Heading,
    HStack,
    LightMode,
    Spinner,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { Image, Text } from '@chakra-ui/react';
import Logo from '../../../svg/varos-logo-small-white.svg';
import { useStore } from '../../../stores/setupContext';
import { CardSpinner } from '../../../domain';
import { Formatter, FormatterImpl } from '../../../hooks';
import { createDependencyStatusImpl, createLegacyUserProvider } from '../../impl';
import {
    createWorkspaceSelectionView,
    createReportContainer,
    createReportController,
    createInsightView,
    createReportTableController,
    createReportQueryController,
    createInsightController,
    createInsightContainer,
    UserNavigationView,
    createAssetMappingView,
    createMappingController,
    createWorkspaceSelectionController,
    createWorkspaceSelectionContainer,
    createUserInviteFormContainer,
    createUserInviteFormView,
    createUserInviteFormController,
    createCohortController,
    createCohortView,
    createBenchmarkListController,
    createBenchmarkListView,
    createBenchmarkListLoader,
    FilterDateView,
    FilterListView,
    FilterItemView,
    SegmentListView,
    CompetitiveSetView,
    createUserListView,
    createUserListController,
    createGroupsFormView,
    createGroupRedirect,
} from '../../view';
import { createButtonSelect } from '../../atom/form';
import {
    configureExportProvider,
    DashboardDefaultRedirect,
    DashboardSetupCompleteRedirect,
    DashboardErrorBoundary,
    configureWorkspaceProvider,
    configureFeatureProvider,
    configureDashboardProvider,
    configureLegacyOrganizationProvider,
    configureSessionProvider,
    configureRecommendationProvider,
    createDashboardConfig,
} from '../../container';
import {
    useWorkspaceContext,
    useOrganizationScope,
    useUserContext,
    useUserApi,
} from '../../app';
import {
    DEFAULT_PLAN_ID,
    getDestinationItems,
    getIntegrationDefinitionOverrides,
    GRAY,
    HOME_VIDEOS,
    LocalStorageKeys,
    PageIds,
    VAROS_GREEN,
    ViewIds,
} from '../../config';
import {
    createReportLayout,
    createReportTableContainer,
    createReportTableView,
    createEditScheduleFormContainer,
    createEditScheduleFormController,
    createScheduleEditView,
    createScheduleListController,
    createScheduleListView,
    createScheduleListContainer,
    createReportView,
} from '../../view/_reportsold';
import { AnyFilter, AuthenticationV2 } from '../../domain';
import {
    useControlApi,
    useDashboardContext,
    useStorageContext,
    useWorkspaceApi,
} from '../../app/dashboard';
import { ApplicationEntrypointConfig } from './applicationConfig';
import { ApplicationEntryEnhancer } from './applicationMiddleware';
import { Application } from './applicationModel';
import { urlConfig } from '../../../config';
import { createUserController, useVarosDatasetController } from '../../controller';
import { SCROLLBAR_STYLE } from '../../style';
import {
    OrganizationRouteConfig,
    createHomeRouteController,
    createOrganizationRouteView,
    createOrganizationRouteController,
    createHomeRouteView,
    COMPARISON_INTERVALS,
    PERIOD_INTERVALS,
    createAnalysisRouteController,
    createAnalysisRouteLoader,
    createAnalysisRouteView,
    createHomeRouteLoader,
    createOrganizationRouteLoader,
    createGroupsIntroView,
} from '../../route';
import {
    createSubscriptionListController,
    createSubscriptionListView,
} from '../../view/notifications';
import {
    createDashboardCohortControl,
    createSettingAccountAssetStrategy,
} from '../../strategy';
import {
    createWorkspaceListContainer,
    createWorkspaceListController,
    createWorkspaceListView,
    createEditWorkspaceFormContainer,
    createEditWorkspaceFormController,
    createWorkspaceEditView,
} from '../../view/platform/workspace';
import { createCacheRegistrar } from '../../service/infra';
import { createDashboardLayoutController } from '../../layout';
import { DashboardSlugs } from '../../../config/dashboard';
import { createPercentileRank } from '../../atom/visualization';
import {
    createCollectionEditController,
    createMetricCollectionEditView,
    createMetricCollectionEditLoader,
    createMetricSelectController,
    createMetricSelectView,
} from '../../view/metrics';
import {
    createOrganizationContextLoader,
    createSystemContextLoader,
    createWorkspaceContextLoader,
    useOrganizationContextV2,
    useWorkspaceContextV2,
} from '../../context';
import {
    BarVisualization,
    createBarStrategy,
    createLineStrategy,
    LineVisualization,
} from '../../../strategy';
import { createVisualizationIntl } from '../../intl';
import { DashboardCohortView } from '../../strategy/dashboard/cohort/controlCohortView';
import {
    createOnboardingPeerGroupLoader,
    createOnboardingPeerGroupView,
} from '../../strategy/onboarding';
import { createPropertyContainer } from '../../strategy/input';
import { Input } from '../../ui';
import { OnboardingStateV2 } from '../../domain/onboarding';
import { createUserListContainer } from '../../view';
import { createImportNewFormProvider } from '../../view/datasets';
import { QUERYBUILDER } from '../../../container';
import { createPlanModalLoader } from '../../route/billing';
import { assert } from '../../util/assert';

const RecommendationProvider = configureRecommendationProvider();
const AuthSessionProvider = configureSessionProvider({});

const LegacyOrganizationProvider = configureLegacyOrganizationProvider();

const WorkspaceProvider = configureWorkspaceProvider({
    paramKey: 'workspaceId',
    queryKey: 'asset',
    workspaceNotFoundPathName: '/u/assets/pending',
});

const FeatureProvider = configureFeatureProvider();

const ExportProvider = configureExportProvider();

const DashboardProvider = configureDashboardProvider({
    param: 'dashboardId',
});

const LegacyUserProvider = createLegacyUserProvider();

export function createApplicationEntrypoint(
    config: ApplicationEntrypointConfig,
    enhancer?: ApplicationEntryEnhancer
): Application {
    if (enhancer) {
        return enhancer(createApplicationEntrypoint)(config);
    }
    const {
        provider: { createStorageProvider },
        components: { Route, Outlet },
        route: {
            createOrganizationRoute,
            createOrganizationWorkspaceRoute,
            install,
            integration,
            ...route
        },
    } = config;

    const axios = config.infra.createAxios({});

    const infra = {
        help: config.infra.createHelp({
            getIntercom: config.infra.getIntercom,
        }),
        getIntercom: config.infra.getIntercom,
        errorFormatter: config.infra.createErrorFormatter(),
        formatter: config.infra.createFormatter(),
        formatterOld: new FormatterImpl(),
        http: config.infra.createHttp(axios),
        axios: axios,
        optionParser: config.infra.createUrlOptionParser(),
        useTracker: config.infra.createUseTracker(),
        toaster: config.infra.createToaster(),
    };

    const api = {
        asset: config.api.createAssetApi({
            client: infra.axios,
        }),
        platform: config.api.createPlatformApi({
            client: infra.axios,
        }),
        company: config.api.createCompanyApi({
            client: infra.axios,
        }),
        billing: config.api.createBillingApi({
            client: infra.axios,
        }),
        ingestion: config.api.createDataIngestionApi({
            client: infra.axios,
        }),
        reportold: config.api.createReportApi({
            client: infra.axios,
        }),
        report: config.api.createReportApiV2({
            client: infra.axios,
        }),
        insight: config.api.createInsightApi({
            client: infra.axios,
        }),
        dashboard: config.api.createDashboardApi({
            client: infra.axios,
        }),
        notification: config.api.createNotificationApi({
            client: infra.axios,
        }),
        query: config.api.createQueryApi({
            client: infra.axios,
        }),
        metric: config.api.createMetricApi({
            client: infra.axios,
        }),
        group: config.api.createGroupApi({
            client: infra.axios,
        }),
    };

    const _ApplicationUI = config.ui.createWorkspaceUI();
    const UI = {
        Onboarding: {
            Form: config.ui.createForm({
                Theme: config.theme.createOnboardingForm({
                    UI: {
                        Application:
                            config.theme.createOnboardingApplication(_ApplicationUI),
                    },
                }),
            }),
        },
        Settings: config.ui.createSettingsUI(_ApplicationUI),
        Application: _ApplicationUI,
        Visualization: config.ui.createVisualization(),
        Input: config.ui.createInput(),
        Date: config.ui.createDate({
            date: {
                infra: {
                    formatter: infra.formatter,
                    legacyFormatter: infra.formatterOld,
                },
            },
        }),
    };

    const Provider = {
        Auth: config.provider.createAuth(),
        Organization: config.provider.createOrganization(),
        Workspace: config.provider.createWorkspace(),
        User: config.provider.createUser(),
        Access: config.provider.createAccess(),
    };

    const Redirect = {
        Root: config.redirect.createRoot(),
    };

    const Layout = {
        Organization: {
            Base: config.layout.createOrganizationBaseLayout({ UI: UI.Application }),
            Audit: config.layout.createOrganizationAuditLayout(),
        },
        Workspace: config.layout.createWorkspaceLayout(),
        Settings: config.layout.createSettingsLayout({}),
        Onboarding: config.layout.createOnboardingLayout({ UI: UI.Application }),
        Home: config.layout.createHomeLayout({
            UI: UI.Settings,
            errorFormatter: infra.errorFormatter,
        }),
        Dashboard: config.layout.createDashboardLayout(),
        Analysis: config.layout.createAnalysisLayout({
            UI: UI.Application,
            errorFormatter: infra.errorFormatter,
            BaseLayout: config.layout.createDashboardLayout(),
            style: {
                wrapper: {
                    // gridTemplateColumns: 'minmax(18rem, 14.2vw) 1fr',
                },
                sidebar: {
                    gridTemplateRows: 'min-content 1fr min-content',
                    color: 'whiteAlpha.900',
                    bg: GRAY[800],
                },
                scroll: {
                    __css: SCROLLBAR_STYLE,
                },
                main: {
                    gridTemplateRows: 'min-content 1fr',
                },
                segment: {
                    px: 6,
                },
                competitive: {
                    mx: 6,
                    mb: 6,
                    py: 6,
                    bg: GRAY[800],
                    borderBottomWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'whiteAlpha.300',
                },
                controls: {
                    px: 4,
                    bg: 'black',
                },
                actions: {
                    py: 3,
                    borderBottomWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'whiteAlpha.300',
                },
                filters: {
                    py: 3,
                },
                cards: {
                    px: 4,
                    paddingBottom: 24,
                },
            },
        }),
    };
    const registrar = createCacheRegistrar();

    const hooks = {
        onboarding: {
            useLocalStorage: config.hook.createUseLocalStorage<OnboardingStateV2 | null>({
                // expireMs: 1000 * 60 * 60 * 24 * 7,
                storageKey: LocalStorageKeys.ONBOARDING_STATE,
                initialValue: { step: 'profile', organization: null, asset: null },
            }),
        },
        home: {
            useLocalStorage: config.hook.createUseLocalStorage<
                Record<string, boolean | undefined>
            >({
                storageKey: LocalStorageKeys.HOME_HIDDEN,
                // expire after a 4 weeks
                // expireMs: 1000 * 60 * 60 * 24 * 30,
                initialValue: {},
            }),
        },
        useQuery,
        useMutation,
        useBuilder: config.infra.createQueryBuilder(),
        useValueFormatter() {
            return infra.formatter;
        },
        useFormatter(): Formatter {
            return useMemo(() => new FormatterImpl(), []);
        },
        useAuth(): AuthenticationV2 {
            const { auth: store } = useStore();
            const userId = store.currentUser?.id;
            return {
                user: userId
                    ? {
                          id: userId,
                      }
                    : null,
                scheme: {
                    kind: 'legacy',
                    store,
                },
            };
        },
        useOrganization() {
            const context = useOrganizationScope();
            return context.organization;
        },
        useOrganizationContext() {
            const context = useOrganizationContextV2();
            return context;
        },
        useWorkspace() {
            const context = useWorkspaceContext();
            return context.workspace;
        },
        useUser() {
            const context = useUserContext();
            return context;
        },
    };

    const adapter = {
        metrics: {
            definition: config.adapter.metric.createDefinition({
                api: {
                    metric: api.metric,
                },
            }),
            summary: config.adapter.metric.createSummary({
                axios,
                infra: {
                    builder: QUERYBUILDER,
                },
                api: {
                    metric: api.metric,
                },
            }),
        },
        accounts: {
            profile: config.adapter.accounts.createProfile({
                config: {
                    defaultPlan: DEFAULT_PLAN_ID,
                },
                api,
                adapter: {
                    asset: config.adapter.assets.createAsset({ api }),
                    registration: config.adapter.registration.createOption({}),
                    preference: config.adapter.platform.createClientPreference({
                        api: {
                            platform: api.platform,
                        },
                    }),
                },
            }),
            invitation: config.adapter.accounts.createInvitation({
                api,
            }),
        },
        registration: {
            option: config.adapter.registration.createOption({}),
        },
        assets: {
            connection: config.adapter.assets.createConnection({
                api,
            }),
            asset: config.adapter.assets.createAsset({
                api: api,
            }),
            definition: config.adapter.assets.createDefinition({
                config: {
                    overridesByIntegrationType: getIntegrationDefinitionOverrides(),
                },
                api: api,
            }),
            entity: config.adapter.assets.createEntity({
                api: api,
            }),
            integration: config.adapter.assets.createIntegration({
                api: api,
            }),
            destination: config.adapter.assets.createDestination({
                config: {
                    items: getDestinationItems(),
                },
            }),
        },
        dashboards: config.adapter.createDashboardAdapter({
            axios,
        }),
        connections: config.adapter.platform.createConnection({
            axios,
        }),
        competitiveSet: config.adapter.createCompetitiveSetAdapter({
            api,
            axios,
            hooks: {
                useQuery,
            },
        }),
        billing: {
            subscriptions: config.adapter.billing.createSubscription({ axios }),
            methods: config.adapter.billing.createPaymentMethod({
                api: {
                    billing: api.billing,
                },
            }),
            plans: config.adapter.billing.createPlan({
                api: {
                    billing: api.billing,
                },
            }),
            charge: config.adapter.billing.createCharge({
                sessionResource: api.billing.session,
            }),
        },
    };

    const repository = {
        personalization: {
            recommendation: config.v2.personalization.recommendation.createRepository(
                config.v2.personalization.recommendation.createAdapter({
                    api: {
                        platform: api.platform,
                    },
                })
            ),
        },
        permissions: {
            role: {
                organization: config.repository.permissions.role.createOrganization(
                    config.adapter.permissions.createRole({
                        api: {
                            platform: api.platform,
                        },
                    })
                ),
            },
        },
        reportsold: {
            report: config.repository.reportsold.createReport(
                config.adapter.reportsold.createReport({
                    api: {
                        report: api.reportold,
                    },
                })
            ),
        },
        reports: {
            report: config.repository.reports.createReport(
                config.adapter.reports.createReport({
                    api: {
                        report: api.report,
                    },
                })
            ),
            invitation: config.repository.reports.createInvitation(
                config.adapter.reports.createInvitation({
                    api: {
                        report: api.report,
                    },
                })
            ),
        },
        metrics: {
            configuration: config.repository.metric.createConfiguration({
                adapter: config.adapter.metric.createConfigurationV2({
                    api: {
                        platform: api.platform,
                    },
                    adapter: {
                        view: config.adapter.query.createView({
                            api: {
                                dashboard: api.dashboard,
                            },
                        }),
                    },
                }),
            }),
            collection: config.repository.metric.createCollection({
                api: {
                    metric: api.metric,
                },
            }),
            definition: config.repository.metric.createDefinition({
                adapter: {
                    definition: adapter.metrics.definition,
                },
            }),
            dashboard: config.repository.metric.createDashboard({
                adapter: adapter.dashboards,
            }),
            summary: config.repository.metric.createSummary({
                adapter: adapter.metrics.summary,
            }),
        },
        accounts: {
            profile: config.repository.accounts.createProfile(adapter.accounts.profile),
            invitation: config.repository.accounts.createInvitation(
                adapter.accounts.invitation
            ),
        },
        registration: {
            option: config.repository.registration.createOption({
                adapter: adapter.registration.option,
            }),
        },
        datasets: {
            dataset: config.repository.datasets.createDataset(
                config.adapter.datasets.createDataset({
                    api: {
                        ingestion: api.ingestion,
                    },
                })
            ),
            connection: config.repository.datasets.createDatasetConnection(
                config.adapter.datasets.createDatasetConnection({
                    api: {
                        ingestion: api.ingestion,
                    },
                })
            ),
            import: config.repository.datasets.createImport(
                config.adapter.datasets.createImport({
                    api: {
                        ingestion: api.ingestion,
                    },
                })
            ),
        },
        assets: {
            connection: config.repository.assets.createConnnection(
                adapter.assets.connection
            ),
            asset: config.repository.assets.createAsset(adapter.assets.asset),
            definition: config.repository.assets.createDefinition(
                adapter.assets.definition
            ),
            entity: config.repository.assets.createEntity(adapter.assets.entity),
            integration: config.repository.assets.createIntegration(
                adapter.assets.integration
            ),
            destination: config.repository.assets.createDestination(
                adapter.assets.destination
            ),
        },
        query: {
            view: config.repository.query.createView({
                adapter: config.adapter.query.createView({
                    api: {
                        dashboard: api.dashboard,
                    },
                }),
            }),
            response: config.repository.query.createResponse({
                adapter: config.adapter.query.createResponse({
                    api: {
                        query: api.query,
                    },
                }),
            }),
        },
        forms: {
            submission: config.repository.forms.createSubmission(
                config.adapter.forms.createSubmission({
                    api: {
                        ingestion: api.ingestion,
                    },
                })
            ),
            data: config.repository.forms.createData(
                config.adapter.forms.createData({
                    api: {
                        ingestion: api.ingestion,
                    },
                })
            ),
        },
        companies: {
            company: config.repository.companies.createCompany(
                config.adapter.companies.createCompany({
                    api: api,
                })
            ),
            industry: config.repository.companies.createIndustry(
                config.adapter.companies.createIndustry({
                    api: api,
                })
            ),
        },
        platform: {
            // connection: config.repository.platform.createConnection(
            //     axios,
            //     createDependencyStatusImpl({ axios })
            // ),
            connectionV2: config.repository.platform.createConnectionV2({
                adapter: {
                    dashboard: adapter.dashboards,
                },
            }),
            plugin: config.repository.platform.createPlugin(
                config.adapter.platform.createPlugin({
                    api,
                })
            ),
            clientPreference: config.repository.platform.createClientPreference(
                config.adapter.platform.createClientPreference({
                    api: {
                        platform: api.platform,
                    },
                })
            ),
            count: config.repository.platform.createCount(
                config.adapter.platform.createCount({
                    api: {
                        platform: api.platform,
                    },
                })
            ),
            organization: config.repository.platform.createOrganization({
                adapter: config.adapter.platform.createOrganization({
                    api: {
                        platform: api.platform,
                    },
                }),
            }),
            feature: config.repository.platform.createFeatureSet(
                config.adapter.platform.createFeatureSet({
                    axios,
                })
            ),
            collaborator: config.repository.platform.createCollaborator({
                adapter: config.adapter.platform.createCollaborator({
                    api: {
                        platform: api.platform,
                    },
                }),
            }),
        },
        insights: {
            opportunity: config.repository.insights.createOpportunity(
                config.adapter.insights.createOpportunity({
                    api: api.insight,
                })
            ),
            definition: config.repository.insights.createDefinition(
                config.adapter.insights.createDefinition({
                    api: api.insight,
                })
            ),
        },
        peers: {
            group: config.repository.peers.createPeerGroups(
                config.adapter.peers.createPeerGroup({
                    api: api.platform,
                })
            ),
            healthcheck: config.repository.peers.createHealthcheck(
                config.adapter.peers.createHealthcheck({
                    api: api.platform,
                })
            ),
            status: config.repository.peers.createPeerStatus(
                config.adapter.peers.createStatus({
                    api: api.platform,
                })
            ),
            peerset: config.repository.peers.createPeerset(
                config.adapter.peers.createPeerSet({
                    groups: api.group,
                    platform: api.platform,
                })
            ),
            member: config.repository.peers.createMember(
                config.adapter.peers.createMember({
                    api: api.group,
                })
            ),
        },
        attributes: {
            member: config.repository.attributes.createMember(
                config.adapter.attributes.createMember({
                    axios,
                })
            ),
        },
        groups: {
            group: config.repository.groups.createGroup(
                config.adapter.group.createGroupAdapter({
                    api: api.group,
                })
            ),
            invite: config.repository.groups.createGroupInvite(
                config.adapter.group.createGroupInviteAdapter({
                    api: api.group,
                })
            ),
            member: config.repository.groups.createGroupMember(
                config.adapter.group.createGroupMemberAdapter({
                    api: api.group,
                })
            ),
        },
        billing: {
            subscriptions: config.repository.billing.createSubscription({
                adapter: adapter.billing.subscriptions,
            }),
            methods: config.repository.billing.createPaymentMethod({
                adapter: adapter.billing.methods,
            }),
            plans: config.repository.billing.createPlan({
                adapter: adapter.billing.plans,
            }),
            plansV2: config.repository.billing.createPlan({
                adapter: adapter.billing.plans,
            }),
            charges: config.repository.billing.createCharge({
                adapter: adapter.billing.charge,
            }),
        },
    };

    const service = {
        peers: {
            peerset: config.v2.peers.peerset.createService({
                adapter: {
                    company: config.adapter.companies.createCompany({
                        api: api,
                    }),
                },
            }),
        },
        billing: {
            subscription: config.service.billing.createSubscription({
                repository: {
                    subscription: repository.billing.subscriptions,
                },
            }),
        },
        accounts: {
            profile: config.service.accounts.createProfile({
                repository: repository.accounts,
            }),
            invitation: config.service.invitation.createInvitationService(
                repository.accounts.invitation
            ),
        },
        onboarding: {
            organization: config.service.onboarding.createOrganizationState({}),
            asset: config.service.onboarding.createAssetState({
                repository: {
                    preference: repository.platform.clientPreference,
                },
            }),
        },
        assets: {
            mapping: config.service.assets.createMapping({
                repository: {
                    connection: repository.assets.connection,
                    integration: repository.assets.integration,
                    entity: repository.assets.entity,
                    asset: repository.assets.asset,
                    definition: repository.assets.definition,
                },
            }),
            asset: config.service.assets.createAsset({
                repository: {
                    asset: repository.assets.asset,
                },
            }),
            integration: config.service.assets.createIntegration({
                repository: {
                    mapping: repository.assets.connection,
                    integration: repository.assets.integration,
                    definition: repository.assets.definition,
                },
            }),
        },
        insights: {
            opportunity: config.service.insights.createOpportunity({
                opportunity: repository.insights.opportunity,
                plugin: repository.platform.plugin,
            }),
        },
        dashboards: {
            plugin: config.service.dashboards.createPluginService({
                api: api.dashboard,
                hook: {
                    useQuery,
                    useMutation,
                },
            }),
        },
        platform: {
            workspace: config.service.platform.createWorkspaceService({
                api: api.platform,
                cacheRegistrar: registrar,
                hook: {
                    useQuery,
                    useMutation,
                },
            }),
            collaborator: config.service.platform.createCollaboratorService({
                api: api.platform,
                cacheRegistrar: registrar,
                hook: {
                    useQuery,
                    useMutation,
                },
            }),
            users: config.service.platform.createUserService({
                api: api.platform,
                cacheRegistrar: registrar,
                hook: {
                    useQuery,
                    useMutation,
                },
            }),
        },
        reports: {
            subscription: config.service.reports.createScheduledReportService({
                api: api.reportold,
                hook: {
                    useQuery,
                    useMutation,
                },
            }),
        },
        report: config.service.createReportService({
            repository: {
                plugins: repository.platform.plugin,
                opportunities: repository.insights.opportunity,
            },
            config: {
                reports: config.config.reports,
            },
            infra: {
                http: infra.http,
            },
            adapter: {
                status: config.adapter.report.createDependencyAdapter({
                    axios,
                }),
                segment: config.adapter.report.createSegmentAdapter({ axios }),
            },
        }),
        groups: config.service.groups.createGroupsService(repository.groups),
    };

    const context = {
        system: config.context.createSystem({
            deps: {
                axios,
                api,
                hook: { useAuth: hooks.useAuth },
            },
            provider: {
                createLoader: createSystemContextLoader,
            },
        }),
        organization: config.context.createOrganization({
            deps: {
                hook: { useAuth: hooks.useAuth, useOrganization: hooks.useOrganization },
                repository: {
                    role: repository.permissions.role.organization,
                    asset: repository.assets.asset,
                    feature: repository.platform.feature,
                    organization: repository.platform.organization,
                },
            },
            provider: {
                createLoader: createOrganizationContextLoader,
            },
        }),
        workspace: config.context.createWorkspace({
            // deferMountChildren: true,
            deps: {
                api,
                adapter: {
                    dashboard: adapter.dashboards,
                },
                repository: {
                    metric: {
                        collection: repository.metrics.collection,
                        definition: repository.metrics.definition,
                        dashboard: repository.metrics.dashboard,
                    },
                    platform: {
                        plugin: repository.platform.plugin,
                        connection: repository.platform.connectionV2,
                    },
                    attributes: {
                        member: repository.attributes.member,
                    },
                    peers: {
                        group: repository.peers.group,
                    },
                    feature: repository.platform.feature,
                },
                hook: {
                    ...hooks,
                },
                queries: {},
            },
            provider: {
                createLoader: createWorkspaceContextLoader,
            },
        }),
        workspaceOnboarding: config.context.createWorkspace({
            // deferMountChildren: true,
            deps: {
                api,
                adapter: {
                    dashboard: adapter.dashboards,
                },
                repository: {
                    metric: {
                        collection: repository.metrics.collection,
                        definition: repository.metrics.definition,
                        dashboard: repository.metrics.dashboard,
                    },
                    platform: {
                        plugin: repository.platform.plugin,
                        connection: repository.platform.connectionV2,
                    },
                    attributes: {
                        member: repository.attributes.member,
                    },
                    peers: {
                        group: repository.peers.group,
                    },
                    feature: repository.platform.feature,
                },
                hook: {
                    ...hooks,
                    // useWorkspace() {
                    //     const [search] = useSearchParams();
                    //     const asset = search.get('asset');
                    //     if (!asset) {
                    //         throw new Error(`asset query param not foound`);
                    //     }
                    //     return { id: asset };
                    // },
                },
                queries: {},
            },
            provider: {
                createLoader: createWorkspaceContextLoader,
            },
        }),
    };
    const Module = {
        WorkspaceSelection: config.module.createWorkspaceSelectionModule({
            deps: {
                addBrandUrl: urlConfig.addBrandHref,
                repository: {
                    organization: repository.platform.organization,
                },
                hook: {
                    useQuery: useQuery,
                    useAuth: hooks.useAuth,
                    useOrganization: hooks.useOrganization,
                },
            },
            provider: {
                createController: createWorkspaceSelectionController,
                createContainer: createWorkspaceSelectionContainer,
                createView() {
                    const Component = createWorkspaceSelectionView();
                    return (props) => (
                        <Component
                            {...props}
                            style={{
                                wrapper: {
                                    bg: GRAY[600],
                                },
                                button: {
                                    color: 'whiteAlpha.800',
                                    bg: 'none',
                                    size: 'sm',
                                },
                            }}
                        />
                    );
                },
            },
        }),
        AssetMapping: config.module.createAssetMappingModule({
            deps: {
                api: {
                    platform: api.platform,
                },
                service: {},
                UI: UI.Settings,
                hook: {
                    useMutation: useMutation,
                    useQuery: useQuery,
                    useOrganization: hooks.useOrganization,
                    useAuth: hooks.useAuth,
                },
            },
            provider: {
                createController: createMappingController,
                createView: createAssetMappingView,
            },
        }),
        Metrics: config.module.createMetricModule({
            deps: {
                createPercentileRank,
                api: {
                    platform: api.platform,
                    dashboard: api.dashboard,
                    metric: api.metric,
                },
                UI: UI.Settings,
                hook: {
                    ...hooks,
                    useQueryClient: useQueryClient,
                    useMutation: useMutation,
                    useQuery: useQuery,
                },
                repository: {
                    metric: {
                        configuration: repository.metrics.configuration,
                        definition: repository.metrics.definition,
                        collection: repository.metrics.collection,
                    },
                    plugin: repository.platform.plugin,
                    peergroup: repository.peers.group,
                    connection: repository.platform.connectionV2,
                },
            },
            provider: {
                benchmark: {
                    list: {
                        createLoader: createBenchmarkListLoader,
                        createController: createBenchmarkListController,
                        createView: createBenchmarkListView,
                    },
                },
                definition: {
                    list: {
                        createLoader: createMetricCollectionEditLoader,
                        createController: createCollectionEditController,
                        createView: createMetricCollectionEditView,
                    },
                    select: {
                        createController: createMetricSelectController,
                        createView: createMetricSelectView,
                    },
                },
            },
        }),
        Notification: config.module.createNotificationModule({
            deps: {
                UI: UI.Settings,
                hook: {
                    useAuth: hooks.useAuth,
                    useOrganization: hooks.useOrganization,
                    useQuery,
                    useMutation,
                },
                api: {
                    notification: api.notification,
                },
                service: {},
                Layout: Layout.Settings,
            },
            provider: {
                list: {
                    createController: createSubscriptionListController,
                    createView: createSubscriptionListView,
                },
            },
        }),
        Schedules: config.module.createScheduledReportsModule({
            deps: {
                UI: UI.Settings,
                Layout: Layout.Settings,
                service: {
                    workspace: service.platform.workspace,
                    collaborator: service.platform.collaborator,
                    schedule: service.reports.subscription,
                },
                hook: {
                    useAuth: hooks.useAuth,
                    useOrganization: hooks.useOrganization,
                },
            },
            provider: {
                edit: {
                    createContainer: createEditScheduleFormContainer,
                    createController: createEditScheduleFormController,
                    createView: createScheduleEditView,
                },
                list: {
                    createContainer: createScheduleListContainer,
                    createController: createScheduleListController,
                    createView: createScheduleListView,
                },
            },
        }),
        WorkspaceManagement: config.module.createWorkspaceManagementModule({
            deps: {
                UI: UI.Settings,
                service: {
                    workspace: service.platform.workspace,
                    user: service.platform.users,
                    collaborator: service.platform.collaborator,
                },
                Layout: Layout.Settings,
                hook: {
                    useAuth: hooks.useAuth,
                    useOrganization: hooks.useOrganization,
                    useUser: hooks.useUser,
                },
            },
            provider: {
                list: {
                    createController: createWorkspaceListController,
                    createContainer: createWorkspaceListContainer,
                    createView: createWorkspaceListView,
                },
                edit: {
                    createController: createEditWorkspaceFormController,
                    createContainer: createEditWorkspaceFormContainer,
                    createView: createWorkspaceEditView,
                },
            },
        }),
        UserManagement: config.module.createUserManagementModule({
            deps: {
                UI: UI.Settings,
                service: {
                    user: service.platform.users,
                },
                Layout: Layout.Settings,
                hook: {
                    useAuth: hooks.useAuth,
                    useOrganization: hooks.useOrganization,
                    useUser: hooks.useUser,
                },
            },
            provider: {
                list: {
                    createController: createUserListController,
                    createContainer: createUserListContainer,
                    createView: createUserListView,
                },
                // edit: {
                //     createController: createEditWorkspaceFormController,
                //     createContainer: createEditWorkspaceFormContainer,
                //     createView: createWorkspaceEditView,
                // },
            },
        }),
        Insight: config.module.createInsightModule({
            deps: {
                UI: UI.Application,
                strategy: {
                    visualizable: config.strategy.insight.createVisualizable({}),
                },
                api: {
                    insight: api.insight,
                },
                hook: {
                    useWorkspace: hooks.useWorkspace,
                    useQuery: useQuery,
                    useOrganization: hooks.useOrganization,
                    useAuth: hooks.useAuth,
                    useFormatter: hooks.useFormatter,
                },
            },
            provider: {
                createController: createInsightController,
                createContainer: createInsightContainer,
                createView: createInsightView,
            },
        }),
        Report: config.module.createReportModule({
            provider: {
                createContainer: createReportContainer,
                createController: createReportController,
                createView: createReportView,
                createTableController: createReportTableController,
                createQueryController: createReportQueryController,
                createLayout: createReportLayout,
                createTableContainer: createReportTableContainer,
                createTableView: createReportTableView,
            },
            deps: {
                UI: UI.Application,
                service: {
                    report: service.report,
                },
                hook: {
                    useHttp() {
                        return infra.axios;
                    },
                    useChart: config.adapter.report.useChartAdapter,
                    useRouter() {
                        return {
                            link(context, type, id) {
                                return `/u/assets/${context.workspace.id}/dashboards/${id}`;
                            },
                        };
                    },
                    useStore() {
                        const context = useStorageContext();
                        return context.report;
                    },
                    useOrganization: hooks.useOrganization,
                    useWorkspace() {
                        const context = useWorkspaceContext();
                        return context.workspace;
                    },
                    useAuth: hooks.useAuth,
                },
            },
        }),
    };

    const controller = {
        report: {
            list: config.controller.reports.createReportList({
                infra: {
                    formatter: infra.formatter,
                },
            }),
            new: config.controller.reports.createReportNew({
                controller: {
                    date: config.controller.date.createDateSelector({
                        config: {
                            options: {
                                period: PERIOD_INTERVALS,
                            },
                        },
                        infra: {
                            formatter: infra.formatter,
                            legacyFormatter: new FormatterImpl(),
                        },
                    }),
                },
            }),
            select: config.controller.reports.createReportSelect({
                controller: {},
            }),
            detail: config.controller.reports.createReportDetail({
                infra: {
                    formatter: infra.formatter,
                },
                strategy: {
                    visualizable: config.strategy.insight.createVisualizable({}),
                },
            }),
        },
        home: {
            integration: config.controller.home.createIntegration(),
            destination: config.controller.home.createDestination(),
            opportunity: config.controller.home.createOpportunity({
                hooks,
                controller: {
                    oppportunity: config.controller.insights.opportunity.createCollection(
                        {
                            infra: {
                                formatter: infra.formatter,
                            },
                            strategy: {
                                visualizable: config.strategy.insight.createVisualizable(
                                    {}
                                ),
                            },
                            service: service.insights,
                        }
                    ),
                },
            }),
            alert: config.controller.home.createAlert(
                {
                    hooks,
                },
                Module.Insight.controller
            ),
            guide: config.controller.home.createGuide(),
        },
        feedback: {
            rating: config.controller.feedback.createRating(),
        },
        date: {
            selector: config.controller.date.createDateSelector({
                config: {
                    options: {
                        period: PERIOD_INTERVALS,
                    },
                },
                infra: {
                    formatter: infra.formatter,
                    legacyFormatter: new FormatterImpl(),
                },
            }),
        },
        insights: {
            insight: Module.Insight.controller,
            opportunity: {
                collection: config.controller.insights.opportunity.createCollection({
                    infra: {
                        formatter: infra.formatter,
                    },
                    strategy: {
                        visualizable: config.strategy.insight.createVisualizable({}),
                    },
                    service: service.insights,
                }),
                item: config.controller.insights.opportunity.createItem({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
            },
        },
        peers: {
            trait: config.controller.peer.createTrait(),
            group: {
                edit: config.controller.peer.createGroupEdit(),
            },
        },
        dashboards: {
            workspaceNavigation:
                config.controller.dashboards.createWorkspaceNavigationController({
                    service: {
                        plugin: service.dashboards.plugin,
                    },
                    hook: {
                        useAuth: hooks.useAuth,
                    },
                }),
        },
        accounts: {
            profile: config.controller.accounts.createProfile({
                repository: {
                    registration: {
                        option: repository.registration.option,
                    },
                },
                service: {
                    profile: service.accounts.profile,
                    asset: service.assets.asset,
                },
            }),
        },
        platform: {
            plugin: {
                list: config.controller.platform.plugin.createList(),
                item: config.controller.platform.plugin.createItem({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
            },
        },
        integrations: {
            entity: {
                control: config.controller.integrations.entity.createControl({
                    controller: {
                        list: config.controller.integrations.entity.createList(),
                    },
                }),
                list: config.controller.integrations.entity.createList(),
            },
            integration: {
                list: config.controller.integrations.integration.createList({}),
                item: config.controller.integrations.integration.createItem({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
            },
        },
        assets: {
            asset: {
                list: config.controller.assets.createList({
                    service: service.assets,
                }),
                new: config.controller.assets.createNew({
                    infra: {
                        help: infra.help,
                    },
                    service: service.assets,
                }),
                edit: config.controller.assets.createEdit({
                    service: service.assets,
                }),
            },
            mapping: config.controller.assets.createMapping({
                service: service.assets,
            }),
            prepare: config.controller.assets.createPrepare({
                service: {
                    mapping: service.assets.mapping,
                    profile: service.accounts.profile,
                },
                repository: {
                    connection: repository.platform.connectionV2,
                    plugin: repository.platform.plugin,
                },
            }),
        },
        groups: {
            activated: config.controller.groups.group.createActivated(),
            introduction: config.controller.groups.group.createIntro({
                repository: {
                    profile: repository.accounts.profile,
                },
            }),
            redirect: config.controller.groups.group.createRedirect(),
            settings: config.controller.groups.group.createSettings({
                groups: service.groups,
                profile: repository.accounts.profile,
            }),
        },
        settings: {
            organization: config.controller.settings.createOrganization(),
            asset: config.controller.settings.createAsset(),
        },
        invitation: {
            accept: config.controller.invitation.createAcceptInvitation({
                deps: {
                    service: {
                        invitations: service.accounts.invitation,
                    },
                },
            }),
        },
    };

    const loader = {
        reports: {
            report: {
                list: config.loader.reports.createReportList({
                    infra: {
                        formatter: infra.formatter,
                    },
                    repository: {
                        report: repository.reportsold.report,
                        collaborator: repository.platform.collaborator,
                        connection: repository.platform.connectionV2,
                        asset: repository.assets.asset,
                    },
                }),
                new: config.loader.reports.createReportNew({
                    repository: {
                        report: repository.reportsold.report,
                        asset: repository.assets.asset,
                        preference: repository.platform.clientPreference,
                    },
                    controller: {
                        date: controller.date.selector,
                    },
                }),
                select: config.loader.reports.createReportSelect({
                    repository: {
                        asset: repository.assets.asset,
                        integration: repository.assets.integration,
                        mapping: repository.assets.connection,
                    },
                    controller: {},
                }),
                detail: config.loader.reports.createReportDetail({
                    repository: {
                        report: repository.reportsold.report,
                        plugin: repository.platform.plugin,
                        definition: repository.metrics.definition,
                        connection: repository.platform.connectionV2,
                        view: repository.query.view,
                        peergroup: repository.peers.group,
                        summary: repository.metrics.summary,
                        member: repository.attributes.member,
                        asset: repository.assets.asset,
                        configuration: repository.metrics.configuration,
                        insight: {
                            opportunity: repository.insights.opportunity,
                            definition: repository.insights.definition,
                        },
                    },
                }),
            },
        },
        home: {
            integration: config.loader.home.createIntegration({
                hook: hooks,
                repository: {
                    connection: repository.platform.connectionV2,
                    plugin: repository.platform.plugin,
                },
            }),
            destination: config.loader.home.createDestination({
                hook: hooks,
                repository: {
                    destination: repository.assets.destination,
                },
            }),
            opportunity: config.loader.home.createOpportunity({
                infra,
                strategy: {
                    visualizable: config.strategy.insight.createVisualizable({}),
                },
                service: {
                    opportunity: service.insights.opportunity,
                },
            }),
            alert: config.loader.home.createAlert(Module.Insight.loader),
            guide: config.loader.home.createGuide({
                items: HOME_VIDEOS,
                repository: {
                    account: {
                        profile: repository.accounts.profile,
                    },
                    asset: repository.assets.asset,
                },
            }),
        },
        groups: config.loader.groups.create({
            service: {
                assets: service.assets.asset,
                groups: service.groups,
            },
        }),
        account: {
            invitation: {},
        },
    };

    const macro = config.macro.createApplication({
        company: {
            infra: {
                options: infra.optionParser,
                toaster: infra.toaster,
            },
            repository: {
                count: repository.platform.count,
                peerset: repository.peers.peerset,
                member: repository.peers.member,
                invitation: repository.accounts.invitation,
                asset: repository.assets.asset,
                collaborator: repository.platform.collaborator,
                company: repository.companies.company,
                industry: repository.companies.industry,
            },
            controller: {
                company: config.v2.companies.company.list.createController({
                    infra: {
                        formatter: infra.formatterOld,
                    },
                    factory: config.v2.companies.company.list.createFactory(),
                }),
            },
            hooks,
            deps: {
                createConfirmationController: config.controller.common.createConfirmation,
                createFormController: config.controller.layout.createForm,
                createSearchController: config.controller.common.createSearch,
            },
        },
    });

    const Routes = {
        Report: {
            Shell: config.route.reports.createReportShell({
                UI: UI.Settings,
                Layout: Layout.Organization.Audit,
            }),
            List: config.route.reports.createReportList({
                UI: UI.Settings,
                hook: hooks,
                controller: {
                    report: controller.report.list,
                },
                loader: {
                    report: loader.reports.report.list,
                },
            }),
            Select: config.route.reports.createReportSelect({
                UI: UI.Settings,
                hook: hooks,
                controller: {
                    report: controller.report.select,
                },
                loader: {
                    report: loader.reports.report.select,
                },
                form: {
                    report: config.form.reports.createSelect(),
                },
            }),
            Detail: config.route.reports.createReportDetail({
                UI: {
                    Application: UI.Settings,
                    Visualization: UI.Visualization,
                },
                Component: {
                    // VisualizationResult: createVisualizationResultView({
                    //     UI: {
                    //         Visualization: UI.Visualization,
                    //     },
                    //     infra: {
                    //         formatter: infra.formatter,
                    //     },
                    //     repository: {
                    //         visualization: {
                    //             result: config.repository.visualizations.createResult({
                    //                 adapter: config.adapter.visualizations.createResult({
                    //                     strategy: {
                    //                         queryable:
                    //                             config.strategy.visualization.createQueryable(
                    //                                 {}
                    //                             ),
                    //                     },
                    //                 }),
                    //             }),
                    //         },
                    //     },
                    // }),
                },
                hook: hooks,
                repository: {
                    member: repository.attributes.member,
                    peergroup: repository.peers.group,
                    plugin: repository.platform.plugin,
                    metric: {
                        definition: repository.metrics.definition,
                    },
                    query: {
                        response: repository.query.response,
                    },
                    view: repository.query.view,
                },
                controller: {
                    insights: {
                        item: controller.insights.opportunity.item,
                    },
                    peer: {
                        item: config.controller.peer.createGroupItem({
                            infra: {
                                formatter: infra.formatter,
                            },
                        }),
                    },
                    visualization: {
                        result: config.controller.visualization.createResult({
                            infra: {
                                formatter: infra.formatter,
                            },
                        }),
                        scalar: config.controller.visualization.createScalar({
                            infra: {
                                formatter: infra.formatter,
                            },
                        }),
                        movement: config.controller.visualization.createMovement({
                            infra: {
                                formatter: infra.formatter,
                            },
                        }),
                        range: config.controller.visualization.createRange({
                            infra: {
                                formatter: infra.formatter,
                            },
                        }),
                    },
                    report: controller.report.detail,
                },
                strategy: {
                    queryable: config.strategy.visualization.createQueryableVisualization(
                        {}
                    ),
                    visualizable: config.strategy.insight.createVisualizable({}),
                    resultable: config.strategy.query.createResultable(),
                },
                loader: {
                    report: loader.reports.report.detail,
                },
            }),
        },
        Settings: {
            Organization: config.route.settings.createOrganization({
                config: {
                    items: [
                        {
                            scope: 'user',
                            key: 'notification',
                            path: 'notifications',
                            title: 'Notifications',
                            Component(props) {
                                const context = hooks.useUser();
                                const auth = hooks.useAuth();
                                const { View, controller } =
                                    Module.Notification.Subscription.List;
                                const viewProps = controller.useProps(
                                    {
                                        user: context.user,
                                        workspaces: context.assets,
                                        auth,
                                    },
                                    {}
                                );
                                return <View {...viewProps} />;
                            },
                        },
                        config.strategy.settings.account.createAssets({
                            UI: UI.Settings,
                            infra: {
                                toaster: infra.toaster,
                            },
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            repository: {
                                asset: repository.assets.asset,
                                organization: repository.platform.organization,
                                integration: repository.assets.integration,
                                mapping: repository.assets.connection,
                            },
                            state: {
                                asset: {
                                    list: config.state.assets.createList(),
                                },
                            },
                            form: {
                                asset: {
                                    new: config.form.assets.asset.createNew(),
                                },
                            },
                            service: {
                                asset: service.assets.asset,
                            },
                            controller: {
                                asset: {
                                    list: controller.assets.asset.list,
                                    new: controller.assets.asset.new,
                                },
                            },
                        }),
                        config.strategy.settings.account.createIntegrations({
                            UI: UI.Settings,
                            infra: {
                                toaster: infra.toaster,
                            },
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            repository: {
                                integration: repository.assets.integration,
                                definition: repository.assets.definition,
                                mappings: repository.assets.connection,
                            },
                            state: {
                                integration: {
                                    list: config.state.integrations.createList(),
                                },
                            },
                            controller: {
                                integration: {
                                    list: controller.integrations.integration.list,
                                    item: controller.integrations.integration.item,
                                },
                            },
                        }),
                        // Billing
                        config.strategy.settings.billing.createSubscription({
                            UI: UI.Settings,
                            infra: {
                                toaster: infra.toaster,
                            },
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            form: {
                                subscription: {
                                    edit: config.form.billing.subscription.createEdit(),
                                },
                            },
                            repository: {
                                subscription: repository.billing.subscriptions,
                                method: repository.billing.methods,
                                plan: repository.billing.plansV2,
                                charge: repository.billing.charges,
                            },
                            state: {
                                integration: {
                                    list: config.state.integrations.createList(),
                                },
                            },
                            service: {
                                subscription: service.billing.subscription,
                            },
                            controller: {
                                subscription: {
                                    edit: config.controller.billing.subscription.createEdit(
                                        {
                                            infra: {
                                                formatter: infra.formatterOld,
                                            },
                                            controller: {
                                                plan: {
                                                    select: config.controller.billing.plan.createSelect(
                                                        {
                                                            infra: {
                                                                formatter:
                                                                    infra.formatterOld,
                                                            },
                                                        }
                                                    ),
                                                },
                                            },
                                        }
                                    ),
                                },
                            },
                        }),
                        {
                            scope: 'organization',
                            key: ViewIds.PERMISSIONS,
                            path: 'permissions',
                            title: 'Permissions',
                            Component: Module.WorkspaceManagement.Container.List,
                        },
                        {
                            scope: 'organization',
                            key: 'scheduled_report',
                            path: 'scheduled_reports',
                            title: 'Scheduled Reports',
                            Component: Module.Schedules.Container.List,
                        },
                        {
                            scope: 'organization',
                            key: 'users',
                            path: 'user-management',
                            title: 'Team',
                            Component: Module.UserManagement.Container.List,
                        },
                        {
                            create(settingsConfig) {
                                return [
                                    {
                                        scope: 'organization',
                                        key: 'privacy',
                                        path: 'privacy',
                                        title: 'Privacy',
                                        Component: config.route.createGroupsSettingsRoute(
                                            {
                                                settings: settingsConfig,
                                                hook: {
                                                    useAuth: hooks.useAuth,
                                                    useOrganization:
                                                        hooks.useOrganization,
                                                    useQuery,
                                                },
                                                controller: controller.groups.settings,
                                                loader: loader.groups,
                                                View: createGroupsFormView({
                                                    UI: UI.Application,
                                                }),
                                                UI: UI.Application,
                                            }
                                        ),
                                    },
                                ];
                            },
                        },
                        // createUserProfileContainer({
                        //     UI: UI.settings,
                        //     hooks: {
                        //         useUser: hooks.useUser,
                        //     },
                        // }),
                    ],
                },
                deps: {
                    UI: UI.Settings,
                    Layout: Layout.Settings,
                    hooks: hooks,
                },
                controller: {
                    route: controller.settings.organization,
                    page: config.controller.layout.createPage(),
                },
            }),
            Asset: config.route.settings.createAsset({
                config: {
                    items: [
                        config.strategy.settings.asset.createProfile({
                            UI: UI.Settings,
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            repository: {
                                asset: repository.assets.asset,
                            },
                            service: {
                                asset: service.assets.asset,
                            },
                            form: {
                                asset: {
                                    edit: config.form.assets.asset.createEdit(),
                                },
                            },
                            controller: {
                                entity: controller.integrations.entity.control,
                                mapping: controller.assets.mapping,
                                asset: {
                                    list: controller.assets.asset.list,
                                    edit: controller.assets.asset.edit,
                                },
                            },
                        }),
                        // config.strategy.settings.asset.createPlugin({
                        //     UI: UI.Settings,
                        //     hooks: {
                        //         useUser: hooks.useUser,
                        //     },
                        //     infra: {
                        //         toaster: infra.toaster,
                        //     },
                        //     repository: {
                        //         asset: repository.assets.asset,
                        //         plugin: repository.platform.plugin,
                        //         connection: repository.platform.connectionV2,
                        //     },
                        //     controller: {
                        //         plugin: {
                        //             list: controller.platform.plugin.list,
                        //             item: controller.platform.plugin.item,
                        //         },
                        //     },
                        // }),
                        config.strategy.settings.asset.createMapping({
                            UI: UI.Settings,
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            infra: {
                                toaster: infra.toaster,
                            },
                            repository: {
                                asset: repository.assets.asset,
                            },
                            service: {
                                mapping: service.assets.mapping,
                            },
                            controller: {
                                entity: controller.integrations.entity.control,
                                mapping: controller.assets.mapping,
                                asset: {
                                    list: controller.assets.asset.list,
                                },
                            },
                        }),
                        // config.strategy.settings.asset.createDataSource({
                        //     UI: UI.Settings,
                        //     hooks: {
                        //         useUser: hooks.useUser,
                        //     },
                        //     infra: {
                        //         toaster: infra.toaster,
                        //     },
                        //     repository: {
                        //         integration: repository.assets.integration,
                        //         definition: repository.assets.definition,
                        //         asset: repository.assets.asset,
                        //         plugin: repository.platform.plugin,
                        //         connection: repository.datasets.connection,
                        //         entity: repository.assets.entity,
                        //         mapping: repository.assets.connection,
                        //     },
                        //     controller: {
                        //         dataset: {
                        //             source: {
                        //                 list: config.controller.datasets.source.createList(
                        //                     {
                        //                         infra: { formatter: infra.formatterOld },
                        //                     }
                        //                 ),
                        //             },
                        //         },
                        //     },
                        //     strategy: {
                        //         mapping: config.strategy.settings.asset.createMapping({
                        //             UI: UI.Settings,
                        //             hooks: {
                        //                 useUser: hooks.useUser,
                        //             },
                        //             infra: {
                        //                 toaster: infra.toaster,
                        //             },
                        //             repository: {
                        //                 asset: repository.assets.asset,
                        //             },
                        //             service: {
                        //                 mapping: service.assets.mapping,
                        //             },
                        //             controller: {
                        //                 entity: controller.integrations.entity.control,
                        //                 mapping: controller.assets.mapping,
                        //                 asset: {
                        //                     list: controller.assets.asset.list,
                        //                 },
                        //             },
                        //         }),
                        //     },
                        // }),
                        // config.strategy.settings.asset.createDatasetConnection({
                        //     UI: UI.Settings,
                        //     hooks: {
                        //         useUser: hooks.useUser,
                        //     },
                        //     infra: {
                        //         toaster: infra.toaster,
                        //     },
                        //     repository: {
                        //         metric: repository.metrics.definition,
                        //         integration: repository.assets.integration,
                        //         definition: repository.assets.definition,
                        //         asset: repository.assets.asset,
                        //         view: repository.query.view,
                        //         plugin: repository.platform.plugin,
                        //         connection: repository.datasets.connection,
                        //         entity: repository.assets.entity,
                        //         mapping: repository.assets.connection,
                        //     },
                        //     controller: {
                        //         dataset: {
                        //             connection: {
                        //                 list: config.controller.datasets.connection.createList(
                        //                     {
                        //                         infra: { formatter: infra.formatterOld },
                        //                     }
                        //                 ),
                        //             },
                        //         },
                        //     },
                        // }),
                        config.strategy.settings.asset.createImport({
                            UI: UI.Settings,
                            hooks: {
                                useUser: hooks.useUser,
                            },
                            infra: {
                                toaster: infra.toaster,
                                parser: config.infra.dataset.createParser({}),
                            },
                            form: {
                                import: createImportNewFormProvider(),
                            },
                            repository: {
                                dataset: repository.datasets.dataset,
                                plugin: repository.platform.plugin,
                                import: repository.datasets.import,
                            },
                            controller: {
                                dataset: {
                                    import: {
                                        list: config.controller.datasets.import.createList(
                                            {
                                                infra: { formatter: infra.formatterOld },
                                            }
                                        ),
                                        new: config.controller.datasets.import.createNew({
                                            infra: {
                                                formatter: infra.formatterOld,
                                                parser: config.infra.dataset.createParser(
                                                    {}
                                                ),
                                            },
                                            controller: {
                                                entry: {
                                                    list: config.controller.datasets.entry.createList(
                                                        {
                                                            infra: {
                                                                formatter:
                                                                    infra.formatter,
                                                            },
                                                        }
                                                    ),
                                                },
                                            },
                                        }),
                                    },
                                },
                            },
                        }),
                    ],
                },
                deps: {
                    UI: UI.Settings,
                    Layout: Layout.Settings,
                    hooks,
                    state: {
                        asset: {
                            list: config.state.assets.createList(),
                        },
                    },
                    controller: {
                        page: config.controller.layout.createPage(),
                        route: controller.settings.asset,
                    },
                    repository: {
                        asset: repository.assets.asset,
                    },
                },
            }),
        },
        Groups: config.route.createGroupsAnalyzeRoute({
            hook: {
                useAuth: hooks.useAuth,
                useOrganization: hooks.useOrganization,
                useQuery,
            },
            loader: loader.groups,
            controller: controller.groups,
            view: {
                Activated: config.view.groups.createActivated({
                    UI: UI.Application,
                }),
                Introduction: createGroupsIntroView({ UI: UI.Application }),
                Redirect: createGroupRedirect(),
            },
        }),
        Billing: {
            ChargeComplete: config.route.billing.charge.complete({
                repository: {
                    subscription: repository.billing.subscriptions,
                    charge: repository.billing.charges,
                },
                service: {
                    subscription: service.billing.subscription,
                },
                hooks: {
                    useOrganization: hooks.useOrganization,
                    useUser: hooks.useUser,
                    useAuth: hooks.useAuth,
                },
                UI: UI.Application,
                controller: {
                    charge: {
                        complete: config.controller.billing.charge.complete(),
                    },
                },
            }),
        },
    };

    const View = {
        Dashboards: {
            WorkspaceNavigation: config.view.dashboards.createWorkspaceNavigationView({
                UI: UI.Application,
            }),
        },
    };

    const Container = {
        Dashboards: {
            WorkspaceNavigation:
                config.container.dashboards.createWorkspaceNavigationContainer({
                    controller: controller.dashboards.workspaceNavigation,
                    Component: View.Dashboards.WorkspaceNavigation,
                }),
        },
        Attributes: {
            PropertyInput: createPropertyContainer({
                infra: {
                    formatter: infra.formatter,
                },
                provider: {
                    createController: config.controller.attributes.createPropertyInput,
                },
            }),
        },
    };

    const StorageProvider = createStorageProvider({
        store: {
            report: config.store.createReportStore(),
        },
    });

    const ReportRedirect: React.FC = () => {
        const location = useLocation();
        const user = useUserApi();
        const workspace = user.getCurrentWorkspace();
        const reportsUrl = `/u/assets/${workspace?.id}/reports/summary`;
        return <Navigate to={reportsUrl} replace={true} state={location.state} />;
    };

    const PluginRedirect: React.FC = () => {
        const location = useLocation();
        const params = useParams();
        const dashboard = useWorkspaceApi()
            .getDashboards()
            .items.find((item) => item.pluginId === params.pluginId);

        if (!dashboard) {
            const dashboardUrl = `/u/assets/${params.workspaceId}/dashboards`;
            return <Navigate to={dashboardUrl} replace={true} />;
        }
        console.info(`dashboard for plugin '${params.pluginId}' not found`);
        const dashboardUrl = `/u/assets/${params.workspaceId}/dashboards/${dashboard.id}`;
        return <Navigate to={dashboardUrl} replace={true} state={location.state} />;
    };

    const ORG_CONFIG: OrganizationRouteConfig = {
        // context: Provider.organizationRouteData,
        deps: {
            UI: UI.Application,
            Layout: Layout.Organization.Base,
            adapter: { dashboard: adapter.dashboards },
        },
        hook: {
            ...hooks,
        },
        repository: {
            asset: repository.assets.asset,
            account: {
                profile: repository.accounts.profile,
            },
        },
        layoutItems: [],
        views: {
            Context: null,
            User: (props) => (
                <UserNavigationView
                    {...props}
                    style={{
                        wrapper: {
                            bg: GRAY[600],
                        },
                        header: { spacing: 4 },
                    }}
                />
            ),
        },
        providers: {
            createLoader: createOrganizationRouteLoader,
            createController: createOrganizationRouteController,
            createView: createOrganizationRouteView,
        },
        controller: {
            useUserNavigation: createUserController({
                addTeamMemberUrl: urlConfig.addTeamMemberHref,
                referAFriendUrl: urlConfig.referAFriendHref,
                hook: {
                    useAuth: hooks.useAuth,
                },
                service: {
                    user: service.platform.users,
                },
                InviteUserForm: createUserInviteFormContainer({
                    hooks: {
                        useAuth: hooks.useAuth,
                    },
                    View: createUserInviteFormView({ UI: UI.Settings }),
                    controller: createUserInviteFormController({
                        service: {
                            user: service.platform.users,
                            workspaces: service.platform.workspace,
                        },
                        hook: {
                            useUser: hooks.useUser,
                        },
                    }),
                }),
            }),
            useVarosDataset: useVarosDatasetController,
        },
        layout: {
            wrapper: {
                gridTemplateRows: '50px 1fr',
                gridTemplateColumns: '1fr',
                rowGap: '1px',
                bg: 'black',
            },
            header: {
                px: 4,
                bg: GRAY[700],
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: '50px',
                borderBottomWidth: '1px',
                borderColor: 'black',
                borderStyle: 'solid',
            },
            scroll: {
                __css: SCROLLBAR_STYLE,
            },
            left: {
                spacing: 4,
            },
            navigation: {
                color: 'whiteAlpha.800',
                fontWeight: 'medium',
                spacing: 4,
                fontSize: 'sm',
            },
            button: {
                color: 'whiteAlpha.800',
                bg: 'none',
                size: 'sm',
            },
        },
    };

    const OnboardingRoute = {
        User: route.createOnboardingUserRoute({
            Layout: Layout.Onboarding,
            hook: hooks,
            service: service.onboarding,
            items: [
                config.strategy.onboarding.createProfile({
                    hooks: {
                        ...hooks,
                    },
                    router: {
                        getSuccessPath() {
                            return `/u/onboarding?step=integration`;
                        },
                    },
                    controller: {
                        profile: controller.accounts.profile,
                    },
                    loader: {
                        profile: config.loader.accounts.createProfile({
                            repository: {
                                // registration: {
                                //     option: repository.registration.option,
                                // },
                            },
                            service: {
                                profile: service.accounts.profile,
                                asset: service.assets.asset,
                            },
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createProfile,
                    },
                }),
            ],
        }),
        Organization: route.createOnboardingOrganizationRoute({
            Layout: Layout.Onboarding,
            hook: hooks,
            service: service.onboarding,
            items: [
                config.strategy.onboarding.createIntegration({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    router: {
                        getSuccessPath(profile, asset) {
                            if (profile?.organizationType === 'ad_agency') {
                                return `/u/onboarding?step=asset`;
                            }
                            if (asset) {
                                return `/u/assets/${asset.id}/onboarding?step=mapping`;
                            }
                            return `/u/onboarding?step=asset`;
                        },
                    },
                    service: {
                        asset: service.assets.asset,
                    },
                    controller: {
                        profile: controller.accounts.profile,
                    },
                    loader: {
                        profile: config.loader.accounts.createProfile({
                            repository: {
                                // registration: {
                                //     option: repository.registration.option,
                                // },
                            },
                            service: {
                                profile: service.accounts.profile,
                                asset: service.assets.asset,
                            },
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createIntegration,
                    },
                }),
                config.strategy.onboarding.createAsset({
                    hooks,
                    router: {
                        getSuccessPath(asset) {
                            return `/u/assets/${asset.id}/onboarding`;
                        },
                    },
                    form: {
                        new: config.form.assets.asset.createNew(),
                    },
                    controller: {
                        new: controller.assets.asset.new,
                    },
                    repository: {
                        asset: repository.assets.asset,
                        organization: repository.platform.organization,
                    },
                    provider: {
                        createController: config.controller.onboarding.createAsset,
                    },
                }),
            ],
        }),
        Asset: route.createOnboardingAssetRoute({
            Layout: Layout.Onboarding,
            hook: hooks,
            service: service.onboarding,
            items: [
                config.strategy.onboarding.createMappingV2({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        mapping: controller.assets.mapping,
                        entity: {
                            control: controller.integrations.entity.control,
                        },
                    },
                    loader: {
                        mapping: config.loader.assets.createMapping({
                            service: service.assets,
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createMapping,
                    },
                }),
                config.strategy.onboarding.createPeerGroup({
                    UI: UI.Application,
                    infra,
                    Context: {
                        Workspace: context.workspaceOnboarding.Provider,
                    },
                    Component: {
                        Input: Input,
                    },
                    Container: {
                        PropertyInput: Container.Attributes.PropertyInput,
                    },
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        preview: config.controller.peer.createPreview(infra.formatter),
                        trait: config.controller.peer.createTrait(),
                        example: config.controller.peer.createExample(infra.formatter),
                        group: controller.peers.group.edit,
                    },
                    service: {
                        integration: service.assets.integration,
                    },
                    repository: {
                        peergroups: repository.peers.group,
                        connection: repository.platform.connectionV2,
                        plugin: config.repository.platform.createPlugin(
                            config.adapter.platform.createPlugin({ api })
                        ),
                        trait: config.repository.peers.createTrait(
                            config.adapter.peers.createTrait({})
                        ),
                        competitive: config.repository.peers.createPeerStatus(
                            config.adapter.peers.createStatus({
                                api: api.platform,
                            })
                        ),
                        member: config.repository.attributes.createMember(
                            config.adapter.attributes.createMember({
                                axios,
                            })
                        ),
                    },
                    provider: {
                        plugin: {
                            createController: config.controller.onboarding.createPlugin,
                        },
                        root: {
                            createLoader: createOnboardingPeerGroupLoader,
                            createController:
                                config.controller.onboarding.createPeerGroups,
                            createView: createOnboardingPeerGroupView,
                        },
                    },
                }),
                config.strategy.onboarding.createCompetitiveSet({
                    UI: UI.Application,
                    infra,
                    macro,
                    hooks,
                    service: {
                        peerset: service.peers.peerset,
                    },
                    repository: {
                        peerset: repository.peers.peerset,
                        invitation: repository.accounts.invitation,
                    },
                    provider: {
                        createController:
                            config.controller.onboarding.createCompetitiveSet,
                    },
                }),
                config.strategy.onboarding.createMetric({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        metric: config.controller.metrics.collection.createNew(),
                    },
                    form: {
                        metric: config.form.metrics.collection.createNew(),
                    },
                    loader: {
                        metric: config.loader.metrics.collection.createNew({
                            repository: {
                                collection: repository.metrics.collection,
                                connection: repository.platform.connectionV2,
                            },
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createMetric,
                    },
                }),
                config.strategy.onboarding.createPrepare({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        prepare: controller.assets.prepare,
                    },
                    loader: {
                        prepare: config.loader.assets.createPrepare({
                            service: {
                                mapping: service.assets.mapping,
                                profile: service.accounts.profile,
                            },
                            repository: {
                                connection: repository.platform.connectionV2,
                                plugin: repository.platform.plugin,
                            },
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createPrepare,
                    },
                }),
                config.strategy.onboarding.createReport({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        report: controller.report.new,
                        prepare: controller.assets.prepare,
                    },
                    loader: {
                        report: loader.reports.report.new,
                        prepare: config.loader.assets.createPrepare({
                            service: {
                                mapping: service.assets.mapping,
                                profile: service.accounts.profile,
                            },
                            repository: {
                                connection: repository.platform.connectionV2,
                                plugin: repository.platform.plugin,
                            },
                        }),
                    },
                    form: {
                        report: config.form.reports.createNew(),
                    },
                    provider: {
                        createController: config.controller.onboarding.createReport,
                    },
                }),
                config.strategy.onboarding.createReceipt({
                    hooks: {
                        ...hooks,
                        useLocalStorage: hooks.onboarding.useLocalStorage,
                    },
                    controller: {
                        report: {
                            poll: config.controller.reports.createReportPoll(),
                        },
                    },
                    loader: {
                        report: {
                            poll: config.loader.reports.createReportPoll({
                                repository: {
                                    report: repository.reportsold.report,
                                    preference: repository.platform.clientPreference,
                                },
                            }),
                        },
                        prepare: config.loader.assets.createPrepare({
                            service: {
                                mapping: service.assets.mapping,
                                profile: service.accounts.profile,
                            },
                            repository: {
                                connection: repository.platform.connectionV2,
                                plugin: repository.platform.plugin,
                            },
                        }),
                    },
                    provider: {
                        createController: config.controller.onboarding.createReceipt,
                    },
                }),
            ],
        }),
    };
    const { Route: MetricAnalysisRoute } = route.createMetricAnalysisRoute({
        deps: {
            UI: UI.Application,
            Container: {
                PropertyInput: Container.Attributes.PropertyInput,
            },
            View: {
                Input: Input,
            },
            infra: {
                formatter: infra.formatter,
                intl: createVisualizationIntl({}),
            },
            repository: {
                competitive: repository.peers.status,
                member: config.repository.attributes.createMember(
                    config.adapter.attributes.createMember({
                        axios,
                    })
                ),
                competitiveStatus: config.repository.query.createCompetitiveStatus(
                    config.adapter.query.createCompetitiveStatus({
                        axios,
                    })
                ),
            },
            form: {
                date: config.form.query.createDate(),
                breakdown: config.form.query.createBreakdown(),
                filter: config.form.query.createFilter(),
            },
            controller: {
                group: controller.peers.group.edit,
                trait: controller.peers.trait,
            },
            strategy: {
                queryable: config.strategy.visualization.createQueryableVisualization({}),
            },
            state: config.adapter.analysis.createQueryState(),
            content: {
                executor: config.adapter.analysis.createQueryExecutor({
                    api,
                }),
            },
            Layout: Layout.Analysis,
            hook: {
                ...hooks,
                useQuery,
            },
        },
        providers: {
            visualization: {
                createController: config.controller.analysis.createVisualization,
            },
            filter: {
                createController: config.controller.analysis.createFilter,
            },
            root: {
                createLoader: createAnalysisRouteLoader,
                createController: createAnalysisRouteController,
                createView: createAnalysisRouteView,
                createComponents() {
                    const line = createLineStrategy({
                        intl: createVisualizationIntl({}),
                    });

                    const barchart = createBarStrategy({
                        intl: createVisualizationIntl({}),
                    });

                    const Select = Module.Metrics.Definition.Select.createView({
                        UI: UI.Application,
                    });

                    const analysisController = {
                        segment: { item: config.controller.segment.createItem() },
                        cohort: createCohortController({
                            UI: UI.Application,
                            view: { createButtonSelect },
                            hook: { useQuery },
                        }),
                    };

                    const View = {
                        Segment: {
                            Item: config.view.segment.createPropertyItem(
                                analysisController.segment.item
                            ),
                        },
                        Cohort: createCohortView({
                            UI: UI.Application,
                            view: { createButtonSelect },
                            hook: { useQuery },
                        }),
                    };

                    return {
                        Select(props) {
                            const viewProps =
                                Module.Metrics.Definition.Select.controller.useProps(
                                    props
                                );
                            return <Select {...viewProps} />;
                        },
                        Distribution(props) {
                            const visualizationProps = useMemo(
                                () =>
                                    barchart(
                                        {
                                            formatter: new FormatterImpl(),
                                        },
                                        {
                                            getEvents(series, key) {
                                                return [];
                                            },
                                        },
                                        props.request,
                                        props.response,
                                        {
                                            kind: 'bar',
                                        },
                                        {
                                            schema: {
                                                properties: props.view.columns.reduce(
                                                    (acc, column) => ({
                                                        ...acc,
                                                        [column.key]: column,
                                                    }),
                                                    {}
                                                ),
                                            },
                                        }
                                    ),
                                [props.request, props.response, props.visualization]
                            );

                            const ref = useRef<HTMLDivElement>(null);

                            const [width, setWidth] = useState(0);
                            const [height, setHeight] = useState(0);

                            const size = useWindowSize();

                            useLayoutEffect(() => {
                                if (
                                    ref.current &&
                                    (ref.current?.offsetWidth !== width ||
                                        ref.current?.offsetHeight !== height)
                                ) {
                                    setWidth(ref.current?.offsetWidth ?? 0);
                                    setHeight(ref.current?.offsetHeight ?? 0);
                                }
                            }, [
                                ref.current?.offsetWidth,
                                ref.current?.offsetHeight,
                                width,
                                height,
                                size,
                            ]);

                            return (
                                <Box
                                    ref={ref}
                                    w="full"
                                    minH="24rem"
                                    h="33vh"
                                    position="relative"
                                >
                                    <Box
                                        position="absolute"
                                        top={0}
                                        bottom={0}
                                        left={0}
                                        right={0}
                                    >
                                        <BarVisualization
                                            width={width}
                                            height={height}
                                            {...visualizationProps}
                                        />
                                    </Box>
                                </Box>
                            );
                        },
                        Chart(props) {
                            const visualizationProps = useMemo(
                                () =>
                                    line(
                                        {
                                            formatter: new FormatterImpl(),
                                        },
                                        {
                                            getEvents(series, key) {
                                                return [];
                                            },
                                        },
                                        props.request,
                                        props.response,
                                        props.visualization,
                                        { schema: { properties: {} } }
                                    ),
                                [props.request, props.response, props.visualization]
                            );

                            const ref = useRef<HTMLDivElement>(null);

                            const [width, setWidth] = useState(0);
                            const [height, setHeight] = useState(0);

                            const size = useWindowSize();

                            useLayoutEffect(() => {
                                if (
                                    ref.current &&
                                    (ref.current?.offsetWidth !== width ||
                                        ref.current?.offsetHeight !== height)
                                ) {
                                    setWidth(ref.current?.offsetWidth ?? 0);
                                    setHeight(ref.current?.offsetHeight ?? 0);
                                }
                            }, [
                                ref.current?.offsetWidth,
                                ref.current?.offsetHeight,
                                width,
                                height,
                                size,
                            ]);

                            return (
                                <Box
                                    ref={ref}
                                    w="full"
                                    minH="24rem"
                                    h="33vh"
                                    position="relative"
                                >
                                    <Box
                                        position="absolute"
                                        top={0}
                                        bottom={0}
                                        left={0}
                                        right={0}
                                    >
                                        <LineVisualization
                                            width={width}
                                            height={height}
                                            {...visualizationProps}
                                        />
                                    </Box>
                                </Box>
                            );
                        },
                        Table() {
                            return <div>i am a table</div>;
                        },
                        CompetitiveSet(props) {
                            if (props.isLoading) {
                                return <Spinner size="sm" />;
                            }
                            return (
                                <CompetitiveSetView
                                    style={{
                                        label: {
                                            color: 'whiteAlpha.900',
                                            fontSize: 'sm',
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                        },
                                    }}
                                    transparency={props.transparency}
                                />
                            );
                        },
                        Ranking(props) {
                            const disclosure = useDisclosure();

                            const viewProps = analysisController.cohort.useProps(
                                props.context,
                                {
                                    value: props.value,
                                    onChange: props.onChange,
                                    onSubmit(values) {
                                        disclosure.onClose();
                                    },
                                    formKey: disclosure.isOpen ? 'open' : 'closed',
                                    modes: props.modes,
                                    options: props.options,
                                }
                            );

                            const current =
                                viewProps.initialValues?.config.fixed.comparison;
                            const mapped = viewProps.fields.comparison.options.find(
                                (item) => item.value === current
                            );

                            const dynamicLabel =
                                viewProps.fields.mode.options.find(
                                    (item) => item.value === 'dynamic'
                                )?.label ?? 'N/A';

                            const fixedLabel = mapped?.label ?? current ?? 'None';

                            const label =
                                viewProps.initialValues?.mode === 'fixed'
                                    ? fixedLabel
                                    : dynamicLabel;

                            return (
                                <DashboardCohortView
                                    label={label}
                                    disclosure={disclosure}
                                    onApply={viewProps.onSaveClick}
                                >
                                    <View.Cohort {...viewProps} />
                                </DashboardCohortView>
                            );
                        },
                        Segment(props) {
                            throw new Error('not impl');
                        },
                        Filter(props) {
                            return (
                                <FilterListView
                                    style={{
                                        wrapper: { h: '100%', spacing: 3 },
                                        label: {
                                            color: 'whiteAlpha.700',
                                            fontSize: 'sm',
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase',
                                        },
                                    }}
                                    filters={props.filters}
                                    renderFilter={(filter, index) => {
                                        const itemProps = props.getItemProps(
                                            filter,
                                            index
                                        );
                                        return (
                                            <FilterItemView
                                                {...itemProps}
                                                style={{
                                                    trigger: {
                                                        px: 3,
                                                        py: 1.5,
                                                        bg: GRAY[600],
                                                        color: VAROS_GREEN['200'],
                                                        borderColor: VAROS_GREEN['200'],
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        // boxSizing: 'border-box',
                                                        // outline: 'none',
                                                        _active: {
                                                            color: 'white',
                                                            bg: VAROS_GREEN['200'],
                                                            outline: 'none',
                                                            // border: 'none',
                                                        },
                                                        _disabled: {
                                                            bg: GRAY[600],
                                                            border: 'none',
                                                            color: 'whilteAlpha.900',
                                                            opacity: 0.66,
                                                        },
                                                    },
                                                    content: {
                                                        bg: GRAY[600],
                                                        p: 2,
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                />
                            );
                        },
                        Date(props) {
                            return (
                                <FilterDateView
                                    enabled={true}
                                    value={props.value}
                                    onChange={props.onChange}
                                />
                            );
                        },
                    };
                },
            },
        },
    });

    const InsightRoute = route.createInsightRoute({
        config: {},
        deps: {
            state: config.state.createInsight(),
            hook: hooks,
            controller: {
                opportunity: controller.insights.opportunity.collection,
                date: controller.date.selector,
            },
            loader: {
                opportunity: config.loader.insights.createOpportunity({
                    infra,
                    service: {
                        opportunity: service.insights.opportunity,
                    },
                    strategy: {
                        visualizable: config.strategy.insight.createVisualizable({}),
                    },
                }),
            },
        },
    });

    const InsightRouteV2 = route.createInsightRouteV2({
        config: {},
        deps: {
            UI: {
                Application: UI.Application,
                Visualization: UI.Visualization,
            },
            state: config.state.createInsight(),
            hook: hooks,
            repository: {
                insight: {
                    opportunity: repository.insights.opportunity,
                },
                member: repository.attributes.member,
                peergroup: repository.peers.group,
                plugin: repository.platform.plugin,
                metric: {
                    definition: repository.metrics.definition,
                },
                query: {
                    response: repository.query.response,
                },
                view: repository.query.view,
            },
            strategy: {
                queryable: config.strategy.visualization.createQueryableVisualization({}),
                visualizable: config.strategy.insight.createVisualizable({}),
                resultable: config.strategy.query.createResultable(),
            },
            controller: {
                visualization: {
                    result: config.controller.visualization.createResult({
                        infra: {
                            formatter: infra.formatter,
                        },
                    }),
                },
                opportunity: {
                    collection: controller.insights.opportunity.collection,
                    item: controller.insights.opportunity.item,
                },
                date: controller.date.selector,
            },
            loader: {
                opportunity: config.loader.insights.createOpportunity({
                    infra,
                    service: {
                        opportunity: service.insights.opportunity,
                    },
                    strategy: {
                        visualizable: config.strategy.insight.createVisualizable({}),
                    },
                }),
            },
        },
    });

    const { Route: HomeRoute, WorkspaceRedirect: HomeWorkspaceRedirect } =
        route.createHomeRoute({
            config: {
                options: {
                    period: PERIOD_INTERVALS,
                    comparison: COMPARISON_INTERVALS,
                },
                controls: [],
                views: [
                    config.strategy.home.createMetricItem({
                        deps: {
                            api,
                            repository: {
                                collection: repository.metrics.collection,
                            },
                            adapter: {
                                configurations:
                                    config.adapter.metric.createConfigurationProvider({
                                        hooks,
                                        axios,
                                        api,
                                        repository: {
                                            member: config.repository.attributes.createMember(
                                                config.adapter.attributes.createMember({
                                                    axios,
                                                })
                                            ),
                                        },
                                    }),
                                // collections:
                                //     config.adapter.metric.createCollectionProvider({
                                //         hooks,
                                //         axios,
                                //         api,
                                //     }),
                                competitive: config.adapter.createCompetitiveSetAdapter({
                                    hooks,
                                    axios,
                                    api,
                                }),
                                // connections: {
                                //     useAdapter() {
                                //         return config.adapter.platform.createConnection({
                                //             axios,
                                //         });
                                //     },
                                // },
                            },
                            hooks: {
                                ...hooks,
                                useQueryBuilder: hooks.useBuilder,
                                useCalculations:
                                    config.adapter.metric.createCalculationAdapterHook({
                                        hooks,
                                        axios,
                                        api,
                                    }),
                            },
                        },
                        createView({ UI }) {
                            return {
                                benchmark: {
                                    loader: Module.Metrics.Benchmark.List.loader,
                                    controller: Module.Metrics.Benchmark.List.controller,
                                    Component: Module.Metrics.Benchmark.List.createView({
                                        UI,
                                    }),
                                },
                                collection: {
                                    loader: config.loader.metrics.collection.createEdit({
                                        repository: {
                                            collection: repository.metrics.collection,
                                        },
                                    }),
                                    controller: Module.Metrics.Definition.List.controller,
                                    Component: Module.Metrics.Definition.List.createView({
                                        UI,
                                    }),
                                },
                            };
                        },
                    }),
                ],
            },
            deps: {
                Layout: Layout.Home,
                adapter: {
                    useControl: config.adapter.control.createControlWorkpaceHook(),
                },
                loader: {
                    integration: loader.home.integration,
                    opportunity: loader.home.opportunity,
                    alert: loader.home.alert,
                    destination: loader.home.destination,
                    guide: loader.home.guide,
                    expertCall: config.loader.home.createExpertCall(),
                },
                controller: {
                    date: controller.date.selector,
                    integration: controller.home.integration,
                    opportunity: controller.home.opportunity,
                    alert: controller.home.alert,
                    destination: controller.home.destination,
                    guide: controller.home.guide,
                    expertCall: config.controller.home.createExpertCall(),
                },
                hook: {
                    ...hooks,
                    useQuery,
                    useLocalStorage: hooks.home.useLocalStorage,
                },
            },
            providers: {
                root: {
                    createLoader: createHomeRouteLoader,
                    createController: createHomeRouteController,
                    createView: createHomeRouteView,
                },
                opportunity: {
                    createView: config.view.home.createOpportunity,
                },
                alert: {
                    createView: config.view.home.createAlert,
                },
                createPageController: config.v2.common.page.createController,
                createAlertListController: config.v2.common.alert.createController,
            },
        });

    const OrganizationDefaultRoute = createOrganizationRoute(ORG_CONFIG);

    const OrganizationWorkspaceRoute = createOrganizationWorkspaceRoute({
        ...ORG_CONFIG,
        views: {
            ...ORG_CONFIG.views,
            Context: Module.WorkspaceSelection.Container,
        },
    });

    const WorkspaceRoute = route.createWorkspaceRoute({
        deps: {
            layout: {
                wrapper: {
                    gridTemplateRows: '50px 1fr',
                },
                navigation: {
                    color: 'whiteAlpha.800',
                    bg: GRAY[900],
                    borderBottomWidth: '1px',
                    borderColor: GRAY[600],
                    borderStyle: 'solid',
                },
            },
            container: {
                Navigation: Container.Dashboards.WorkspaceNavigation,
            },
            hook: {
                useRedirects(items) {
                    const { auth } = useStore();
                    const navigate = useNavigate();

                    useEffect(() => {
                        for (const redirect of items) {
                            const url = redirect.apply(auth);
                            if (url) {
                                console.info(`redirect ${redirect.id} -> ${url}`);
                                return navigate(url, { replace: true });
                            }
                        }
                    }, [auth, items]);
                },
            },
        },
        config: {
            items: [
                config.container.workspaces.createWorkspaceInsightContainer({
                    hooks,
                    Component: Module.Insight.createView({
                        UI: UI.Application,
                    }),
                    controller: Module.Insight.controller,
                    loader: Module.Insight.loader,
                }),
            ],
            redirects: [],
        },
        providers: {
            createController: config.controller.platform.createWorkspace,
        },
    });

    const DashboardRoute = route.createDashboardRoute({
        ...createDashboardConfig(UI.Application),
        UI: {
            Application: UI.Application,
        },
        api: {
            UI: {},
            useContext() {
                const organization = hooks.useOrganization();
                const workspace = hooks.useWorkspace();
                const auth = hooks.useAuth();
                const context = useDashboardContext();
                return {
                    organization,
                    workspace,
                    auth,
                    dashboard: {
                        id: context.dashboard.id,
                    },
                };
            },
        },
        Layout: { UI: Layout.Dashboard, controller: createDashboardLayoutController() },
        actions: [],
        hook: hooks,
        repository: {
            asset: repository.assets.asset,
            peerset: repository.peers.peerset,
            healthcheck: repository.peers.healthcheck,
        },
        controls: [
            createDashboardCohortControl({
                ...config.module.createCohortModule({
                    deps: {
                        view: { createButtonSelect },
                        UI: UI.Application,
                        hook: { useQuery },
                    },
                    provider: {
                        createController: createCohortController,
                        createView: createCohortView,
                    },
                }),
                hiddenForDashboards: [
                    DashboardSlugs.MEDIA_MIX,
                    DashboardSlugs.GA4_ECOMMERCE,
                    DashboardSlugs.GA4_REVENUE_BREAKDOWN,
                    DashboardSlugs.GA4_TRAFFIC,
                    // DashboardSlugs.FACEBOOK_BREAKDOWN,
                ],
                id: ViewIds.COHORTS,
                hook: {
                    useStorage() {
                        return useStorageContext();
                    },
                    useAdapter(store) {
                        const api = { control: useControlApi() };
                        return {
                            async put(context, state) {
                                // return store.setCohort(context.dashboard, state);
                                return api.control.setCohortValue(state);
                            },
                            get(context) {
                                return api.control.getCohortValue();
                                // return store.getCohort(context.dashboard);
                            },
                        };
                    },
                },
            }),
        ],
        settings: [],
    });

    const InstallRoute = install.create(install.config);
    const IntegrationRoute = integration.create(integration.config);

    const CustomProvider: React.FC = (props) =>
        config.provider.custom.reduce(
            (acc, Component) => <Component>{acc}</Component>,
            <>{props.children}</>
        );

    const InvitationRoute = {
        Accept: config.route.createInvitiationAcceptRoute({
            controller: controller.invitation.accept,
            View: config.view.invitations.createAcceptView(),
            loader: config.loader.invitation.create({
                deps: {
                    repository: {
                        invitations: repository.accounts.invitation,
                    },
                },
            }),
            hook: {
                useQueryParams(schema) {
                    const [raw] = useSearchParams();
                    const mapped = [...raw.entries()].reduce(
                        (acc, [key, value]) => ({ ...acc, [key]: value }),
                        {} as Record<string, string>
                    );
                    const parsed = schema.safeParse(mapped);
                    if (!parsed.success) {
                        throw new Error(
                            `invalid query params. error detail ${parsed.error.toString()}`
                        );
                    }
                    return parsed.data;
                },
            },
        }),
    };
    const UserOrOrgOnboardingRoute: React.FC = () => {
        const [params] = useSearchParams();
        const step = params.get('step');
        if (step === 'profile' || !step) {
            // Hack to fix the dependency tree
            return (
                <LightMode>
                    <AuthSessionProvider>
                        <OnboardingRoute.User>
                            <Outlet />
                        </OnboardingRoute.User>
                    </AuthSessionProvider>
                </LightMode>
            );
        }
        return (
            <AuthSessionProvider>
                <LegacyUserProvider>
                    <LegacyOrganizationProvider>
                        <StorageProvider>
                            <FeatureProvider>
                                <CustomProvider>
                                    <context.system.Provider>
                                        <context.organization.Provider>
                                            <OnboardingRoute.Organization>
                                                <Outlet />
                                            </OnboardingRoute.Organization>
                                        </context.organization.Provider>
                                    </context.system.Provider>
                                </CustomProvider>
                            </FeatureProvider>
                        </StorageProvider>
                    </LegacyOrganizationProvider>
                </LegacyUserProvider>
            </AuthSessionProvider>
        );
    };

    const PlanSelectRoute = config.route.billing.plan.createSelect({
        UI: UI.Application,
        hook: {
            ...hooks,
        },
        state: {
            paywall: config.state.createPaywall(config.store.createPaywall()),
        },
        loader: createPlanModalLoader({
            repository: {
                feature: repository.platform.feature,
                subscription: repository.billing.subscriptions,
                plan: repository.billing.plansV2,
            },
        }),
        controller: config.controller.modal.billing.createPlan({
            getIntercom: infra.getIntercom,
            controller: {
                modal: config.controller.layout.createModal(),
                plan: {
                    select: config.controller.billing.plan.createSelect({
                        infra: {
                            formatter: infra.formatterOld,
                        },
                    }),
                },
            },
        }),
    });

    // const [
    //     {
    //         children: [{ Component: ImportSettingStrategy }],
    //     },
    // ] = config.strategy.settings.asset
    //     .createImport({
    //         UI: UI.Settings,
    //         hooks: {
    //             useUser: hooks.useUser,
    //         },
    //         infra: {
    //             toaster: infra.toaster,
    //         },
    //         form: {
    //             import: createImportNewFormProvider(),
    //         },
    //         repository: {
    //             view: repository.query.view,
    //             integration: repository.assets.integration,
    //             definition: repository.assets.definition,
    //             asset: repository.assets.asset,
    //             plugin: repository.platform.plugin,
    //             connection: repository.datasets.connection,
    //             import: repository.datasets.import,
    //             entity: repository.assets.entity,
    //             mapping: repository.assets.connection,
    //         },
    //         controller: {
    //             dataset: {
    //                 import: {
    //                     list: config.controller.datasets.import.createList({
    //                         infra: { formatter: infra.formatterOld },
    //                     }),
    //                     new: config.controller.datasets.import.createNew({
    //                         infra: { formatter: infra.formatterOld },
    //                         controller: {
    //                             entry: {
    //                                 list: config.controller.datasets.entry.createList({
    //                                     infra: {
    //                                         formatter: infra.formatter,
    //                                     },
    //                                 }),
    //                             },
    //                         },
    //                     }),
    //                 },
    //             },
    //         },
    //     })
    //     .create({
    //         UI: UI.Application,
    //         Layout: Layout.Settings.Page,
    //         api: {},
    //     });

    const ImportSettingStrategy: React.FC = React.memo(() => (
        <div>
            <div>route inside component</div>
            <input
                id="adasdaasda"
                type="file"
                onInput={console.log}
                onInputCapture={console.log}
                onChange={console.log}
                onChangeCapture={console.log}
            />
        </div>
    ));

    class ImportSettingStrategyClass extends React.Component {
        render() {
            return (
                <div>
                    <div>route inside class </div>
                    <input
                        id="adasdaasda"
                        type="file"
                        onInput={console.log}
                        onInputCapture={console.log}
                        onChange={console.log}
                        onChangeCapture={console.log}
                    />
                </div>
            );
        }
    }

    const networkRouter = config.router.createNetwork({
        mount: 'tactical-calls',
        hook: hooks,
        infra: {
            toaster: infra.toaster,
            formatter: infra.formatter,
            oldFormatter: infra.formatterOld,
        },
        UI: {
            Application: UI.Application,
        },
        Layout: {
            Domain: config.layout.createDomainLayout({
                UI: UI.Application,
            }),
            Page: config.layout.createPageLayout({
                UI: UI.Application,
            }),
        },
        controller: {
            modal: config.controller.layout.createModal(),
            page: config.controller.layout.createPage(),
            form: config.controller.layout.createForm(),
            peer: config.controller.peer.createFilterList({
                infra: {
                    formatter: infra.formatter,
                },
                controller: {
                    filter: config.controller.common.createFilterList(),
                    preview: config.controller.peer.createPreview(infra.formatter),
                },
            }),
            preview: config.controller.peer.createPreview(infra.formatter),
        },
        repository: {
            metric: repository.metrics.definition,
            plugin: repository.platform.plugin,
            connection: repository.platform.connectionV2,
            member: repository.attributes.member,
            status: repository.peers.status,
            peer: repository.peers.group,
        },
        provider: {
            createOnboardingController: config.controller.common.createOnboarding,
            createLayoutController: config.controller.layout.createDomain,
        },
    });

    const registrationRouter = config.router.createRegistration({
        mount: 'i',
        UI: {
            Application: config.theme.createOnboardingApplication(UI.Application),
            Form: UI.Onboarding.Form,
        },
        infra: {
            toaster: infra.toaster,
            options: infra.optionParser,
        },
        service: {
            invitation: config.service.invitation.createInvitationService(
                repository.accounts.invitation
            ),
        },
        repository: {
            invitation: repository.accounts.invitation,
        },
        provider: {
            createPageController: config.v2.common.page.createController,
            createFormController: config.controller.layout.createForm,
            createVideoController: config.v2.common.video.createController,
            createAlertListController: config.v2.common.alert.createController,
        },
    });

    const reportingRouter = config.router.createReporting({
        mount: 'reporting',
        Layout: {
            Domain: config.layout.createDomainLayout({
                UI: UI.Application,
            }),
            Page: config.layout.createPageLayout({
                UI: UI.Application,
            }),
        },
        UI: {
            Application: UI.Application,
            Input: UI.Input,
            Visualization: UI.Visualization,
            Date: UI.Date,
        },
        hook: hooks,
        infra: {
            optionParser: infra.optionParser,
            toaster: infra.toaster,
        },
        strategy: {
            schema: config.strategy.forms.createSchema(),
            queryable: config.strategy.visualization.createQueryableVisualization({}),
            result: config.strategy.query.createResultable(),
        },
        repository: {
            // invitation: repository.accounts.invitation,
            // member: repository.groups.member,
            // invite: repository.groups.invite,
            // view: repository.query.view,
            // metric: repository.metrics.definition,
            invitation: repository.reports.invitation,
            count: repository.platform.count,
            submission: repository.forms.submission,
            formdata: repository.forms.data,
            dataset: repository.datasets.dataset,
            group: repository.groups.group,
            member: repository.attributes.member,
            asset: repository.assets.asset,
            collaborator: repository.platform.collaborator,
            response: repository.query.response,
            plugin: repository.platform.plugin,
            report: repository.reports.report,
            view: repository.query.view,
        },
        controller: {
            help: config.controller.platform.createHelp({
                infra: {
                    help: infra.help,
                },
            }),
            image: config.controller.common.createImageList(),
            peer: config.controller.peer.createFilterList({
                infra: {
                    formatter: infra.formatter,
                },
                controller: {
                    filter: config.controller.common.createFilterList(),
                    preview: config.controller.peer.createPreview(infra.formatter),
                },
            }),
            result: config.controller.visualization.createResult({
                infra: {
                    formatter: infra.formatter,
                },
            }),
            date: config.controller.date.createDateSelector({
                infra: {
                    formatter: infra.formatter,
                    legacyFormatter: new FormatterImpl(),
                },
            }),
            page: config.controller.layout.createPage(),
            layout: {
                modal: config.controller.layout.createModal(),
                page: config.controller.layout.createPage(),
            },
            common: {
                form: config.controller.layout.createForm(),
            },
            invite: {
                form: config.controller.accounts.createInviteForm(),
            },
            groups: {
                group: {
                    list: config.controller.groups.group.createList({
                        infra: {
                            formatter: infra.formatterOld,
                        },
                    }),
                    item: config.controller.groups.group.createItem({
                        infra: {
                            formatter: infra.formatterOld,
                        },
                    }),
                },
                invite: {
                    list: config.controller.groups.invite.createList(),
                },
                member: {
                    list: config.controller.groups.member.createList(),
                },
            },
            form: {
                field: config.controller.forms.createFieldList(),
                submission: config.controller.forms.createSubmission(),
            },
            response: {
                list: config.controller.studies.response.createList({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
                item: config.controller.studies.response.createItem({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
            },
            submissions: {
                list: config.controller.studies.submission.createList({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
                item: config.controller.studies.submission.createItem({
                    infra: {
                        formatter: infra.formatter,
                    },
                }),
            },
            studies: {
                list: config.controller.studies.study.createList(),
                filter: config.controller.studies.study.createFilter(),
            },
        },
        provider: {
            createOnboardingController: config.controller.common.createOnboarding,
            createLayoutController: config.controller.layout.createDomain,
            createOptionController: config.controller.common.createOptionList,
            createPageController: config.v2.common.page.createController,
            createAlertListController: config.v2.common.alert.createController,
        },
    });

    const peerRouterRoot = config.router.createPeer({
        mount: 'peers',
        macro,
        route: {
            Layout: {
                Domain: config.layout.createDomainLayout({
                    UI: UI.Application,
                }),
                Page: config.layout.createPageLayout({
                    UI: UI.Application,
                }),
            },
            UI: {
                Application: UI.Application,
                Input: UI.Input,
            },
        },
        UI: {
            Application: UI.Application,
        },
        hook: hooks,
        infra: {
            options: infra.optionParser,
            toaster: infra.toaster,
        },
        repository: {
            count: repository.platform.count,
            peerset: repository.peers.peerset,
            member: repository.peers.member,
            invitation: repository.accounts.invitation,
            asset: repository.assets.asset,
            collaborator: repository.platform.collaborator,
            company: repository.companies.company,
            industry: repository.companies.industry,
            connection: repository.platform.connectionV2,
            plugin: repository.platform.plugin,
            healthcheck: repository.peers.healthcheck,
        },
        service: {
            peerset: service.peers.peerset,
        },
        factory: {
            company: config.v2.companies.company.list.createFactory(),
        },
        controller: {
            // eligibility: {
            //     list: config.v2.peers.eligibility.createController(),
            // },
            group: {
                list: config.controller.groups.group.createList({
                    infra: {
                        formatter: infra.formatterOld,
                    },
                }),
                item: config.controller.groups.group.createItem({
                    infra: {
                        formatter: infra.formatterOld,
                    },
                }),
            },
            member: {
                list: config.controller.groups.member.createList(),
            },
            company: {
                list: config.v2.companies.company.list.createController({
                    infra: {
                        formatter: infra.formatterOld,
                    },
                    factory: config.v2.companies.company.list.createFactory(),
                }),
            },
        },
        provider: {
            common: {
                createPeerDatasetEligibilityController:
                    config.v2.peers.eligibility.createController,
                createConfirmationController: config.controller.common.createConfirmation,
                createActionItemController: config.controller.common.createActionItem,
                createFormController: config.controller.layout.createForm,
                createListBoxController: config.controller.common.createListBox,
                createSearchController: config.controller.common.createSearch,
                createFilterListController: config.controller.common.createFilterList,
                createModalController: config.controller.layout.createModal,
                createPageController: config.controller.layout.createPage,
                createOnboardingController: config.controller.common.createOnboarding,
                createLayoutController: config.controller.layout.createDomain,
                createEditOverlay: config.macro.common.createEditOverlay,
            },
        },
    });

    return {
        repository,
        hook: hooks,
        context,
        controller,
        infra,
        service,
        api,
        adapter,
        root: (
            <Route>
                {/* <Route
                    path="r"
                    element={
                        <AuthSessionProvider>
                            <LegacyUserProvider>
                                <LegacyOrganizationProvider>
                                    <CustomProvider>
                                        <Context.System.Provider>
                                            <Context.Organization.Provider>
                                                <Outlet />
                                            </Context.Organization.Provider>
                                        </Context.System.Provider>
                                    </CustomProvider>
                                </LegacyOrganizationProvider>
                            </LegacyUserProvider>
                        </AuthSessionProvider>
                    }
                >
                    <Route
                        element={
                            <Routes.Report.Shell>
                                <Outlet />
                            </Routes.Report.Shell>
                        }
                    >
                        <Route
                            index={true}
                            element={<Navigate replace={true} to="reports" />}
                        />
                        <Route path="reports">
                            <Route index={true} element={<Routes.Report.List />} />
                            <Route path="new" element={<Routes.Report.Select />} />
                            <Route path=":reportId" element={<Routes.Report.Detail />} />
                        </Route>
                    </Route>
                </Route> */}
                {registrationRouter}
                <Route path="u">
                    {/* <Route
                        path="internal-test"
                        element={
                            <div>
                                <input
                                    id="adasda"
                                    type="file"
                                    onInput={console.log}
                                    onInputCapture={console.log}
                                    onChange={console.log}
                                    onChangeCapture={console.log}
                                />
                            </div>
                        }
                    ></Route> */}
                    <Route
                        path="onboarding"
                        element={
                            <UserOrOrgOnboardingRoute>
                                <Outlet />
                            </UserOrOrgOnboardingRoute>
                        }
                    />
                    <Route
                        element={
                            <React.Suspense fallback={<CardSpinner />}>
                                <AuthSessionProvider>
                                    <LegacyUserProvider>
                                        <LegacyOrganizationProvider>
                                            <StorageProvider>
                                                <FeatureProvider>
                                                    <CustomProvider>
                                                        <context.system.Provider>
                                                            <context.organization.Provider>
                                                                <>
                                                                    <PlanSelectRoute />
                                                                    <Outlet />
                                                                </>
                                                            </context.organization.Provider>
                                                        </context.system.Provider>
                                                    </CustomProvider>
                                                </FeatureProvider>
                                            </StorageProvider>
                                        </LegacyOrganizationProvider>
                                    </LegacyUserProvider>
                                </AuthSessionProvider>
                            </React.Suspense>
                        }
                    >
                        <Route
                            index={true}
                            element={
                                <OrganizationDefaultRoute>
                                    <Redirect.Root />
                                </OrganizationDefaultRoute>
                            }
                        />
                        <Route
                            path="settings"
                            element={
                                <OrganizationDefaultRoute>
                                    <Routes.Settings.Organization.Route>
                                        <Outlet />
                                    </Routes.Settings.Organization.Route>
                                </OrganizationDefaultRoute>
                            }
                        >
                            <Route
                                index={true}
                                element={<Routes.Settings.Organization.Redirect />}
                            />
                            {Routes.Settings.Organization.items.map(
                                ({ Component, children, ...item }) => (
                                    <Route key={item.key} path={item.path}>
                                        <Route index={true} element={<Component />} />
                                        {children?.map(({ Component, ...child }) => (
                                            <Route
                                                key={child.key}
                                                path={child.path}
                                                element={<Component />}
                                            />
                                        ))}
                                    </Route>
                                )
                            )}
                        </Route>
                        <Route
                            path="integrations"
                            element={
                                <OrganizationDefaultRoute>
                                    <IntegrationRoute>
                                        <Outlet />
                                    </IntegrationRoute>
                                </OrganizationDefaultRoute>
                            }
                        />

                        <Route path="home">
                            <Route index={true} element={<HomeWorkspaceRedirect />} />
                        </Route>
                        <Route
                            path="research"
                            element={
                                <OrganizationDefaultRoute>
                                    <>
                                        <link
                                            rel="stylesheet"
                                            href="https://static.hex.site/embed/embedStyles.css"
                                        />
                                        <div
                                            style={{ width: '100%' }}
                                            className="hex-embed"
                                        >
                                            <iframe
                                                width="100%"
                                                height="800px"
                                                frameBorder="0"
                                                src="https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/5a21a733-a0e0-4d25-ac23-0caa76abbd2f/latest?embedded=true"
                                            ></iframe>
                                            <a
                                                href="https://hex.tech/?embed"
                                                target="_blank"
                                            >
                                                <img
                                                    src="https://static.hex.site/embed/hex-logo-embed.png"
                                                    alt="Hex - a modern data workspace for collaborative notebooks, data apps, dashboards, and reports."
                                                />
                                            </a>
                                        </div>
                                    </>
                                </OrganizationDefaultRoute>
                            }
                        ></Route>
                        <Route
                            path="billing"
                            element={
                                <OrganizationDefaultRoute>
                                    <Outlet />
                                </OrganizationDefaultRoute>
                            }
                        >
                            <Route
                                path="charge-complete"
                                element={<Routes.Billing.ChargeComplete />}
                            />
                        </Route>
                        <Route
                            path="private-groups"
                            element={
                                <OrganizationDefaultRoute>
                                    <Outlet />
                                </OrganizationDefaultRoute>
                            }
                        >
                            <Route
                                path="setup"
                                element={
                                    <React.Fragment>
                                        <Routes.Groups.Redirect fromRoute="introduction" />
                                        <Routes.Groups.Introduction />
                                    </React.Fragment>
                                }
                            />
                            <Route
                                path=":groupId"
                                element={
                                    <React.Fragment>
                                        <Routes.Groups.Redirect fromRoute="activated" />
                                        <Routes.Groups.Activated />
                                    </React.Fragment>
                                }
                            />
                            <Route index={true} element={<Routes.Groups.Redirect />} />
                        </Route>
                        <Route
                            path="reports"
                            element={
                                <OrganizationDefaultRoute>
                                    <Outlet />
                                </OrganizationDefaultRoute>
                            }
                        >
                            <Route
                                element={
                                    <Box
                                        w="full"
                                        mx="auto"
                                        px={{ base: 6, md: 0 }}
                                        maxW={{
                                            sm: '100vw',
                                            md: '90vw',
                                            lg: '90vw',
                                            xl: '90vw',
                                            '2xl': '80vw',
                                            '3xl': '75vw',
                                        }}
                                    >
                                        <Outlet />
                                    </Box>
                                }
                            >
                                <Route index={true} element={<Routes.Report.List />} />
                                <Route path="new" element={<Routes.Report.Select />} />
                                <Route
                                    path=":reportId"
                                    element={<Routes.Report.Detail />}
                                />
                            </Route>
                        </Route>
                        <Route path="assets">
                            <Route
                                path=":workspaceId"
                                element={
                                    <WorkspaceProvider>
                                        {/* V2 workspace provider */}
                                        <context.workspace.Provider>
                                            <Outlet />
                                        </context.workspace.Provider>
                                    </WorkspaceProvider>
                                }
                            >
                                <Route
                                    path="onboarding"
                                    element={<OnboardingRoute.Asset />}
                                />
                            </Route>
                        </Route>
                        <Route path="assets">
                            <Route index={true} element={<Redirect.Root />} />
                            <Route
                                path="pending"
                                element={
                                    <OrganizationDefaultRoute>
                                        <NoBrandConfigured />
                                    </OrganizationDefaultRoute>
                                }
                            />
                            <Route
                                path=":workspaceId"
                                element={
                                    <WorkspaceProvider>
                                        {/* V2 workspace provider */}
                                        <context.workspace.Provider>
                                            <OrganizationWorkspaceRoute>
                                                <Outlet />
                                            </OrganizationWorkspaceRoute>
                                        </context.workspace.Provider>
                                    </WorkspaceProvider>
                                }
                            >
                                <Route
                                    index={true}
                                    element={<DashboardDefaultRedirect />}
                                />
                                <Route path="plugins">
                                    <Route path=":pluginId">
                                        <Route
                                            index={true}
                                            element={<PluginRedirect />}
                                        />
                                    </Route>
                                </Route>
                                <Route path="home" element={<HomeRoute />} />
                                {reportingRouter}
                                {networkRouter}
                                {peerRouterRoot}
                                <Route path="reports">
                                    <Route
                                        path="summary"
                                        element={
                                            <Module.Report.Container definitionId="summary" />
                                        }
                                    />
                                </Route>
                                <Route
                                    path="settings"
                                    element={
                                        <Routes.Settings.Asset.Route>
                                            <Outlet />
                                        </Routes.Settings.Asset.Route>
                                    }
                                >
                                    <Route
                                        index={true}
                                        element={<Routes.Settings.Asset.Redirect />}
                                    />
                                    {Routes.Settings.Asset.items.map(
                                        ({ Component, children, ...item }) => (
                                            <Route key={item.key} path={item.path}>
                                                <Route
                                                    index={true}
                                                    element={<Component />}
                                                />
                                                {children?.map(
                                                    ({
                                                        Component: ChildComponent,
                                                        ...child
                                                    }) => (
                                                        <Route
                                                            key={child.key}
                                                            path={child.path}
                                                            element={<ChildComponent />}
                                                        />
                                                    )
                                                )}
                                            </Route>
                                        )
                                    )}
                                </Route>
                                <Route path="metrics" element={<Outlet />}>
                                    <Route
                                        path="analyze"
                                        element={<MetricAnalysisRoute />}
                                    />
                                </Route>
                                {/* <Route
                                    path="opportunities"
                                    element={
                                        <InsightRoute>
                                            <Outlet />
                                        </InsightRoute>
                                    }
                                /> */}
                                <Route
                                    path="opportunities"
                                    element={
                                        <InsightRouteV2>
                                            <Outlet />
                                        </InsightRouteV2>
                                    }
                                />
                                <Route
                                    path="dashboards"
                                    element={
                                        <WorkspaceRoute>
                                            <Outlet />
                                        </WorkspaceRoute>
                                    }
                                >
                                    <Route
                                        index={true}
                                        element={<DashboardDefaultRedirect />}
                                    />
                                    <Route>
                                        <Route
                                            path=":dashboardId"
                                            element={
                                                <DashboardErrorBoundary paramName="dashboardId">
                                                    <DashboardProvider>
                                                        <RecommendationProvider>
                                                            <Outlet />
                                                        </RecommendationProvider>
                                                    </DashboardProvider>
                                                </DashboardErrorBoundary>
                                            }
                                        >
                                            <Route
                                                path="setup"
                                                element={
                                                    <React.Fragment>
                                                        <DashboardSetupCompleteRedirect />
                                                        <InstallRoute>
                                                            <Outlet />
                                                        </InstallRoute>
                                                    </React.Fragment>
                                                }
                                            />
                                            <Route
                                                index={true}
                                                element={
                                                    <ExportProvider>
                                                        <DashboardRoute>
                                                            <Outlet />
                                                        </DashboardRoute>
                                                    </ExportProvider>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                {/* <Route path="i">
                    <Route path="invitations" element={<InvitationRoute.Accept />} />
                </Route> */}
            </Route>
        ),
    };
}

export function NoBrandConfigured(): React.ReactElement {
    return (
        <Container
            h="100vh"
            flexDir="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={4}>
                <Image src={Logo} width={300} />
                <Heading textAlign="center" fontSize="xx-large" fontWeight="bold">
                    We're setting up your account. the account will be available shortly
                </Heading>
                <Text>
                    Email <a href="mailto:yarden@varos.io">yarden@varos.io</a> if you need
                    anything in the meantime.
                </Text>
            </VStack>
        </Container>
    );
}
