import React from 'react';
import { RegistrationViewConfig } from '../../base';
import { Heading, HStack, Icon, Image, Text, VStack } from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AcceptFinalizeViewProps } from './acceptFinalizeProps';

export function createRegistrationAcceptFinalizeView(
    config: RegistrationViewConfig
): React.FC<AcceptFinalizeViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <VStack align="start" borderRadius="lg" p={12} spacing={6} minW="50vw">
                <VStack align="start" spacing={3}>
                    <Heading color="black">{props.page.header.title}</Heading>
                    {props.page.header.description && (
                        <Text fontSize="lg" fontWeight="medium" color="blackAlpha.700">
                            {props.page.header.description}
                        </Text>
                    )}
                </VStack>
                {props.children}
                <HStack w="full" spacing={3}>
                    <Button
                        {...props.button}
                        colorScheme="green"
                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                    >
                        Connect data
                    </Button>
                </HStack>
            </VStack>
        );
    };
}
