import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Center,
    Checkbox,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    StackDivider,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';

import { Icons } from '../../../../../config';
import { GroupInviteItemViewProps } from '../../../../view/groups';
import { GroupItemViewProps } from '../../../../view';
import { PeerGroupListRouteViewProps } from './peerGroupListProps';
import { HiDotsVertical } from 'react-icons/hi';
import { PeerViewConfig } from '../../base';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { AiOutlinePlus } from 'react-icons/ai';

export function createPeerGroupListView(
    config: PeerViewConfig
): React.FC<PeerGroupListRouteViewProps> {
    const {
        UI: {
            Application: { Link, Tooltip },
        },
    } = config;

    const MembershipItem: React.FC<GroupItemViewProps> = (props) => {
        return (
            <Link style={{ width: '100%' }} {...props.links.detail}>
                <HStack
                    w="full"
                    p={6}
                    justify="space-between"
                    spacing={12}
                    cursor="pointer"
                    _hover={{ bg: 'whiteAlpha.100' }}
                >
                    <HStack w="full" spacing={4}>
                        <Box flexShrink={0} p={2.5} bg="whiteAlpha.300" borderRadius="sm">
                            <Icon
                                w={5}
                                h={5}
                                as={Icons.Peers.CompetitiveSets}
                                color="whiteAlpha.700"
                            />
                        </Box>
                        <VStack align="start" w="full" spacing={0}>
                            <HStack>
                                <Text fontWeight="semibold">{props.label}</Text>
                            </HStack>
                            <HStack
                                w="full"
                                color="whiteAlpha.700"
                                divider={<Box border="none">&#x2022;</Box>}
                            >
                                {props.count.member && (
                                    <Text>{props.count.member.label}</Text>
                                )}
                                {props.count.invitations && (
                                    <Text>{props.count.invitations.label}</Text>
                                )}
                            </HStack>
                        </VStack>
                    </HStack>
                    {props.status && (
                        <HStack justify="end" cursor="default">
                            <Tooltip
                                {...props.status.getTooltipProps()}
                                p={3}
                                placement="top"
                                hasArrow={true}
                                bg="#1D1C21"
                            >
                                <Tag size="lg" {...props.status.getTagProps()} />
                            </Tooltip>
                        </HStack>
                    )}
                </HStack>
            </Link>
        );
    };

    return (props) => {
        if (props.list.items.length === 0) {
            return (
                <Center w="full" h="full" pt="25vh">
                    <VStack align="start" justify="center" maxW="24rem" spacing={4}>
                        <Box bg="whiteAlpha.300" p={2.5} borderRadius="sm">
                            <Icon w={6} h={6} as={Icons.Peers.CompetitiveSets}></Icon>
                        </Box>
                        <VStack align="start" spacing={1}>
                            <Heading fontSize="lg" fontWeight="semibold">
                                You don't have any groups
                            </Heading>
                            <Text color="whiteAlpha.700">
                                Create even more accurate comparisons by benchmarking
                                against specific companies
                            </Text>
                        </VStack>
                        <HStack>
                            <Link to="new">
                                <Button size="sm" colorScheme="blue">
                                    Search companies
                                </Button>
                            </Link>
                        </HStack>
                    </VStack>
                </Center>
            );
        }
        return (
            <Grid templateColumns="1fr" rowGap={6} w="full">
                <GridItem>
                    <VStack
                        w="full"
                        align="start"
                        spacing={6}
                        divider={
                            <StackDivider borderWidth={1} borderColor="whiteAlpha.300" />
                        }
                    >
                        <HStack
                            w="full"
                            justify="space-between"
                            spacing={12}
                            align="start"
                        >
                            <VStack align="start" w="full" spacing={1}>
                                <HStack
                                    fontWeight="semibold"
                                    spacing={2}
                                    divider={
                                        <Icon
                                            boxSize="1.20rem"
                                            color="whiteAlpha.600"
                                            as={ChevronRightIcon}
                                            border="none"
                                        />
                                    }
                                >
                                    <Link to="..">
                                        <Text
                                            color="blue.300"
                                            _hover={{ color: 'blue.400' }}
                                        >
                                            Peers
                                        </Text>
                                    </Link>
                                </HStack>
                                <VStack align="start" w="full" spacing={3}>
                                    <Heading fontSize="2xl">Competitive Sets</Heading>
                                </VStack>
                            </VStack>
                            <HStack>
                                <Link {...props.links.new}>
                                    <Button
                                        leftIcon={<Icon as={AiOutlinePlus} />}
                                        size="sm"
                                        colorScheme="blue"
                                    >
                                        New competitive set
                                    </Button>
                                </Link>
                            </HStack>
                        </HStack>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack
                        w="full"
                        align="start"
                        fontWeight="medium"
                        spacing={0}
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderStyle="solid"
                        borderRadius="lg"
                    >
                        <HStack
                            px={6}
                            py={4}
                            w="full"
                            position="sticky"
                            top={0}
                            bg="black"
                            borderBottomWidth={2}
                            borderStyle="solid"
                            borderColor="whiteAlpha.300"
                            zIndex={5}
                            borderTopRadius="lg"
                        >
                            <HStack
                                w="full"
                                justify="space-between"
                                color="whiteAlpha.600"
                            >
                                <Text fontWeight="medium" whiteSpace="nowrap">
                                    {props.list.items.length} competitive sets found
                                </Text>
                            </HStack>
                        </HStack>
                        <VStack
                            h="full"
                            w="full"
                            spacing={0}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            {props.list.items.map((item) => {
                                const itemProps = props.list.getItemProps(item);
                                return (
                                    <MembershipItem key={itemProps.id} {...itemProps} />
                                );
                            })}
                        </VStack>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
