import { PluginSlugs } from '../../../../config';
import {
    AccountInvitationDto,
    CountDto,
    DashboardApi,
    ListResponse,
    PlatformApi,
    ViewDto,
} from '../../../api';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { MockEnhancerConfig } from '../mockConfig';

export const INVITATIONS = [
    {
        id: 'inv_adasdadda',
        token: 'tok_asdadsdasdasdads',
        data: {
            kind: 'external',
            status: 'ready',
            first_name: 'John',
            last_name: 'Doe',
            email: 'johndoe@example.com',
            organization_name: 'Google',
            inviter_organization_id: 1,
            inviter_user_id: 33,
            sent_count: 1,
            source: {
                kind: 'group',
                group_ids: ['grp_asdasdsdasd'],
            },
            company_id: null,
            invitee_user_id: null,
            last_sent: new Date(),
        },
        created_at: new Date(),
    },
] satisfies Array<AccountInvitationDto & { token: string }>;

export function createMockInvitationEnhancer(
    init: MockEnhancerConfig
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function createPlatformApi(api: PlatformApi): PlatformApi {
            return {
                ...api,
                invitation: {
                    ...api.invitation,
                    async verify(query) {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        return INVITATIONS[0];
                        // const found = INVITATIONS.find(
                        //     (candidate) =>
                        //         candidate.id === query.invitationId &&
                        //         candidate.token &&
                        //         query.token
                        // );
                        // if (!found) {
                        //     throw new Error('not found');
                        // }
                        // return found;
                    },
                    async accept(accept) {
                        console.log('accepting invitation', accept);
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        return {
                            access_token: 'asdasd',
                            refresh_token: 'adaasdasdsdds',
                        };
                    },
                },
            };
        }
        return create({
            ...config,
            api: {
                ...config.api,
                createPlatformApi(...args) {
                    const api = config.api.createPlatformApi(...args);
                    return createPlatformApi(api);
                },
            },
        });
    };
}
