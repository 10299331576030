import { AnyAccountInvitationEntity } from '../../../domain/accounts';
import { AccountInvitationDto } from '../../../api';
import {
    AccountInvitationAdapter,
    AccountInvitationBulkCreateItemProps,
    AccountInvitationBulkDeleteItemProps,
    AnyAccountInvitationBulkItemProps,
} from '../../../app/accounts';
import { AccountInvitationImplConfig } from './invitationImplConfig';
import { assert } from '../../../util/assert';
import { assertNever } from '../../../util';

export function createAccountInvitationImpl(
    config: AccountInvitationImplConfig
): AccountInvitationAdapter {
    const {
        api: { platform: api },
    } = config;

    function toEntity(dto: AccountInvitationDto): AnyAccountInvitationEntity {
        const source: AnyAccountInvitationEntity['source'] =
            dto.data.source?.kind === 'group'
                ? { kind: 'group', id: dto.data.source.group_ids[0] }
                : null;

        assert(!source || (source && source.id), 'invalid group');
        if (dto.data.kind === 'company') {
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                company: {
                    id: dto.data.company_id,
                },
                status: dto.data.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
            };
        }
        if (dto.data.kind === 'external') {
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                email: dto.data.email,
                organization_name: dto.data.organization_name ?? null,
                url: dto.data.url ?? null,
                first_name: dto.data.first_name ?? null,
                last_name: dto.data.last_name ?? null,
                company: dto.data.company_id
                    ? {
                          id: dto.data.company_id,
                      }
                    : null,
                status: dto.data.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
                inviteeUserId: dto.data.invitee_user_id,
            };
        }
        if (dto.data.kind === 'internal') {
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                email: dto.data.email,
                first_name: dto.data.first_name ?? null,
                last_name: dto.data.last_name ?? null,
                role: dto.data.role,
                inviteeUserId: dto.data.invitee_user_id,
                status: dto.data.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
            };
        }
        return {
            kind: dto.data.kind,
            id: dto.id,
            source,
            status: dto.data.status,
            createdAt: dto.created_at,
            updatedAt: dto.updated_at ?? dto.created_at,
        };
    }

    return {
        async find(context, query) {
            const [first, ...rest] = query.source;
            if (first && rest.length > 0) {
                // multi lookup on source
                const response = await Promise.all(
                    [first, ...rest].map((item) => {
                        assert(
                            item.kind === 'group',
                            'only group-based invitations are implemented'
                        );
                        return api.invitation.list(context, {
                            source: item.id,
                        });
                    })
                );
                const merged = response.flatMap((item) => item.data);
                return {
                    total: merged.length,
                    limit: query.limit ?? 10,
                    items: merged.map(toEntity),
                };
            }
            if (first) {
                // lookup by single source
                assert(
                    first.kind === 'group',
                    'only group-based invitations are implemented'
                );
                const response = await api.invitation.list(context, {
                    source: first.id,
                });
                return {
                    total: response.data.length,
                    limit: query.limit ?? 10,
                    items: response.data.map(toEntity),
                };
            }
            // NOTE there is currently an issue with fetching non-groups based invitations
            return {
                total: 0,
                limit: query.limit ?? 10,
                items: [],
            };
            // const response = await api.invitation.list(context, {});
            // return {
            //     total: response.data.length,
            //     limit: query.limit ?? 10,
            //     items: response.data.map(toEntity),
            // };
        },
        async findOne(query) {
            const verified = await api.invitation.verify(query);
            return toEntity(verified);
        },
        async bulk(context, props) {
            async function create(
                props: AccountInvitationBulkCreateItemProps
            ): Promise<AnyAccountInvitationEntity & { deleted?: boolean }> {
                assert(
                    props.data.kind === 'company',
                    'only company invites are supported'
                );
                const response = await api.invitation.create(context, {
                    data: {
                        kind: 'company',
                        company_id: props.data.id,
                        source:
                            props.data.source.kind === 'group'
                                ? {
                                      kind: 'group',
                                      group_ids: [props.data.source.id],
                                  }
                                : undefined,
                    },
                });
                const entity = toEntity(response);
                return entity;
            }
            async function remove(
                props: AccountInvitationBulkDeleteItemProps
            ): Promise<AnyAccountInvitationEntity & { deleted?: boolean }> {
                const response = await api.invitation.delete(context, props.entity.id);
                return { ...props.entity, deleted: true };
            }
            return Promise.all(
                props.items.map((item) => {
                    if (item.kind === 'create') {
                        return create(item);
                    }
                    if (item.kind === 'delete') {
                        return remove(item);
                    }
                    assertNever(item);
                })
            );
        },

        accept(payload) {
            return api.invitation.accept(payload);
        },
    };
}
