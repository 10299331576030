import React, { useEffect } from 'react';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import {
    createPageControllerV2,
    PageController,
    PageControllerV2,
} from '../../../view/page';
import { AnyPageEvent } from './trackingPageEvent';

export function createPageTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyPageEvent>();
            return tracker;
        }

        function enhanceController(controller: PageController): PageController {
            return {
                ...controller,
                useProps(...args) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    useEffect(() => {
                        tracker.track('page_viewed', {
                            page_id: viewProps.id,
                        });
                    }, []);
                    return viewProps;
                },
            };
        }

        function enhanceControllerV2(
            createController: typeof createPageControllerV2
        ): typeof createPageControllerV2 {
            return (config) => {
                const controller = createController(config);
                return {
                    ...controller,
                    useProps(item, props, ...args) {
                        const tracker = useTracker();
                        const viewProps = controller.useProps(item, props, ...args);
                        useEffect(() => {
                            tracker.track('page_viewed', {
                                page_id: config.id,
                                page_aggregate_id:
                                    item.entity?.id.toString() ?? undefined,
                                page_aggregate_object: item.entity?.object ?? undefined,
                            });
                        }, []);
                        return viewProps;
                    },
                };
            };
        }

        const instance = create({
            ...init,
            v2: {
                ...init.v2,
                common: {
                    ...init.v2.common,
                    page: {
                        createController: enhanceControllerV2(
                            init.v2.common.page.createController
                        ),
                    },
                },
            },
            controller: {
                ...init.controller,
                layout: {
                    ...init.controller.layout,
                    createPage(...args) {
                        const controller = init.controller.layout.createPage(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });
        return instance;
    };
}
