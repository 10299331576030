import React from 'react';
import { FormContainerController } from './formContainerInterface';
import {
    FormContainerContainerProps,
    FormContainerViewProps,
} from './formContainerProps';

export function createFormContainerContainer(
    controller: FormContainerController,
    View: React.FC<FormContainerViewProps>
): React.FC<FormContainerContainerProps> {
    return (containerProps) => {
        const props = controller.useProps({});
        return <View {...props}>{containerProps.children}</View>;
    };
}
