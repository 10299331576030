import { ReportAccessStatus, ReportDependencyStatus } from '../../../../app';
import { StudyItemStatusProps } from './studyStatusProps';

export function buildAccessStatusProps(status: ReportAccessStatus) {
    const mapping: {
        [TKey in ReportAccessStatus]: StudyItemStatusProps<ReportAccessStatus>;
    } = {
        available: {
            kind: 'available',
            color: 'blue',
            label: 'Available',
            description: null,
        },
        missing_dependencies: {
            kind: 'missing_dependencies',
            color: 'orange',
            label: 'Missing dependencies',
            description: `You must meet the required dependencies before accessing this report`,
        },
        not_enough_data: {
            kind: 'not_enough_data',
            color: 'orange',
            label: 'Collecting data',
            description: `Report data is still being collected from participants. It will be available once there are at least 10 answers.`,
        },
    };
    return mapping[status];
}

export function buildDependencyStatusProps(status: ReportDependencyStatus) {
    const mapping: {
        [TKey in ReportDependencyStatus]: StudyItemStatusProps<ReportDependencyStatus>;
    } = {
        fulfilled: {
            kind: 'fulfilled',
            color: 'green',
            label: 'Fulfilled',
            description: null,
        },
        unfulfilled: {
            kind: 'unfulfilled',
            color: 'red',
            label: 'Unfulfilled',
            description: null,
        },
    };
    return mapping[status];
}
