import React from 'react';
import { FormFieldItemController } from './formFieldItemInterface';
import {
    FormFieldItemContainerProps,
    FormFieldItemViewProps,
} from './formFieldItemProps';

export function createFormFieldItemContainer(
    controller: FormFieldItemController,
    View: React.FC<FormFieldItemViewProps>
): React.FC<FormFieldItemContainerProps> {
    return (containerProps) => {
        const props = controller.useProps(containerProps);
        return <View {...props}>{containerProps.children}</View>;
    };
}
