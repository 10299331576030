import React from 'react';
import { WithAs } from '../../../../base';
import { PeerRouteConfig } from '../../base';
import {
    CompanyListBoxMatchController,
    CompanyListBoxMatchLoader,
    CompanyListBoxMatchStateProvider,
} from './peerCompanyMatchInterface';
import {
    CompanyListboxMatchContainerProps,
    CompanyListboxMatchViewProps,
} from './peerCompanyMatchProps';

export function createCompanyListBoxMatchContainer(
    config: PeerRouteConfig & {
        state: CompanyListBoxMatchStateProvider;
    },
    loader: CompanyListBoxMatchLoader,
    controller: CompanyListBoxMatchController
): React.FC<WithAs<CompanyListboxMatchContainerProps, CompanyListboxMatchViewProps>> {
    const {
        state: { useState },
        context: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = useContext();
        const state = useState(context);
        const data = loader.useLoad(context, {
            query: {
                term: state.value.search,
                limit: 25,
            },
        });
        const viewProps = controller.useProps(context, data, {
            ...containerProps,
            search: {
                value: state.value.search,
                onChange(value) {
                    state.onChange({ ...state.value, search: value });
                },
            },
            filter: {
                value: {},
                onChange(value) {},
            },
        });
        return <View {...viewProps} />;
    };
}
