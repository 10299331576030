import { ApplicationLinks } from '../.././../../config';
import { PeerControllerConfig } from '../../base';
import { PeerMemberEditController } from './peerMemberEditInterface';
import { PeerMemberEditViewProps } from './peerMemberEditProps';

export function createPeerMemberEditController(
    config: PeerControllerConfig
): PeerMemberEditController {
    const {
        controller: {
            common: {
                page: { useProps: usePageProps },
            },
        },
        provider: { createFormController },
        macro: {
            company: {
                select: {
                    controller: { useProps: useCompanyListBoxProps },
                },
            },
        },
    } = config;

    const { useProps: useFormProps } = createFormController({
        submitOnEnter: false,
    });

    return {
        useProps(context, item, props): PeerMemberEditViewProps {
            const page = usePageProps(context, props.page);
            const form = useFormProps(props.company.form);
            const listbox = useCompanyListBoxProps(context, item.company, props.company);
            return {
                company: {
                    page,
                    form,
                    listbox,
                    anchor: {
                        documentation: ApplicationLinks.Documentation.CompetitiveSet,
                    },
                },
            };
        },
    };
}
