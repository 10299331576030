import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../base';
import { RegistrationAcceptContextProvider } from '../context';
import { buildServicePayload } from './acceptOrganizationFactory';
import { RegistrationAcceptOrganizationController } from './acceptOrganizationInterface';
import { AcceptOrganizationViewProps } from './acceptOrganizationProps';

export function createRegistrationAcceptOrganizationContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptOrganizationController,
    View: React.FC<AcceptOrganizationViewProps>
): React.FC {
    const {
        infra: { toaster },
        service: { invitation: invitationService },
        accept: { useContext },
    } = config;
    return (_containerProps) => {
        const toast = toaster.useToast();
        const mutation = invitationService.useAccept();
        const context = useContext();
        const props = controller.useProps({
            stepper: context.stepper,
            form: {
                action: {
                    label: 'Continue',
                },
                id: 'invite_accept_organization_step',
                form: context.form.organization,
                async onSubmit(values) {
                    const payload = buildServicePayload({
                        step: context.stepper.selected,
                        token: context.token,
                        invitation: context.invitation,
                        profile: context.form.profile.getValues(),
                        organization: values,
                    });
                    const response = await mutation.mutateAsync(payload);
                    context.stepper.onChange(context.stepper.selected + 1);
                },
                onSubmitError(error) {
                    toast({
                        kind: 'error',
                        description: `Form submission failed`,
                        durationMs: 5000,
                    });
                },
            },
            layout: {
                stepper: context.stepper,
                navigate: useNavigate(),
            },
        });
        return <View {...props} />;
    };
}
