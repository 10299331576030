import {
    AgencyCompensationReport,
    CreativeStrategyReport,
    HexExampleReport,
    TopCompaniesReport,
} from './fixture';

export const ReportDefinitions = {
    AGENCY: AgencyCompensationReport,
    CREATIVE: CreativeStrategyReport,
    HEX_EXAMPLE: HexExampleReport,
    TOP_COMPANIES: TopCompaniesReport,
};
