import React from 'react';
import { Box, HStack, Icon, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { GoOrganization } from 'react-icons/go';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { VAROS_YELLOW } from '../../../../config';
import { PeerViewConfig } from '../../base';
import { PeerCompanyItemViewProps } from './peerCompanyItemProps';
import { createPeerCompanyCardView } from '../card';
import { createPeerCompanyPopoverView } from '../popover';

export function createPeerCompanyItemView(
    config: PeerViewConfig
): React.FC<PeerCompanyItemViewProps> {
    const {
        UI: {
            Application: { Tooltip },
        },
    } = config;
    const CompanyCard = createPeerCompanyCardView(config);
    const CompanyPopover = createPeerCompanyPopoverView();
    return (props) => {
        // const varosIcon = (
        //     <HStack
        //         position="absolute"
        //         bottom={-1}
        //         left={-1}
        //         // bg="black"
        //         // borderRadius="full"
        //         p={1}
        //         justify="center"
        //         align="center"
        //         zIndex={5}
        //     >
        //         {props.company.profiles.map((item) => (
        //             <Tooltip
        //                 p={4}
        //                 placement="top"
        //                 hasArrow={true}
        //                 bg="#1D1C21"
        //                 label={item.description}
        //             >
        //                 <Image w={3} h={3} {...item.image} />
        //             </Tooltip>
        //         ))}
        //     </HStack>
        // );
        return (
            <HStack px={6} py={4} justify="space-between" w="full" spacing={16}>
                <HStack w="full" spacing={4}>
                    {props.company.iconUrl && (
                        <Box flexShrink={0} position="relative">
                            <Image
                                borderRadius="sm"
                                w={10}
                                h={10}
                                src={props.company.iconUrl}
                            />
                            {/* {props.company.profiles.length > 0 && varosIcon} */}
                        </Box>
                    )}
                    {!props.company.iconUrl && (
                        <Box
                            p={2.5}
                            bg="whiteAlpha.300"
                            borderRadius="sm"
                            flexShrink={0}
                            position="relative"
                        >
                            <Icon w={5} h={5} as={GoOrganization} />
                            {/* {props.company.profiles.length > 0 && varosIcon} */}
                        </Box>
                    )}
                    <VStack w="full" align="start" spacing={0} cursor="default">
                        <HStack w="full" spacing={3}>
                            <HStack spacing={3}>
                                {/* {props.company.profiles.length > 0 && (
                                    <HStack>
                                        {props.company.profiles.map((item) => (
                                            <Tooltip
                                                p={4}
                                                placement="top"
                                                hasArrow={true}
                                                bg="#1D1C21"
                                                label={item.description}
                                            >
                                                <Image {...item.image} />
                                            </Tooltip>
                                        ))}
                                    </HStack>
                                )} */}
                                <CompanyPopover
                                    placement="top-end"
                                    trigger={
                                        <Text
                                            fontWeight="medium"
                                            cursor="default"
                                            noOfLines={1}
                                            wordBreak="break-word"
                                            _hover={{ textDecor: 'underline' }}
                                        >
                                            {props.company.name}
                                        </Text>
                                    }
                                >
                                    <CompanyCard {...props.company} />
                                </CompanyPopover>
                            </HStack>
                            {props.company.highlight && (
                                <Tooltip
                                    p={4}
                                    placement="top"
                                    hasArrow={true}
                                    bg="#1D1C21"
                                    label={props.company.highlight.description}
                                >
                                    <HStack
                                        spacing={1}
                                        color={VAROS_YELLOW['200']}
                                        fontWeight="medium"
                                        fontSize="sm"
                                    >
                                        <Icon as={props.company.highlight.Icon}></Icon>
                                        <Text>{props.company.highlight.label}</Text>
                                    </HStack>
                                </Tooltip>
                            )}
                        </HStack>
                        <Text noOfLines={1} color="whiteAlpha.700" wordBreak="break-word">
                            {props.company.domain}
                        </Text>
                    </VStack>
                </HStack>
                {props.select?.isSelected && (
                    <IconButton
                        {...props.select.button}
                        variant="outline"
                        size="sm"
                        borderRadius="sm"
                        aria-label="unselect company"
                        colorScheme="red"
                        icon={<Icon as={AiOutlineClose} />}
                        _focus={{ outline: 'none' }}
                    />
                )}
                {props.select && !props.select.isSelected && (
                    <IconButton
                        {...props.select.button}
                        variant="outline"
                        size="sm"
                        bg="whiteAlpha.400"
                        borderRadius="sm"
                        aria-label="select company"
                        colorScheme="gray"
                        icon={<Icon as={AiOutlinePlus} />}
                        _hover={{ bg: 'whiteAlpha.500' }}
                        _focus={{ outline: 'none' }}
                    />
                )}
            </HStack>
        );
    };
}
