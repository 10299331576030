import pluralize from 'pluralize';
import { buildPeerSetStatusProps } from '../../../../view/peer/set';
import { isCompanyInvitation } from '../../../../domain/accounts';
import { Formatter } from '../../../../../hooks';
import { getCollaboratorLabel, PeerSetStatus, WorkspaceContextV2 } from '../../../../app';
import { GroupItemAggregate } from './groupItemModel';
import { GroupItemViewProps } from './groupItemProps';

export function buildGroupItemViewProps(
    formatter: Formatter,
    context: WorkspaceContextV2,
    item: GroupItemAggregate,
    index: number
): GroupItemViewProps {
    const format = {
        date: {
            relative: formatter.timeago(item.peerset.createdAt, {}),
        },
    };

    const count = {
        all: item.peerset.members.length + (item.count.invitation?.value ?? 0),
    };

    const plural = {
        all: pluralize('company', count.all),
        member: pluralize('member', item.peerset.members.length),
        invitations: item.count.invitation
            ? pluralize('invitations', item.count.invitation.value)
            : null,
    };

    const status = buildPeerSetStatusProps({
        peerset: item.peerset,
        invitation: item.count.invitation ?? { value: 0 },
    });

    return {
        id: item.peerset.id,
        label: item.peerset.name,
        creator: {
            label: item.user ? getCollaboratorLabel(item.user) : 'N/A',
        },
        // status: status.value === 'eligible' ? null : status,
        status,
        created: {
            relative: {
                label: format.date.relative,
            },
        },
        count: {
            member: plural.all
                ? {
                      label: `${count.all} ${plural.all}`,
                  }
                : { label: 'No companies' },
            // invitations: null,
            invitations:
                item.count.invitation &&
                item.count.invitation.value > 0 &&
                plural.invitations
                    ? {
                          label: `${item.count.invitation.value} pending ${plural.invitations}`,
                      }
                    : null,
        },
        links: {
            detail: {
                to: item.peerset.id,
            },
        },
    };
}
