import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../base';
import { RegistrationAcceptContextProvider } from '../context';
import { RegistrationAcceptFinalizeController } from './acceptFinalizeInterface';
import { AcceptFinalizeViewProps } from './acceptFinalizeProps';

export function createRegistrationAcceptFinalizeContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptFinalizeController,
    View: React.FC<AcceptFinalizeViewProps>
): React.FC {
    const {
        accept: { useContext },
    } = config;
    return (_containerProps) => {
        const context = useContext();
        const props = controller.useProps({
            stepper: context.stepper,
            navigate: useNavigate(),
        });
        return <View {...props} />;
    };
}
