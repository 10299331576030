import {
    VStack,
    Box,
    Grid,
    GridItem,
    Heading,
    Text,
    Button,
    StackDivider,
    HStack,
    ButtonGroup,
    Tag,
    Alert,
    AlertIcon,
    AlertTitle,
    Modal,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    Icon,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Icons } from '../../../../config';
import { StudyItemViewProps, SubmissionItemViewProps } from '../../../view/studies';
import { PeerViewConfig } from '../base';
import { PeerOverviewViewProps } from './peerOverviewProps';

export function createPeerOverviewRouteView(
    config: PeerViewConfig
): React.FC<PeerOverviewViewProps> {
    const {
        UI: {
            Application: { Link: ApplicationLink, Tooltip },
        },
    } = config;

    return (props) => {
        const {} = props;
        return (
            <>
                <Grid
                    templateAreas={`
                        "left right"
                        "content content"
                    `}
                >
                    <GridItem gridArea="left">
                        <VStack
                            align="start"
                            w="full"
                            spacing={6}
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.200"
                                    borderWidth={2}
                                />
                            }
                        >
                            <VStack w="full" align="start" spacing={6}>
                                <VStack w="full" align="start" spacing={3}>
                                    <VStack w="full" align="start" spacing={3}>
                                        <Heading
                                            fontSize="xl"
                                            fontWeight="semibold"
                                            color="blue.300"
                                        >
                                            Peers
                                        </Heading>
                                        <HStack spacing={3}>
                                            <Text
                                                fontWeight="semibold"
                                                fontSize="2xl"
                                                maxW="32rem"
                                            >
                                                Select your competitors
                                            </Text>
                                            <Tag
                                                size="md"
                                                fontWeight="semibold"
                                                color="blackAlpha.900"
                                                bg="#c6e74f"
                                                letterSpacing="wide"
                                                py={1}
                                                px={2}
                                            >
                                                Beta
                                            </Tag>
                                        </HStack>
                                    </VStack>
                                    <Text
                                        fontSize="lg"
                                        color="whiteAlpha.700"
                                        maxW="42rem"
                                    >
                                        Create even more accurate comparisons by
                                        benchmarking against specific companies. While you
                                        will not be able to see the numbers of a specific
                                        company, you will know exactly which companies are
                                        in the
                                    </Text>
                                </VStack>
                                <ButtonGroup w="full" spacing={4}>
                                    {/* <Button
                                        {...props.modal.triggerProps}
                                        borderRadius="full"
                                        variant="outline"
                                        colorScheme="blue"
                                        _focus={{ outline: 'none' }}
                                    >
                                        Watch video
                                    </Button> */}
                                    <Link to="../competitive_sets/new">
                                        <Button
                                            borderRadius="full"
                                            colorScheme="blue"
                                            _focus={{ outline: 'none' }}
                                        >
                                            Search companies
                                        </Button>
                                    </Link>
                                </ButtonGroup>
                            </VStack>
                        </VStack>
                    </GridItem>
                    <GridItem gridArea="right"></GridItem>
                    <GridItem gridArea="content"></GridItem>
                </Grid>
                {/* <Modal {...props.modal.containerProps} size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton _focus={{ outline: 'none' }} />
                        <ModalBody p={0}>
                            <VStack w="full" align="start" p={12} spacing={6}>
                                <VStack w="full" align="start" spacing={1}>
                                    <HStack color="#c6e74f">
                                        <Icon as={Icons.Feature.Highlight} />
                                        <Text fontWeight="bold">Feature highlight</Text>
                                    </HStack>
                                    <VStack w="full" align="start" spacing={1}>
                                        <Heading fontSize="2xl">
                                            {props.video.title}
                                        </Heading>
                                        {props.video.description && (
                                            <Text
                                                fontWeight="medium"
                                                fontSize="xl"
                                                color="whiteAlpha.700"
                                            >
                                                {props.video.description}
                                            </Text>
                                        )}
                                    </VStack>
                                </VStack>
                                <Box
                                    bg="whiteAlpha.300"
                                    borderRadius="lg"
                                    overflow="hidden"
                                    w="full"
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        paddingBottom: '64.86486486486486%',
                                        height: 0,
                                    }}
                                >
                                    <iframe
                                        src={props.video.src}
                                        frameBorder="0"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal> */}
            </>
        );
    };
}
