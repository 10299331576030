import React from 'react';
import { FormFieldListController } from './formFieldListInterface';
import {
    FormFieldListContainerProps,
    FormFieldListViewProps,
} from './formFieldListProps';

export function createFormFieldListContainer(
    controller: FormFieldListController,
    View: React.FC<FormFieldListViewProps>
): React.FC<FormFieldListContainerProps> {
    return (containerProps) => {
        const props = controller.useProps(containerProps);
        return (
            <View {...props}>
                {React.Children.map(containerProps.children, (child) => {
                    return React.cloneElement(child, { ...child.props });
                })}
            </View>
        );
    };
}
