import { PageIds } from '../../../../config';
import { RegistrationControllerConfig } from '../../base';
import { RegistrationAcceptFinalizeController } from './acceptFinalizeInterface';

export function createRegistrationAcceptFinalizeController(
    config: RegistrationControllerConfig
): RegistrationAcceptFinalizeController {
    const {
        provider: { createPageController },
    } = config;

    const { useProps: usePageProps } = createPageController({
        id: PageIds.REGISTRATION_ACCEPT_COMPLETE,
        title: 'Account created',
        description: `Your account has been created and the next step is to connect your data`,
        provider: config.provider,
    });

    return {
        useProps(props) {
            const page = usePageProps({}, {});
            return {
                page,
                button: {
                    onClick() {
                        props.navigate(`/u/onboarding?step=integration`, {
                            replace: true,
                        });
                    },
                },
            };
        },
    };
}
