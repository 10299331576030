import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import { FormIds, PageIds } from '../../../../config';
import { PeerRouteConfig } from '../../base';
import {
    PeerMemberEditController,
    PeerMemberEditFormProvider,
    PeerMemberEditLoader,
} from './peerMemberEditInterface';
import { PeerMemberEditFormValues } from './peerMemberEditModel';
import {
    PeerMemberEditContainerProps,
    PeerMemberEditViewProps,
} from './peerMemberEditProps';
import { PeerMemberEditPathParamsSchema } from './peerMemberEditSchema';

export function createPeerMemberEditRoute(
    config: PeerRouteConfig,
    forms: PeerMemberEditFormProvider,
    controller: PeerMemberEditController,
    loader: PeerMemberEditLoader,
    View: React.FC<PeerMemberEditViewProps>
): React.FC<PeerMemberEditContainerProps> {
    const {
        service: { peerset: peersetService },
        repository: { peerset: peersetRepository, invitation: invitationRepository },
        infra: { options, toaster },
        context,
    } = config;
    return (_routeProps) => {
        const toast = toaster.useToast();
        const navigate = useNavigate();

        const viewOptions = options.useOptions(PeerMemberEditPathParamsSchema);
        const viewContext = context.useContext();
        const viewForm = forms.useForm(viewContext);
        const viewFormValues = viewForm.watch();

        const viewData = loader.useLoad(viewContext, {
            peerset: { id: viewOptions.competitiveSetId },
            form: viewForm,
        });

        const mutations = {
            invitation: invitationRepository.useBulk(viewContext),
            peerset: peersetRepository.useUpdate(viewContext),
        };

        const submit = useMutation({
            mutationFn(values: PeerMemberEditFormValues) {
                return peersetService.update(viewContext, viewData, {
                    companies: values.companies,
                    updatePeerset(props) {
                        return mutations.peerset.mutateAsync([viewData.peerset, props]);
                    },
                    updateInvitations(props) {
                        return mutations.invitation.mutateAsync(props);
                    },
                });
            },
        });

        const selected = useMemo(
            () => new Set(viewFormValues.companies),
            [viewFormValues.companies]
        );

        const viewProps = controller.useProps(viewContext, viewData, {
            page: {
                item: {
                    id: PageIds.PEER_COMPETITIVE_SET_MEMBER_EDIT,
                    title: 'Edit group members',
                    breadcrumbs: [],
                },
            },
            company: {
                match: {
                    company: {
                        metadata: {},
                    },
                    selected,
                    onAdd(item) {
                        viewForm.setValue('companies', [
                            // add company to start of list for proper UX
                            item.company.id,
                            ...viewFormValues.companies,
                        ]);
                    },
                },
                select: {
                    selected: viewFormValues.companies,
                    onRemove(item) {
                        viewForm.setValue(
                            'companies',
                            viewFormValues.companies.filter(
                                (candidate) => candidate !== item.company.id
                            )
                        );
                    },
                    onClear() {
                        viewForm.reset({ companies: [] });
                    },
                },
                form: {
                    id: FormIds.PEER_GROUP_MEMBER_EDIT,
                    action: {
                        label: 'Update group',
                    },
                    form: viewForm,
                    async onSubmit(values) {
                        const response = await submit.mutateAsync(values);
                        navigate(-1);
                        toast({
                            kind: 'success',
                            description: `Successfully updated group members`,
                        });
                    },
                    onSubmitError(error) {
                        toast({
                            kind: 'error',
                            description: `Form submission failed`,
                        });
                    },
                },
            },
        });
        return <View {...viewProps} />;
    };
}
