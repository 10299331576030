import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { ReportV2Api, ReportV2Dto } from '../../../api';
import { MockReportMiddlewareConfig } from './mockReportConfig';
import { ExternalReportDispatch } from './mockReportInterface';

export function createMockReportEnhancer(
    init: MockReportMiddlewareConfig
): ApplicationEntryEnhancer {
    // const overrides: ReportV2Dto[] = [...Object.values(ReportDefinitions)];

    return (create) => (config) => {
        function buildUrl(dispatch: ExternalReportDispatch) {
            const url = new URL(dispatch.url);

            // Only append params if they exist and are not null
            if (dispatch.params) {
                const searchParams = new URLSearchParams(
                    Object.entries(dispatch.params).map(([key, value]) => [
                        key,
                        String(value),
                    ])
                );
                url.search = searchParams.toString();
            }

            return url.toString();
        }

        function enhanceReportApi(api: ReportV2Api): ReportV2Api {
            return {
                ...api,
                report: {
                    ...api.report,
                    async list(context, query, options) {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        const resolved = init.reports
                            .filter((candidate) =>
                                query.status
                                    ? query.status.includes(candidate.entity.status)
                                    : true
                            )
                            .map((item): ReportV2Dto => {
                                if (item.kind === 'external') {
                                    const dispatch = item.getTargetUrl(query);
                                    return {
                                        kind: item.kind,
                                        ...item.entity,
                                        url: buildUrl(dispatch),
                                    };
                                }
                                return { kind: item.kind, ...item.entity };
                            });
                        return {
                            data: resolved,
                        };
                    },
                    async get(context, query) {
                        const resolved = init.reports.map((item): ReportV2Dto => {
                            if (item.kind === 'external') {
                                const dispatch = item.getTargetUrl(query);

                                return {
                                    kind: item.kind,
                                    ...item.entity,
                                    url: buildUrl(dispatch),
                                };
                            }
                            return { kind: item.kind, ...item.entity };
                        });
                        const found = resolved.find(
                            (candidate) => candidate.id === query.id
                        );
                        if (found) {
                            await new Promise((resolve) => setTimeout(resolve, 600));
                            return found;
                        }
                        throw new Error(`report ${query.id} not found`);
                        // return api.report.get(context, id);
                    },
                },
            };
        }

        return create({
            ...config,
            api: {
                ...config.api,
                createReportApiV2(...args) {
                    const api = config.api.createReportApiV2(...args);
                    return enhanceReportApi(api);
                },
            },
        });
    };
}
