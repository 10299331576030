import { ButtonProps, TooltipProps } from '@chakra-ui/react';
import { capitalize, chain, isEqual, uniq } from 'lodash';
import { useMemo } from 'react';
import { getSubscriptionTotalDue } from '../../../../service/billing';
import {
    BillingUsagePlan,
    getBillingPlanUnitDescription,
    getBillingPlanUnitLabel,
    SubscriptionUpdateProps,
} from '../../../../domain/billing';
import { assert } from '../../../../util/assert';
import { SubscriptionEditFormProps, SubscriptionFormValues } from '../form';
import { SubscriptionEditPreviewProps } from '../preview';
import { SubscriptionControllerConfig } from '../subscriptionConfig';
import { SubscriptionEditController } from './subscriptionEditInterface';
import {
    SubscriptionEditAddonItemProps,
    SubscriptionEditAddonListProps,
    SubscriptionEditAlertListProps,
} from './subscriptionEditProps';
import { buildPlanSelectProps, PlanSelectViewProps } from '../../plan/select';
import { SubscriptionEditUsageListProps } from './usage';

export function createSubscriptionEditController(
    config: Pick<SubscriptionControllerConfig, 'infra' | 'controller'>
): SubscriptionEditController {
    const {
        infra: { formatter },
        controller: {
            plan: { select: planController },
        },
    } = config;
    function getPayload(values: SubscriptionFormValues): SubscriptionUpdateProps {
        return { plan: values.plan, addons: values.addons };
    }
    return {
        useProps(context, deps, props) {
            const formValues = deps.form.watch();
            const { isSubmitting } = deps.form.formState;

            console.log('DEBUG formValues', formValues);

            const currentPlan = useMemo(
                () =>
                    props.item.plans.find(
                        (plan) => plan.id === props.item.subscription.plan
                    ) ?? null,
                [props.item.plans, props.item.subscription.plan]
            );

            const selectedPlan = useMemo(
                () =>
                    props.item.plans.find((plan) => plan.id === formValues.plan) ?? null,
                [props.item.plans, formValues.plan]
            );

            // HACK if on legacy plan we display the free plan as selecfted but
            // notify the user that they are on a paid legacy plan
            const plan =
                selectedPlan ?? props.item.plans.find((plan) => plan.amount === 0);

            assert(plan, `'${formValues.plan}' not found`);

            // const planSelectProps = useMemo<PlanSelectViewProps>(() => {
            //     return buildPlanSelectProps(formatter, {
            //         item: props.item,
            //         value: formValues.plan,
            //         onChange(value) {
            //             deps.form.setValue('plan', value);
            //         },
            //     });
            // }, [props.item.plans, formValues]);

            const planSelectProps = planController.useProps(context, {
                item: props.item,
                value: formValues.plan,
                onChange(value) {
                    deps.form.setValue('plan', value);
                },
            });

            // const isOnLegacyPlan = selectedPlan === null || currentPlan === null;
            const isOnAvailablePlan = useMemo(
                () =>
                    planSelectProps.items.some((candidate) =>
                        candidate.kind === 'usage'
                            ? candidate.options.some(
                                  (option) => option.id === props.item.subscription.plan
                              )
                            : candidate.plan.id === props.item.subscription.plan
                    ),
                [planSelectProps.items, props.item.subscription.plan]
            );

            const isOnLegacyPlan = !isOnAvailablePlan;

            const payload = getPayload(formValues);

            const formProps = useMemo<SubscriptionEditFormProps>(() => {
                // const isDirty = !isEqual(
                //     // @ts-expect-error
                //     getPayload(deps.form.control._defaultValues),
                //     getPayload(formValues)
                // );

                const isDirty = currentPlan?.id !== selectedPlan?.id;

                let submitTooltip: string | null = null;
                if (!isDirty) {
                    submitTooltip = 'Modify your subscription to update';
                }

                // payment method is only required for paid plans
                const hasRequiredPaymentMethod =
                    plan.amount > 0 ? props.item.methods.length > 0 : true;

                if (!hasRequiredPaymentMethod) {
                    submitTooltip = 'An active payment method is required';
                }
                return {
                    async onSubmit() {
                        const response = await deps.mutation.update.mutateAsync(payload);
                        deps.form.reset(formValues);
                    },
                    handleSubmit: deps.form.handleSubmit,
                    isSubmittable: isDirty && !isSubmitting && hasRequiredPaymentMethod,
                    isSubmitting: isSubmitting,
                    submitTooltip,
                    error: null,
                };
            }, [
                plan,
                formValues,
                props.item.methods,
                props.item.subscription.plan,
                deps.form.control._defaultValues,
                isSubmitting,
                payload,
            ]);

            const submitButtonProps = useMemo<ButtonProps>(() => {
                return {
                    type: 'submit',
                    isDisabled: props.status?.isDisabled || !formProps.isSubmittable,
                    isLoading: formProps.isSubmitting,
                    children: 'Update subscription',
                };
            }, [props.status?.isDisabled, formProps]);

            const submitTooltipProps = useMemo<Partial<TooltipProps>>(() => {
                return {
                    label: props.status?.error ?? formProps.submitTooltip ?? undefined,
                    isDisabled: !formProps.submitTooltip,
                };
            }, [props.status?.error, formProps.submitTooltip]);

            const previewProps = useMemo<SubscriptionEditPreviewProps>(() => {
                const addons = plan.add_ons.filter((addon) =>
                    formValues.addons.some((x) => x.addon_id === addon.id)
                );
                const subscriptionAmountLabel = formatter.currency(
                    plan.amount / 100,
                    'usd'
                );
                const total = getSubscriptionTotalDue(props.item, plan, payload);
                const totalLabel = formatter.currency(total / 100, 'usd');

                const verb =
                    plan.id === props.item.subscription.plan ? 'Subscribed' : 'Subscribe';

                let planName =
                    plan.kind === 'usage' ? plan.name.split(' ')[0] : plan.name;

                const title = `${verb} to ${planName}`;

                return {
                    title: title,
                    description: `${totalLabel}/mo`,
                    items: [
                        {
                            label: 'Subscription',
                            empty: null,
                            children: [
                                {
                                    label: plan.name,
                                    secondary: `${subscriptionAmountLabel}/mo`,
                                },
                            ],
                        },
                        {
                            label: 'Addons',
                            empty:
                                addons.length === 0
                                    ? { label: `No addons selected` }
                                    : null,
                            children: addons.map((addon) => {
                                const amountLabel = formatter.currency(
                                    addon.amount / 100,
                                    'usd'
                                );
                                return {
                                    label: addon.name,
                                    secondary: `+${amountLabel}/mo`,
                                };
                            }),
                        },
                    ],
                };
            }, [formValues, plan, payload, props.item.subscription.plan]);

            const addonProps: SubscriptionEditAddonListProps = useMemo(
                () => ({
                    items: plan.add_ons,
                    getItemProps(item): SubscriptionEditAddonItemProps {
                        let isSelected = false;
                        const formatted = formatter.currency(item.amount / 100, 'usd');
                        let action: SubscriptionEditAddonItemProps['action'] | null =
                            null;
                        if (item.payment_strategy === 'integrated') {
                            isSelected = formValues.addons.some(
                                (x) => x.addon_id === item.id
                            );
                            action = {
                                display: 'switch',
                                isSelected,
                                getSwitchProps() {
                                    return {
                                        isChecked: isSelected,
                                        onChange() {
                                            const value = uniq(
                                                isSelected
                                                    ? formValues.addons.filter(
                                                          (candidate) =>
                                                              candidate.addon_id !==
                                                              item.id
                                                      )
                                                    : [
                                                          ...formValues.addons,
                                                          {
                                                              addon_id: item.id,
                                                              product_id: item.product_id,
                                                              is_payed: false,
                                                          },
                                                      ]
                                            );
                                            deps.form.setValue('addons', value);
                                        },
                                    };
                                },
                            };
                        } else if (item.payment_strategy == 'external') {
                            const returnUrl = `/u/billing/charge-complete?next=${window.location.pathname}`;
                            action = {
                                display: 'payment',
                                beginPaymentSession: () => {
                                    return deps.mutation.startPaymentSession
                                        .mutateAsync({
                                            product: item.product_id,
                                            cancel_path: returnUrl,
                                            success_path: returnUrl,
                                        })
                                        .then((x) => {
                                            window.location.href = x.url;
                                        });
                                },
                                isEnabled: formValues.addons.some(
                                    (x) =>
                                        x.addon_id === item.id &&
                                        item.product_id == x.product_id
                                ),
                                isPaid: formValues.addons.some(
                                    (x) =>
                                        x.addon_id === item.id &&
                                        item.product_id == x.product_id &&
                                        x.is_payed
                                ),
                                isLoading: deps.mutation.startPaymentSession.isLoading,
                            };
                        } else {
                            throw new Error('unsupported payment startegy');
                        }
                        return {
                            name: item.name,
                            price: {
                                label: item.is_one_time_charge
                                    ? formatted
                                    : `${formatted}/mo`,
                            },
                            description: item.description ?? null,
                            action,
                        };
                    },
                }),
                [props.item.plans, plan, formValues]
            );

            let alertListProps: SubscriptionEditAlertListProps = {
                items:
                    props.item.methods.length === 0
                        ? [
                              {
                                  status: 'info',
                                  label: `Payment method is not configured`,
                                  action: {
                                      label: 'Add payment method',
                                      async onClick() {
                                          const response =
                                              await deps.mutation.method.portal.mutateAsync(
                                                  {
                                                      returnPath: deps.location.pathname,
                                                  }
                                              );
                                          location.href = response.url;
                                      },
                                  },
                              },
                          ]
                        : [],
            };

            if (isOnLegacyPlan) {
                alertListProps = {
                    ...alertListProps,
                    items: [
                        ...alertListProps.items,
                        {
                            status: 'warning',
                            label: 'Your are currently subscribed to a paid legacy plan',
                        },
                    ],
                };
            }

            return {
                getAlertListProps() {
                    return alertListProps;
                },
                getFormStateProps() {
                    return formProps;
                },
                getSubmitButtonProps() {
                    return submitButtonProps;
                },
                getSubmitTooltipProps() {
                    return submitTooltipProps;
                },
                getPreviewProps() {
                    return previewProps;
                },
                getPlanSelectProps() {
                    return planSelectProps;
                },
                getAddonListProps() {
                    return addonProps;
                },
            };
        },
    };
}
